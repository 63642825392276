import { estados } from "@/utils/estadosPedidos.utils.js";
export default {
  pedidosListener: null,
  pedidosRealizandoTpvListener: null,
  pedidosArray: [],
  pedidosRealizandoTpv: [],
  pedidosImprimir: [],
  pedidosImprimirGestionando: [], //si el pedido se está gestionando para imprimir
  pedidosDirectorioMatriz: [],
  estados: estados,
  formaPago: [
    {
      id: "tarjeta_online",
      name: "Tarjeta Online",
      modo: ["domicilio", "recoger"],
    },
    {
      id: "tarjeta_domicilio",
      name: "Tarjeta a domicilio",
      modo: ["domicilio"],
    },
    {
      id: "efectivo",
      name: "Efectivo",
      modo: ["domicilio"],
    },
    {
      id: "local",
      name: "En local",
      modo: ["recoger"],
    },
    {
      id: "tarjeta_local",
      name: "Tarjeta en local",
      modo: [],
    },
    {
      id: "otro",
      name: "Otro",
      modo: [],
    },
  ],
  cambioDisponible: [
    {
      id: 10,
      name: "10 €",
    },
    {
      id: 20,
      name: "20 €",
    },
    {
      id: 40,
      name: "40 €",
    },
    {
      id: 50,
      name: "50 €",
    },
    {
      id: 100,
      name: "100 €",
    },
  ],
  estados: [
    { text: "Finalizado", value: "finalizado" },
    { text: "En Curso", value: "" },
  ],
  modos: [
    { text: "Recoger", value: "recoger" },
    { text: "Domicilio", value: "domicilio" },
    { text: "Plano", value: "plano" },
    { text: "Llevar", value: "llevar" },
    // { text: "Barra", value: "barra" },
    // { text: "Mesa", value: "mesa" },
  ],
  eliminarMotivos: [
    {
      text: "Solicitado por dueño negocio",
      value: "solicitado-negocio",
      icon: "mdi-store",
    },
    {
      text: "Solicitado por el usuario",
      value: "solicitado-usuario",
      icon: "mdi-account",
    },
    {
      text: "Fallo de la aplicación",
      value: "fallo-aplicacion",
      icon: "mdi-bug",
    },
  ],
  siguienteNumRestDia: null,
  siguienteNumRest: null,
  siguienteNumRestDiaListener: null,
  siguienteNumRestListener: null,
  abriendoNoGrabar: false,
  pedidoEditando: null, //id pedido editando
  pedidoEditandoObj: {}, //objeto pedido editando
  pedidoEditandoFlag: null, //para parar reactividad, saber si se ha grrabado
  cobrarDialog: false,
  cobrarDialogFullscreen: true,
  modoPantalla: "seleccionModo", //seleccionModo, seleccionMesa, seleccionComensales, seleccionCliente, pedidoCarrito
  pedidoDialog: false,
  accionesDialog: false,
  facturacionDialog: false,
  facturacionContableFacturar: {},
  cambiandoCliente: false, //marca si estamos cambiando el cliente del pedido
  facturandoPedido: false,
  moverPedidoModoDialog: false,
  descuentosDialog: false,
  historialDialog: false,
  abonoDialog: false,
  grabandoPedido: false,

  editarProductoObj: null, //indica el producto que se está editando de carrito
  editarProductoMenuObj: null, //indica el producto que se está editando de carrito
};
