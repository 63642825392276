import moment from 'moment-timezone'
import { mostrarMoneda, redondeo } from "@/utils/helper.utils.js";
import { readFileAsync } from 'fs'
const { loadImage } = require('canvas')
const QRCode = require('qrcode')
var EscPosEncoder = require('esc-pos-encoder');



const ESC_INIT = [0x1b, 0x40];
const CORTE = [0x1D, 0x56, 49];
const LINEA = [0x0A];
const FUENTE_MEDIANA = [0x1D, 0x21, 0x10];
const FUENTE_GRANDE = [0x1D, 0x21, 0x11];
const FUENTE_NORMAL = [0x1D, 0x21, 0x00];
const BOLD = [0x1B, 0x21, 0x08];
const BOLD_NORMAL = [0x1B, 0x21, 0x00];
const FUENTE_MAS_GRANDE = [0x1D, 0x21, 34];
const ALIGN_CENTER = [0x1b, 0x61, 0x01]
const ALIGN_LEFT = [0x1b, 0x61, 0x00]
const ALIGN_RIGHT = [0x1b, 0x61, 0x02]

const ABRIR_CAJON = [0x1B, 0x70, 0x0, 0x19, 0xFA]


// prueba rene

const RENE = [0x1B, 0x57];
const INVERTIR = [0x1D, 0x42, 1];
const STOP_INVERTIR = [0x1D, 0x42, 0];
const FONT_HACHATADA = [0x1B, 0x21, 34];
const ESPACIO_ENTRE_CARACTER = [0x1B, 0x20, 2];
const LINEA_HORIZONTAL = [0x1B, 0x61, 0x01, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D,
    0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D,
    , 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, , 0x2D, 0x2D, 0x2D,
    0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x2D, 0x0A];







function lineaEsc(text) {
    return text.split('').map(char => char.charCodeAt(0)).concat(LINEA)
}

function quitarAcentos(str) {
    console.log(str)
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

function idToTexto(s) {
    return (s && s.length > 0) ? s.replace(/^./, s[0].toUpperCase()).replace(/_/g, ' ').replace(/-/g, ' ') : '';
}

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function getBase64FromImageUrl(url) {
    return new Promise((r, rej) => {
        var img = new Image();

        img.setAttribute('crossOrigin', 'anonymous');

        img.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(this, 0, 0);

            var dataURL = canvas.toDataURL("image/png");


            let datos = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            return r(_base64ToArrayBuffer(datos));
        };

        img.src = url;

    })
}

export const imprimirTicketTipo1 = async function (data, logo) {
    var res = [];
    return new Promise((resolve) => {
        if (!logo) resolve([]);

        var img = new Image()

        //listener has to be added before setting the src attribute in case the image is cached
        img.addEventListener('load', (r) => {

            let encoder = new EscPosEncoder();
            let result = encoder
                .image(img, 480, 240, 'atkinson')
                .encode()
            resolve(result)
        });
        img.src = 'data:image/png;base64,' + logo;

    })
        .then(async (logo) => {
            res = res
                .concat(ESC_INIT)
                .concat(ALIGN_CENTER)
                .concat([...logo])



            const negocio = data.copyNegocio;
            const restaurante = data.copyRest;
            const timezone = (restaurante.confGeneral.zonaHoraria) ? restaurante.confGeneral.zonaHoraria : 'Europe/Madrid';
            var fechaMoment = (data.fechaServidor.seconds) ?
                moment.unix(data.fechaServidor.seconds).tz(timezone) :
                moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);
            const formaPago = data.forma_pago == 'tarjeta_online'
                ? 'PAGADO ' + idToTexto(data.forma_pago).toUpperCase()
                : idToTexto(data.forma_pago).toUpperCase();
            res = res.concat(ALIGN_CENTER)
                .concat(lineaEsc(quitarAcentos(data.copyNegocio.nombre)))
                .concat(lineaEsc(quitarAcentos(data.copyRest.direccion)))
                .concat(lineaEsc((data.copyRest.cif + ' | ' + data.copyRest.telefono)))
                .concat(BOLD)
                .concat(lineaEsc('TICKET: 00' + data.numPedidoRest + ' - ' + fechaMoment.format('DD/MM/YYYY') + ' - ' + fechaMoment.format('HH:mm')))
                .concat(BOLD)
            /*
            .concat(FUENTE_MAS_GRANDE)
            .concat(LINEA)
            .concat(INVERTIR)
        if (data.multiple && data.multipleHijos && data.multipleHijos.length) {//hijo
            cabecera 
                .line('PEDIDO MULTIPLE')
                .raw([0x1D, 0x21, 0x00])
            data.multipleHijos.forEach(h => {
                cabecera
                    .line(h)
            })
            cabecera.newline()
        } else if (data.multiplePadre) { //padre
            cabecera
                .line('MULTI-00' + data.numPedidoRestDiaDirectorio + ' - ' + data.modo.toUpperCase())
        } else  {
            // let f = this.$store.state.datosTpv.modos.find((m) => m.id == this.modoid);
            //   return f ? f.nombre : "";
            let modoMostrar = !!data.modoTexto ? data.modoTexto.toUpperCase() : data.modo.toUpperCase()
            res = res.concat(lineaEsc('00' + data.numPedidoRestDia + ' - ' + modoMostrar))
        } */
            res = res.concat(FUENTE_NORMAL) //miriad undereline
                .concat(STOP_INVERTIR)
                .concat(LINEA)
                .concat(ALIGN_CENTER)
                .concat(BOLD)
                .concat(lineaEsc("DIRECCION DE ENTREGA"))
                .concat(lineaEsc(((data.modo == 'domicilio')) ? quitarAcentos(data.direccion.calle + ' '
                    + data.direccion.numero + ' ' + data.direccion.adicional
                    + ', ' + data.direccion.municipio + ' ' + data.direccion.cp
                    + ' ' + data.direccion.provincia) : 'RECOGIDA'))
                .concat(LINEA)
                .concat(ALIGN_LEFT)
                .concat(BOLD)


            if ((data.modo == 'domicilio' || data.modo == 'recogida') && !!data.copyUser && !!data.copyUser.phoneNumber)
                res = res.concat(lineaEsc("TELEFONO: " + data.copyUser.phoneNumber.padStart(5)))


            if (data.modo == 'domicilio' || data.modo == 'recoger')
                res = res.concat(lineaEsc((data.modo == 'domicilio') ? "TIEMPO ESTIMADO: " + data.copyRest.tiempoDomicilio + ' min.' : "TIEMPO ESTIMADO: " + data.copyRest.tiempoRecogida + ' min.'))

            // .bold()
            // .underline()
            // .size()
            // .align("center")
            // .raw([0x1D, 0x21, 0x11]) //tamaño grande
            // .newline()
            // .line('PEDIDO ONLINE')
            // .raw([0x1D, 0x21, 0x00])
            // .underline("false")
            // .align("left")
            // .bold("false")
            // .newline()
            res = res.concat(lineaEsc("FORMA PAGO: " + formaPago));
            if (data.notaRestaurante)
                res = res.concat(lineaEsc(((data.notaRestaurante) ? "COMENTARIO CLIENTE: " + data.notaRestaurante : '')))

            res = res.concat(ALIGN_LEFT)
                .concat(BOLD)
                .concat(LINEA_HORIZONTAL)
                .concat(ALIGN_LEFT)

            var impuestos = 0;
            var productos = data.productos.filter(p => p.cantidad > 0);

            productos.forEach(prd => {
                let s = "";
                if (prd.porPeso) {
                    s = s + (prd.cantidad + "kg ").padEnd(5) + quitarAcentos(prd.nombre)
                } else {
                    s = s + (prd.cantidad + "x ").padEnd(5) + quitarAcentos(prd.nombre)
                }

                s = s.padEnd(34);
                let precioUnitario = !!prd.precioProductoTotalUnitario ? prd.precioProductoTotalUnitario : prd.precio;
                precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
                //if (prd.porPeso || prd.cantidad > 1)
                //    s = s + precioUnitario

                let precioTotal = !!prd.precioProductoTotal
                    ? prd.precioProductoTotal
                    : !!prd.precioProductoTotalUnitario
                        ? prd.precioProductoTotalUnitario * prd.cantidad
                        : precio * prd.cantidad;
                impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
                let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
                s = s + precioUnitario + precioTotalString
                res = res.concat(lineaEsc(s))
                if (prd.opciones) {
                    let opcionesImpresas = [];
                    prd.opciones.forEach((e, i) => {
                        if (!opcionesImpresas.includes(e)) {
                            opcionesImpresas.push(e);
                            var ocurrencias = prd.opciones.reduce((a, v) => (v === e ? a + 1 : a), 0);
                            let s = "";
                            s = (ocurrencias > 1) ?
                                "   " + ocurrencias + "- " + quitarAcentos(prd.opcionesName[i]) :
                                "   - " + quitarAcentos(prd.opcionesName[i]);
                            s = s.padEnd(43);
                            let precio = "" + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
                            precio = precio.padStart(5);
                            s = s + precio
                            // carritoString = carritoString + encoder.line(s)
                            res = res.concat(lineaEsc(s))
                        }

                    })
                }
            });
            if (data.recargo && data.recargo > 0) {
                let s = "";
                // carritoString = carritoString + encoder.line(s)
                // res = res.concat(lineaEsc(s))
                s = "";
                s = s + "   Recargo domicilio ";
                s = s.padEnd(36);
                let precio = mostrarMoneda(data.recargo).padStart(5);
                s = s + precio
                // carritoString = carritoString + encoder.line(s)
                res = res.concat(lineaEsc(s))
            }
            const impuestosImprimir = data.impuestosTotal ? data.impuestosTotal : impuestos;
            let precioTotal = data.precioTotal;
            // var carritoProductos = new Uint8Array(carritoString);
            var precioFinal = "TOTAL: " + mostrarMoneda(precioTotal);
            precioFinal = precioFinal;

            res = res.concat(ALIGN_LEFT)
                .concat(LINEA_HORIZONTAL)
                .concat(ALIGN_RIGHT)
                .concat(FUENTE_GRANDE)
                .concat(FONT_HACHATADA)
            if (data.forma_pago == 'efectivo' && Array.isArray(data.cobradoDesglose) && data.cobradoDesglose.length == 1) { //sólo un pago
                let cambioFound = data.cobradoDesglose.find(f => f.formaPago == 'efectivo')
                if (!!cambioFound) {
                    res = res
                        .concat(lineaEsc("ENTREGADO: " + mostrarMoneda(cambioFound.entregado, true)))
                        .concat(lineaEsc(' CAMBIO: ' + mostrarMoneda(cambioFound.cambio, true)))

                }
            }
            res = res
                .concat(BOLD_NORMAL)
                .concat(lineaEsc(("BASE IMPONIBLE:   " + mostrarMoneda(parseFloat(precioTotal) - impuestosImprimir))))
                .concat(lineaEsc(("IMPUESTOS:   " + mostrarMoneda(impuestosImprimir))))
                .concat(FUENTE_GRANDE)
                .concat(lineaEsc(precioFinal))
                .concat(LINEA)
                .concat(FUENTE_NORMAL)

                .concat(LINEA)
                .concat(ALIGN_CENTER)

            if (data.facturaCliente) {
                // create QRCode Image
                async function getQRCode(content) {
                    return new Promise((resolve, reject) => {
                        QRCode.toDataURL(content, (err, url) => {
                            if (err) return reject(err)

                            loadImage(url).then(resolve, reject);
                        })
                    })
                }
                const qrcode = await getQRCode(data.facturaCliente);
                let encoder = new EscPosEncoder();
                const result = encoder
                    .initialize()
                    .align('center')
                    .image(qrcode, 320, 320)
                    .encode()

                res = res
                    .concat(ALIGN_CENTER)
                    .concat(lineaEsc("DESCARGUE SU FACTURA"))
                    .concat([...result])
                    .concat(ALIGN_CENTER)
                    .concat(LINEA)
                    .concat(LINEA)
                    .concat(LINEA)



            }

            res = res
                .concat(lineaEsc("Gracias por su pedido"))
                .concat(lineaEsc("By FunEats"))


            res = res.concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)

            res = res.concat(CORTE)
            return new Uint8Array(res)
        })
}

export const imprimirTicketTipo2 = async function (data, logo) {
    console.log(data);
    var res = [];
    return new Promise((resolve) => {
        if (!logo) resolve([]);

        var img = new Image()

        //listener has to be added before setting the src attribute in case the image is cached
        img.addEventListener('load', (r) => {

            let encoder = new EscPosEncoder();
            let result = encoder
                .image(img, 480, 240, 'atkinson')
                .encode()
            resolve(result)
        });
        img.src = 'data:image/png;base64,' + logo;
    })
        .then(async (logo) => {
            res = res
                .concat(ESC_INIT)
                .concat(ALIGN_CENTER)
                .concat([...logo])

            const negocio = data.copyNegocio;
            const restaurante = data.copyRest;
            const timezone = (restaurante && restaurante.confGeneral && restaurante.confGeneral.zonaHoraria) ? restaurante.confGeneral.zonaHoraria : 'Europe/Madrid';
            var fechaMoment = (data.fechaServidor.seconds) ?
                moment.unix(data.fechaServidor.seconds).tz(timezone) :
                moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);
            var formaPago = data.forma_pago == 'tarjeta_online'
                ? 'PAGADO ' + idToTexto(data.forma_pago).toUpperCase()
                : idToTexto(data.forma_pago).toUpperCase();
            res = res.concat(ALIGN_CENTER)
                .concat(lineaEsc(quitarAcentos(data.copyNegocio.nombre)))
                .concat(lineaEsc(quitarAcentos(data.copyRest.direccion)))
                .concat(lineaEsc((data.copyRest.cif + ' | ' + data.copyRest.telefono)))
                .concat(BOLD)
                .concat(lineaEsc('TICKET: 00' + data.numPedidoRest + ' - ' + fechaMoment.format('DD/MM/YYYY') + ' - ' + fechaMoment.format('HH:mm')))
                .concat(BOLD)
                .concat(FUENTE_MAS_GRANDE)
                .concat(LINEA)
                .concat(INVERTIR)
            if (data.multiple && data.multipleHijos && data.multipleHijos.length) {//hijo
                cabecera
                    .line('PEDIDO MULTIPLE')
                    .raw([0x1D, 0x21, 0x00])
                data.multipleHijos.forEach(h => {
                    cabecera
                        .line(h)
                })
                cabecera.newline()
            } else if (data.multiplePadre) { //padre
                cabecera
                    .line('MULTI-00' + data.numPedidoRestDiaDirectorio + ' - ' + data.modo.toUpperCase())
            } else {
                let cabeceraMostrar;
                if (
                    data.copyMesa &&
                    data.modoCopy
                ) {
                    cabeceraMostrar = (
                        "MESA " +
                        data.copyMesa.nombre +
                        " " +
                        data.modoCopy.nombre
                    );
                } else if (data.modoCopy) {
                    cabeceraMostrar =
                        data.modoCopy.nombre +
                        (data.numPedidoRestDia
                            ? " " + data.numPedidoRestDia
                            : ""
                        );
                }
                if (!cabeceraMostrar) {
                    switch (data.modo) {
                        case "domicilio":
                            res = "DOMICILIO";
                            break;
                        case "recoger":
                            res = "RECOGER";
                            break;
                        case "mesa":
                            res = "MESA " + data.copyMesa.nombre;
                            break;
                        case "mostrador":
                            res = "MOSTRADOR ";
                            break;
                    }
                    cabeceraMostrar = res;
                }
                // let cabeceraMostrar = (data.modo == 'mesa')
                //     ? data.copyMesa.nombre
                //     : (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase()
                res = res.concat(lineaEsc(cabeceraMostrar))
            }
            res = res.concat(FUENTE_NORMAL) //miriad undereline
                .concat(STOP_INVERTIR)
                .concat(LINEA)
                .concat(ALIGN_CENTER)
                .concat(BOLD)
                .concat(lineaEsc("DIRECCION DE ENTREGA"))
                .concat(lineaEsc(((data.modo == 'domicilio')) ? quitarAcentos(data.direccion.calle + ' '
                    + data.direccion.numero + ' ' + data.direccion.adicional
                    + ', ' + data.direccion.municipio + ' ' + data.direccion.cp
                    + ' ' + data.direccion.provincia) : 'RECOGIDA'))
                .concat(LINEA)
                .concat(ALIGN_LEFT)
                .concat(BOLD)


            if ((data.modo == 'domicilio' || data.modo == 'recogida') && !!data.copyUser && !!data.copyUser.phoneNumber)
                res = res.concat(lineaEsc("TELEFONO: " + data.copyUser.phoneNumber.padStart(5)))


            if (data.modo == 'domicilio' || data.modo == 'recoger')
                res = res.concat(lineaEsc((data.modo == 'domicilio') ? "TIEMPO ESTIMADO: " + data.copyRest.tiempoDomicilio + ' min.' : "TIEMPO ESTIMADO: " + data.copyRest.tiempoRecogida + ' min.'))

            // .bold()
            // .underline()
            // .size()
            // .align("center")
            // .raw([0x1D, 0x21, 0x11]) //tamaño grande
            // .newline()
            // .line('PEDIDO ONLINE')
            // .raw([0x1D, 0x21, 0x00])
            // .underline("false")
            // .align("left")
            // .bold("false")
            // .newline()
            if (data.restante) {
                res = res.concat(lineaEsc("RESTANTE POR PAGAR"));
            } else {
                res = res.concat(lineaEsc("FORMA PAGO: " + formaPago));
            }
            if (data.notaRestaurante)
                res = res.concat(lineaEsc(((data.notaRestaurante) ? "COMENTARIO CLIENTE: " + data.notaRestaurante : '')))

            res = res.concat(ALIGN_LEFT)
                .concat(BOLD)
                .concat(LINEA_HORIZONTAL)
                .concat(ALIGN_LEFT)
            var impuestos = 0;
            var productos = data.productos.filter(p => p.cantidad > 0);
            productos.forEach(prd => {
                let s = "";
                if (prd.porPeso) {
                    s = s + (prd.cantidad + "kg ").padEnd(5) + quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre)
                } else {
                    s = s + (prd.cantidad + "x ").padEnd(5) + quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre)
                }

                s = s.padEnd(34);
                let precioUnitario = !!prd.precioProductoTotalUnitario ? prd.precioProductoTotalUnitario : prd.precio;
                precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
                //if (prd.porPeso || prd.cantidad > 1)
                //    s = s + precioUnitario

                let precioTotal = !!prd.precioProductoTotal ? prd.precioProductoTotal : prd.precio * prd.cantidad;
                impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
                let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
                s = s + precioUnitario + precioTotalString
                res = res.concat(lineaEsc(s))
                if (prd.opciones) {
                    let opcionesImpresas = [];
                    prd.opciones.forEach((e, i) => {
                        if (!opcionesImpresas.includes(e)) {
                            let arrayUsar = Array.isArray(prd.opcionesNombreEs) ? prd.opcionesNombreEs : prd.opcionesName
                            opcionesImpresas.push(e);
                            var ocurrencias = prd.opciones.reduce((a, v) => (v === e ? a + 1 : a), 0);
                            let s = "";
                            s = (ocurrencias > 1) ?
                                "   " + ocurrencias + "- " + quitarAcentos(arrayUsar[i]) :
                                "   - " + quitarAcentos(arrayUsar[i]);
                            s = s.padEnd(43);
                            /*let precio = "" + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
                            precio = precio.padStart(5);
                            s = s + precio*/
                            // carritoString = carritoString + encoder.line(s)
                            res = res.concat(lineaEsc(s))
                        }

                    })
                }
            });
            if (data.recargo && data.recargo > 0) {
                let s = "";
                // carritoString = carritoString + encoder.line(s)
                // res = res.concat(lineaEsc(s))
                s = "";
                s = s + "   Recargo domicilio ";
                s = s.padEnd(36);
                let precio = mostrarMoneda(data.recargo).padStart(5);
                s = s + precio
                // carritoString = carritoString + encoder.line(s)
                res = res.concat(lineaEsc(s))
            }

            const impuestosImprimir = data.impuestosTotal ? data.impuestosTotal : impuestos;
            let precioTotal = data.precioTotal;
            // var carritoProductos = new Uint8Array(carritoString);
            var precioFinal = "TOTAL: " + mostrarMoneda(precioTotal);
            precioFinal = precioFinal;


            res = res.concat(ALIGN_LEFT)
                .concat(LINEA_HORIZONTAL)
                .concat(ALIGN_RIGHT)
                .concat(FUENTE_GRANDE)
                .concat(FONT_HACHATADA)
            if (data.forma_pago == 'efectivo' && Array.isArray(data.cobradoDesglose) && data.cobradoDesglose.length == 1) { //sólo un pago
                let cambioFound = data.cobradoDesglose.find(f => f.formaPago == 'efectivo')
                if (!!cambioFound) {
                    res = res
                        .concat(lineaEsc("ENTREGADO: " + mostrarMoneda(cambioFound.entregado, true)))
                        .concat(lineaEsc(' CAMBIO: ' + mostrarMoneda(cambioFound.cambio, true)))

                }
            }


            res = res
                .concat(BOLD_NORMAL)
                .concat(lineaEsc(("BASE IMPONIBLE:   " + mostrarMoneda(parseFloat(precioTotal) - impuestosImprimir))))
                .concat(lineaEsc(("IMPUESTOS:   " + mostrarMoneda(impuestosImprimir))))
                .concat(FUENTE_GRANDE)
                .concat(lineaEsc(precioFinal))
                .concat(LINEA)
                .concat(FUENTE_NORMAL)

                .concat(LINEA)
                .concat(ALIGN_CENTER)

            if (data.facturaCliente) {
                // create QRCode Image
                async function getQRCode(content) {
                    return new Promise((resolve, reject) => {
                        QRCode.toDataURL(content, (err, url) => {
                            if (err) return reject(err)

                            loadImage(url).then(resolve, reject);
                        })
                    })
                }
                const qrcode = await getQRCode(data.facturaCliente);
                let encoder = new EscPosEncoder();
                const result = encoder
                    .initialize()
                    .align('center')
                    .image(qrcode, 320, 320)
                    .encode()

                res = res
                    .concat(ALIGN_CENTER)
                    .concat(lineaEsc("DESCARGUE SU FACTURA"))
                    .concat([...result])
                    .concat(ALIGN_CENTER)
                    .concat(LINEA)
                    .concat(LINEA)
                    .concat(LINEA)



            }

            res = res
                .concat(lineaEsc("Gracias por su pedido"))
                .concat(lineaEsc("By FunEats"))


            res = res.concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)

            res = res.concat(CORTE)
            console.log(res);
            return new Uint8Array(res)
        })






    //let img = await getBase64FromImageUrl("https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/PNG_transparency_demonstration_2.png/600px-PNG_transparency_demonstration_2.png")





}

export const impresionFacturaSimplificada = async function (data, factSimplificada, logo) {
    var res = [];
    return new Promise((resolve) => {
        if (!logo) resolve([]);

        var img = new Image()

        //listener has to be added before setting the src attribute in case the image is cached
        img.addEventListener('load', (r) => {

            let encoder = new EscPosEncoder();
            let result = encoder
                .image(img, 480, 240, 'atkinson')
                .encode()
            resolve(result)
        });
        img.src = 'data:image/png;base64,' + logo;

    })
        .then(async (logo) => {
            res = res
                .concat(ESC_INIT)
                .concat(ALIGN_CENTER)
                .concat([...logo])

            const negocio = data.copyNegocio;
            const restaurante = data.copyRest;
            const timezone = (restaurante && restaurante.confGeneral && restaurante.confGeneral.zonaHoraria) ? restaurante.confGeneral.zonaHoraria : 'Europe/Madrid';
            var fechaMoment = (data.fechaServidor.seconds) ?
                moment.unix(data.fechaServidor.seconds).tz(timezone) :
                moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);
            const formaPago = idToTexto(factSimplificada.formaPago).toUpperCase();
            res = res.concat(ALIGN_CENTER)
                .concat(lineaEsc(quitarAcentos(data.copyNegocio.nombre)))
                .concat(lineaEsc(quitarAcentos(data.copyRest.direccion)))
                .concat(lineaEsc((data.copyRest.cif + ' | ' + data.copyRest.telefono)))
                .concat(BOLD)
            /*.concat(lineaEsc('TICKET: 00' + data.numPedidoRest + ' - ' + fechaMoment.format('DD/MM/YYYY') + ' - ' + fechaMoment.format('HH:mm')))
            .concat(BOLD)
            .concat(FUENTE_MAS_GRANDE)
            .concat(LINEA)
            .concat(INVERTIR)
        if (data.multiple && data.multipleHijos && data.multipleHijos.length) {//hijo
            cabecera
                .line('PEDIDO MULTIPLE')
                .raw([0x1D, 0x21, 0x00])
            data.multipleHijos.forEach(h => {
                cabecera
                    .line(h)
            })
            cabecera.newline()
        } else if (data.multiplePadre) { //padre
            cabecera
                .line('MULTI-00' + data.numPedidoRestDiaDirectorio + ' - ' + data.modo.toUpperCase())
        } else {
            // let f = this.$store.state.datosTpv.modos.find((m) => m.id == this.modoid);
            //   return f ? f.nombre : "";
            let modoMostrar = !!data.modoTexto ? data.modoTexto.toUpperCase() : data.modo.toUpperCase()
            res = res.concat(lineaEsc('00' + data.numPedidoRestDia + ' - ' + modoMostrar))
        }*/
            res = res.concat(FUENTE_NORMAL) //miriad undereline
                .concat(STOP_INVERTIR)
                .concat(LINEA)
                .concat(ALIGN_CENTER)
                .concat(BOLD)
                .concat(lineaEsc("FACTURA SIMPLIFICADA"))
                .concat(lineaEsc("NUMERO DE FACTURA: " + factSimplificada.codigoFactura))
                .concat(LINEA)
                .concat(ALIGN_LEFT)
                .concat(BOLD)
                .concat(lineaEsc("FECHA PEDIDO: " + fechaMoment.format('DD/MM/YYYY') + ' - ' + fechaMoment.format('HH:mm')))
                .concat(lineaEsc("FECHA EMISION FACTURA: " + moment.unix(factSimplificada.moment).format('DD/MM/YYYY') + ' - ' + moment.unix(factSimplificada.moment).format('HH:mm')))
            // .concat(lineaEsc("FECHA IMPRESION: " + moment().format('DD/MM/YYYY') + ' - ' + moment().format('HH:mm')))


            // if ((data.modo == 'domicilio' || data.modo == 'recogida') && !!data.copyUser && !!data.copyUser.phoneNumber)
            //     res = res.concat(lineaEsc("TELEFONO: " + data.copyUser.phoneNumber.padStart(5)))


            // if (data.modo == 'domicilio' || data.modo == 'recoger')
            //     res = res.concat(lineaEsc((data.modo == 'domicilio') ? "TIEMPO ESTIMADO: " + data.copyRest.tiempoDomicilio + ' min.' : "TIEMPO ESTIMADO: " + data.copyRest.tiempoRecogida + ' min.'))

            // .bold()
            // .underline()
            // .size()
            // .align("center")
            // .raw([0x1D, 0x21, 0x11]) //tamaño grande
            // .newline()
            // .line('PEDIDO ONLINE')
            // .raw([0x1D, 0x21, 0x00])
            // .underline("false")
            // .align("left")
            // .bold("false")
            // .newline()
            res = res.concat(lineaEsc("FORMA PAGO: " + formaPago));

            // if (data.notaRestaurante)
            //     res = res.concat(lineaEsc(((data.notaRestaurante) ? "COMENTARIO CLIENTE: " + data.notaRestaurante : '')))

            res = res.concat(ALIGN_LEFT)
                .concat(BOLD)
                .concat(LINEA_HORIZONTAL)
                .concat(ALIGN_LEFT)

            var impuestos = 0;
            console.log(factSimplificada);
            var productos = factSimplificada.productos.filter(p => p.cantidad > 0);
            console.log(factSimplificada)
            productos.forEach(prd => {
                let s = "";
                if (prd.porPeso) {
                    s = s + (prd.cantidad + "kg ").padEnd(5) + quitarAcentos(prd.nombre)
                } else {
                    s = s + (prd.cantidad + "x ").padEnd(5) + quitarAcentos(prd.nombre)
                }

                s = s.padEnd(34);
                let precioUnitario = !!prd.precioProductoTotalUnitario ? prd.precioProductoTotalUnitario : prd.precio;
                precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
                //if (prd.porPeso || prd.cantidad > 1)
                //    s = s + precioUnitario

                let precioTotal = !!prd.precioProductoTotal ? prd.precioProductoTotal : prd.precioProductoTotalUnitario * prd.cantidad;
                impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
                let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
                s = s + precioUnitario + precioTotalString
                res = res.concat(lineaEsc(s))
                if (prd.opciones) {
                    let opcionesImpresas = [];
                    prd.opciones.forEach((e, i) => {
                        if (!opcionesImpresas.includes(e)) {
                            opcionesImpresas.push(e);
                            var ocurrencias = prd.opciones.reduce((a, v) => (v === e ? a + 1 : a), 0);
                            let s = "";
                            s = (ocurrencias > 1) ?
                                "   " + ocurrencias + "- " + quitarAcentos(prd.opcionesName[i]) :
                                "   - " + quitarAcentos(prd.opcionesName[i]);
                            s = s.padEnd(43);
                            /*let precio = "" + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
                            precio = precio.padStart(5);
                            s = s + precio*/
                            // carritoString = carritoString + encoder.line(s)
                            res = res.concat(lineaEsc(s))
                        }

                    })
                }
            });
            if (data.recargo && data.recargo > 0) {
                let s = "";
                // carritoString = carritoString + encoder.line(s)
                // res = res.concat(lineaEsc(s))
                s = "";
                s = s + "   Recargo domicilio ";
                s = s.padEnd(36);
                let precio = mostrarMoneda(data.recargo).padStart(5);
                s = s + precio
                // carritoString = carritoString + encoder.line(s)
                res = res.concat(lineaEsc(s))
            }

            const impuestosImprimir = factSimplificada.impuestosTotalesPago ? factSimplificada.impuestosTotalesPago : impuestos;
            let precioTotal = factSimplificada.pagado;
            // var carritoProductos = new Uint8Array(carritoString);
            var precioFinal = "TOTAL: " + mostrarMoneda(precioTotal);
            precioFinal = precioFinal;

            res = res.concat(ALIGN_LEFT)
                .concat(LINEA_HORIZONTAL)
                .concat(ALIGN_RIGHT)
                .concat(FUENTE_GRANDE)
                .concat(FONT_HACHATADA)
            if (factSimplificada.formaPago == 'efectivo' && factSimplificada.entregado !== 'undefined') { //sólo un pago
                res = res
                    .concat(lineaEsc("ENTREGADO: " + mostrarMoneda(factSimplificada.entregado, true)))
                    .concat(lineaEsc(' CAMBIO: ' + mostrarMoneda(factSimplificada.cambio, true)))
            }
            let baseImponibleImprimir = factSimplificada.baseImponiblePago ? factSimplificada.baseImponiblePago : parseFloat(precioTotal) - impuestosImprimir
            res = res
                .concat(BOLD_NORMAL)
                .concat(lineaEsc(("BASE IMPONIBLE:   " + mostrarMoneda(baseImponibleImprimir))))
                .concat(lineaEsc(("IMPUESTOS:   " + mostrarMoneda(impuestosImprimir))))
                .concat(FUENTE_GRANDE)
                .concat(lineaEsc(precioFinal))
                .concat(LINEA)
                .concat(FUENTE_NORMAL)

                .concat(LINEA)
                .concat(ALIGN_CENTER)

            if (factSimplificada.ficheroFactura) {
                // create QRCode Image
                async function getQRCode(content) {
                    return new Promise((resolve, reject) => {
                        QRCode.toDataURL(content, (err, url) => {
                            if (err) return reject(err)

                            loadImage(url).then(resolve, reject);
                        })
                    })
                }
                const qrcode = await getQRCode(factSimplificada.ficheroFactura);
                let encoder = new EscPosEncoder();
                const result = encoder
                    .initialize()
                    .align('center')
                    .image(qrcode, 320, 320)
                    .encode()

                res = res
                    .concat(ALIGN_CENTER)
                    .concat(lineaEsc("DESCARGUE SU FACTURA"))
                    .concat([...result])
                    .concat(ALIGN_CENTER)
                    .concat(LINEA)
                    .concat(LINEA)
                    .concat(LINEA)



            }

            res = res
                .concat(lineaEsc("Gracias por su pedido"))
                .concat(lineaEsc("By FunEats"))


            res = res.concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)
                .concat(LINEA)

            res = res.concat(CORTE)
            return new Uint8Array(res)
        })

}

export const imprimirTicketCocina = function (data, impObj, filtrarImpresos = false) { //filtrar impresos true devuelve imprime solo los productos no impresos. Devuelve {datos, productos}
    const categoriasExcluir = impObj.categoriasSelects ? impObj.categoriasSelects : [];
    const productosSi = impObj.productosSelectsSi ? impObj.productosSelectsSi : [];
    const productosNo = impObj.productosSelectsNo ? impObj.productosSelectsNo : [];
    const reglasEscritura = impObj.reglasEscritura;
    const sizeCocina = impObj.sizeCocina ? impObj.sizeCocina : 'big';
    var res = []; //datos;
    var productosImprime = [];
    const negocio = data.copyNegocio;
    const restaurante = data.copyRest;
    const timezone = (restaurante.confGeneral.zonaHoraria) ? restaurante.confGeneral.zonaHoraria : 'Europe/Madrid';
    var fechaMoment = (data.fechaServidor.toDate()) ?
        moment(data.fechaServidor.toDate()).tz(timezone) :
        moment(data.fechaServidorFinalEjecucion.toDate()).tz(timezone);
    res = res.concat(ESC_INIT)
        .concat(ALIGN_CENTER)
        .concat(lineaEsc(fechaMoment.format('DD/MM/YYYY') + ' ' + fechaMoment.format('HH:mm') + ' | ' + (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase() + ' | COCINA'))
        .concat(FUENTE_NORMAL)
        //underline
        .concat(ALIGN_CENTER)
        // .concat(lineaEsc('TICKET DIA: 00' + data.numPedidoRestDia))
        .concat(lineaEsc('TICKET: 00' + data.numPedidoRest + '  ' + 'DIA: 00' + data.numPedidoRestDia))
        // .concat(lineaEsc('TICKET REST DIA: 00' + data.numPedidoRestDia))
        .concat(BOLD)
        .concat(FUENTE_MAS_GRANDE)
        .concat(LINEA)
        .concat(INVERTIR)

    if (data.multiple && data.multipleHijos && data.multipleHijos.length) {
        cabecera = cabecera
            .line('PEDIDO MULTIPLE')
            .raw([0x1D, 0x21, 0x00])
        data.multipleHijos.forEach(h => {
            cabecera = cabecera
                .line(h)
        })
        cabecera = cabecera.newline()
    } else if (data.multiplePadre) {

        cabecera = cabecera
            .line('MULTI-00' + data.numPedidoRestDiaDirectorio + ' - ' + data.modo.toUpperCase())
    } else {
        let cabeceraMostrar = '';
        if (
            data.copyMesa &&
            data.modoCopy
        ) {
            cabeceraMostrar = (
                "MESA " +
                data.copyMesa.nombre +
                " " +
                data.modoCopy.nombre
            );
        } else if (data.modoCopy) {
            cabeceraMostrar =
                data.modoCopy.nombre +
                (data.numPedidoRestDia
                    ? " " + data.numPedidoRestDia
                    : ""
                );
        }
        if (!cabeceraMostrar) {

            switch (data.modo) {
                case "domicilio":
                    res = "DOMICILIO";
                    break;
                case "recoger":
                    res = "RECOGER";
                    break;
                case "mesa":
                    res = "MESA " + data.copyMesa.nombre;
                    break;
                case "mostrador":
                    res = "MOSTRADOR ";
                    break;
            }
            cabeceraMostrar = res;
        }

        // let cabeceraMostrar = (data.modo == 'mesa')
        //     ? data.copyMesa.nombre
        //     : (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase()
        res = res.concat(lineaEsc(cabeceraMostrar))
    }


    res = res
        .concat(LINEA)
        .concat(STOP_INVERTIR)
        
    if (!!data.comentarioPedidoTpv && !!data.comentarioPedidoTpv.length > 0) {
        res = res
            .concat(FUENTE_NORMAL)
            .concat(LINEA_HORIZONTAL)
            .concat(ALIGN_LEFT)
            .concat(FONT_HACHATADA)
            .concat(lineaEsc((data.comentarioPedidoTpv)))
            .concat(FUENTE_NORMAL)
            .concat(LINEA_HORIZONTAL)
    }
    if (!!data.notaRestaurante) {
        res = res
            .concat(lineaEsc("COMENTARIO CLIENTE: "))       
            .concat(lineaEsc((data.notaRestaurante)))
    }
    res = res
        .concat(BOLD)
        .concat(ALIGN_LEFT)
        .concat(LINEA)
        .concat((sizeCocina === 'big') ? FUENTE_GRANDE : FUENTE_MEDIANA)



    var impuestos = 0;
    var productos = data.productos;
    var imprime = false;

    productos.forEach((prd, linea) => {
        var cantidadImprimir = prd.cantidad;
        var cantidadCarrito = !!data.eliminado ? 0 : prd.cantidad;
        let impresionCocina = !!data.impresionCocina ? data.impresionCocina : []

        // console.log(prd.id);
        // console.log(prd.categoria);
        // console.log(categoriasExcluir);
        // console.log(productosNo);
        // console.log(productosSi);

        if ((!categoriasExcluir.includes(prd.categoria) && !productosNo.includes(prd.id)) || productosSi.includes(prd.id)) {
            // console.log("si imprime")
            let imprimeConcreto = false; //va a imprimir este producto
            let idAux = [prd.id, ...(!!Array.isArray(prd.opciones) ? prd.opciones : [])].join('_');

            let foundImpresion = impresionCocina.find(imP => imP.id == idAux) //se ha imprimido algún ticket de cocina
            let anular = false;
            if (filtrarImpresos) {

                if (!!foundImpresion) {
                    if (foundImpresion.cantidad < prd.cantidad && prd.cantidad != 0) {
                        imprime = true;
                        imprimeConcreto = true;
                        cantidadImprimir = cantidadCarrito - foundImpresion.cantidad
                    } else if (foundImpresion.cantidad > cantidadCarrito) {
                        //console.log(prd.cantidad);
                        //console.log(foundImpresion.cantidad);

                        imprime = true;
                        imprimeConcreto = true;
                        cantidadImprimir = foundImpresion.cantidad - cantidadCarrito
                        anular = true;
                    }
                } else if (cantidadCarrito != 0) {
                    imprime = true;
                    imprimeConcreto = true;

                }
                if (imprimeConcreto) {
                    productosImprime.push({
                        id: idAux,
                        cantidad: anular ? -cantidadImprimir : cantidadImprimir
                    })
                }

            } else if (cantidadCarrito != 0) {
                productosImprime.push({
                    id: idAux,
                    cantidad: prd.cantidad
                })
                imprime = true;
                imprimeConcreto = true;
            }
            if (imprimeConcreto) {
                let nombreImprimir = prd.nombre
                //console.log(productosImprime)
                if (reglasEscritura && Array.isArray(reglasEscritura)) {
                    var found = reglasEscritura.find(r => r.antes == nombreImprimir);
                    if (found) {
                        nombreImprimir = found.despues;
                    }
                }
                nombreImprimir = prd.nombreTpv ? prd.nombreTpv : nombreImprimir;
                if (anular) {
                    nombreImprimir = '[ANUL] ' + nombreImprimir
                }
                let s = "";
                if (prd.porPeso) {
                    s = s + prd.cantidad + "kg " + quitarAcentos(nombreImprimir)
                } else {
                    s = s + cantidadImprimir + "x " + quitarAcentos(nombreImprimir)
                }
                //console.log(s);
                res = res.concat(lineaEsc(s))
                if (prd.opciones) {
                    prd.opciones.forEach((e, i) => {
                        var nombreOp = prd.opcionesName[i];
                        if (reglasEscritura && Array.isArray(reglasEscritura)) {
                            var foundOp = reglasEscritura.find(r => r.antes == nombreOp);
                            if (foundOp) {
                                nombreOp = foundOp.despues;
                            }
                        }
                        nombreOp = prd.opcionesTpv && prd.opcionesTpv[i] ? prd.opcionesTpv[i] : nombreOp;
                        if (nombreOp && nombreOp.length > 0) {
                            let s = "";
                            s = "   -" + quitarAcentos(nombreOp);
                            //console.log(s);

                            res = res.concat(lineaEsc(s))
                        }

                    })
                }
                if (prd.comentarioCocina && prd.comentarioCocina.length > 0) {
                    s = "COMENTARIO: " + quitarAcentos(prd.comentarioCocina)
                    res = res.concat(lineaEsc(s))
                }
            }

        }
    });
    if (!imprime)
        return false;
    res = res.concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)

    res = res.concat(CORTE)
    return { productos: productosImprime, datos: new Uint8Array(res) }

}


export const zcierre = function (pedidos, resumenes, restaurante, fecha = null) {
    var res = [];
    const timezone = (restaurante.timezone) ? restaurante.timezone : 'Europe/Madrid';
    let fechaDefinitiva = !fecha ? moment() : fecha
    let fechaFormateada = !fecha ? 'Fecha: ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY HH:mm') : 'Cierre del dia ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY')
    res = res.concat(ESC_INIT)
        .concat(FUENTE_NORMAL)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(ALIGN_CENTER)
        .concat(lineaEsc('Z CIERRE'))
        .concat(LINEA)
        .concat(lineaEsc(fechaFormateada))
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(BOLD)
        .concat(ALIGN_LEFT)

    res = res.concat(lineaEsc(("TOTAL: ").padEnd(35) + mostrarMoneda(resumenes.cobrados.totales.total.toString()).padStart(5)))
        .concat(lineaEsc(("TOTAL PEDIDOS: ").padEnd(35) + mostrarMoneda(resumenes.cobrados.totales.numPedidos.toString()).padStart(5)))
        .concat(lineaEsc(("TOTAL EFECTIVO: ").padEnd(35) + mostrarMoneda(resumenes.cobrados.totales.efectivo.toString()).padStart(5)))
        .concat(lineaEsc(("TOTAL TARJETA DOMICILIO: ").padEnd(35) + mostrarMoneda(resumenes.cobrados.totales.tarjeta_domicilio.toString()).padStart(5)))
        .concat(lineaEsc(("TOTAL TARJETA ONLINE: ").padEnd(35) + mostrarMoneda(resumenes.cobrados.totales.tarjeta_online.toString()).padStart(5)))
        .concat(lineaEsc(("TOTAL TARJETA LOCAL: ").padEnd(35) + mostrarMoneda(resumenes.cobrados.totales.tarjeta_local.toString()).padStart(5)))
        .concat(lineaEsc(("TOTAL OTRO: ").padEnd(35) + mostrarMoneda(resumenes.cobrados.totales.otro.toString()).padStart(5)))
        .concat(LINEA)
    /*esumenes.forEach((dato, i) => {
        res = res
            .concat(lineaEsc((dato.nombre + " TOTAL: ").padEnd(35) + mostrarMoneda(dato.total.toString()).padStart(5)))
        if (i == 0) {
            console.log((dato.nombre + " PEDIDOS: ").padEnd(35) + mostrarMoneda(dato.numPedidos.toString()).padStart(5))
            console.log((dato.nombre + " EFECTIVO: ").padEnd(35) + mostrarMoneda(dato.efectivo.toString()).padStart(5))
            console.log((dato.nombre + " TARJETA DOMICILIO: ").padEnd(35) + mostrarMoneda(dato.tarjeta_domicilio.toString()).padStart(5))
            console.log((dato.nombre + " TARJETA ONLINE: ").padEnd(35) + mostrarMoneda(dato.tarjeta_online.toString()).padStart(5))
            console.log((dato.nombre + " TARJETA LOCAL: ").padEnd(35) + mostrarMoneda(dato.tarjeta_local.toString()).padStart(5))
            console.log((dato.nombre + " OTRO: ").padEnd(35) + mostrarMoneda(dato.otro.toString()).padStart(5))
            res = res.concat(lineaEsc((dato.nombre + " PEDIDOS: ").padEnd(35) + mostrarMoneda(dato.numPedidos.toString()).padStart(5)))
                .concat(lineaEsc((dato.nombre + " EFECTIVO: ").padEnd(35) + mostrarMoneda(dato.efectivo.toString()).padStart(5)))
                .concat(lineaEsc((dato.nombre + " TARJETA DOMICILIO: ").padEnd(35) + mostrarMoneda(dato.tarjeta_domicilio.toString()).padStart(5)))
                .concat(lineaEsc((dato.nombre + " TARJETA ONLINE: ").padEnd(35) + mostrarMoneda(dato.tarjeta_online.toString()).padStart(5)))
                .concat(lineaEsc((dato.nombre + " TARJETA LOCAL: ").padEnd(35) + mostrarMoneda(dato.tarjeta_local.toString()).padStart(5)))
                .concat(lineaEsc((dato.nombre + " OTRO: ").padEnd(35) + mostrarMoneda(dato.otro.toString()).padStart(5)))
                .concat(LINEA)
        }

    })*/
    res = res
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(ALIGN_LEFT)
        .concat(lineaEsc("NUMERO".padEnd(12) + "MODO".padEnd(12) + "PAGO".padEnd(12) + "CANTIDAD".padEnd(12)))
        .concat(BOLD_NORMAL)
        .concat(LINEA)

    pedidos.forEach(ped => {
        if (Array.isArray(ped.cobradoDesglose)) {
            var multiplePago = ped.cobradoDesglose.length > 1;
            ped.cobradoDesglose.forEach(p => {

                let numero = ped.numPedidoRestDia ? ped.numPedidoRestDia.toString().padEnd(10) : '';
                let envio = idToTexto(ped.modo).padEnd(12);
                let formaPago = ((multiplePago ? '* ' : '') + idToTexto(p.formaPago)).padEnd(18);
                let cantidad = mostrarMoneda(p.pagado).padStart(7);
                let s = numero + envio + formaPago + cantidad;

                res = res.concat(lineaEsc(s))
            })
        } else {
            let numero = ped.numPedidoRestDia.toString().padEnd(10);
            let envio = idToTexto(ped.modo).padEnd(12);
            let formaPago = idToTexto(ped.forma_pago).padEnd(18);
            let cantidad = mostrarMoneda(ped.precioTotal).padStart(7);
            let s = numero + envio + formaPago + cantidad;
            console.log("SIN DESGLOSE " + s);
            res = res.concat(lineaEsc(s))
        }
    })

    res = res.concat(LINEA)
    res = res.concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)

    res = res.concat(CORTE)
    return new Uint8Array(res)
}

export const imprimirPararCocina = function (data) {
    var res = []
    const negocio = data.copyNegocio;
    const restaurante = data.copyRest;
    const timezone = (restaurante.confGeneral.zonaHoraria) ? restaurante.confGeneral.zonaHoraria : 'Europe/Madrid';
    var fechaMoment = moment().tz(timezone)

    res = res.concat(ESC_INIT)
        .concat(LINEA)
        .concat(ALIGN_CENTER)
        .concat(FUENTE_NORMAL)
        .concat(lineaEsc('FECHA DEL MENSAJE: ' + fechaMoment.format('DD/MM/YYYY') + ' Hora: ' + fechaMoment.format('HH:mm')))
        .concat(FUENTE_GRANDE)
        .concat(LINEA)
        .concat(lineaEsc('PARAR COCINA'))
        .concat(lineaEsc('TICKET ' + data.numPedidoRest))
        .concat(lineaEsc('TICKET DIA ' + data.numPedidoRestDia))
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(CORTE)

    return new Uint8Array(res)
}

export const imprimirReanudarCocina = function (data) {
    var res = []
    const negocio = data.copyNegocio;
    const restaurante = data.copyRest;
    const timezone = (restaurante.confGeneral.zonaHoraria) ? restaurante.confGeneral.zonaHoraria : 'Europe/Madrid';
    var fechaMoment = moment().tz(timezone)

    res = res.concat(ESC_INIT)
        .concat(LINEA)
        .concat(ALIGN_CENTER)
        .concat(FUENTE_NORMAL)
        .concat(lineaEsc('FECHA DEL MENSAJE: ' + fechaMoment.format('DD/MM/YYYY') + ' Hora: ' + fechaMoment.format('HH:mm')))
        .concat(FUENTE_GRANDE)
        .concat(LINEA)
        .concat(lineaEsc('REANUDAR COCINA'))
        .concat(lineaEsc('TICKET ' + data.numPedidoRest))
        .concat(lineaEsc('TICKET DIA ' + data.numPedidoRestDia))
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA)
        .concat(CORTE)

    return new Uint8Array(res)
}

export const abrirCaja = function () {
    var res = [];
    res = res.concat(ESC_INIT)
        .concat(ABRIR_CAJON)
    return new Uint8Array(res)

}

