<template>
  <v-dialog
    v-model="moverPedidoModoDialog"
    max-width="800px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="
      $vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'
    "
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title
        class="white py-3 pr-3" height="78"
      >
        <!-- <v-icon color="info" class="mr-4"> mdi-swap-horizontal-bold </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          MOVER PEDIDO - {{ tituloPidiendo }} {{ numeroPidiendo }}
        </h2>
        <v-spacer />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="moverPedidoModoDialog = false"
          top
          right
        >
          <v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
            color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4 blue-grey lighten-5">
        <v-row>
          <v-col
            v-for="(modo, i) in valoresSeleccionar"
            :key="i"
            cols="6"
            sm="4"
            md="4"
          >
            <v-card
              class="rounded-lg ficha-generica"
              elevation="4"
              @click="cambiarModo(modo)"
            >
              <v-responsive :aspect-ratio="1.618" class="align-center">
                <v-img :src="modo.imagen">
                  <v-card-text
                    class="text-uppercase text-center font-weight-black"
                    :class="
                      $vuetify.breakpoint.smAndDown
                        ? 'subtitle-2'
                        : $vuetify.breakpoint.mdAndDown
                        ? 'headline'
                        : 'display-1'
                    "
                    ><v-spacer></v-spacer> {{ modo.nombre }}<v-spacer></v-spacer
                  ></v-card-text>
                </v-img>
              </v-responsive>
            </v-card>
          </v-col> </v-row></v-card-text></v-card
  ></v-dialog>
</template>

<script>
export default {
  watch: {
    moverPedidoModoDialog(v) {
      if (!v) {
      }
    },
  },
  computed: {
    tituloPidiendo() {
      var res = "";
      if (!!this.pedidoEditandoObj) {
        if (this.pedidoEditandoObj.modoCopy)
          return this.pedidoEditandoObj.modoCopy.nombre;
        switch (this.pedidoEditandoObj.modo) {
          case "domicilio":
            res = "DOMICILIO";
            break;
          case "recoger":
            res = "RECOGER";
            break;
          case "mesa":
            res = "MESA " + this.pedidoEditandoObj.copyMesa.nombre;
            break;
          case "mostrador":
            res = "MOSTRADOR ";
            break;
        }
      }
      return res;
    },
    numeroPidiendo() {
      return this.pedidoEditandoObj
        ? this.pedidoEditandoObj.numPedidoRestDia
        : "-";
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
    },
    valoresSeleccionar() {
      return [
        ...this.$store.state.datosTpv.modos
          .filter((m) => {
            return (
              (m.id != this.pedidoEditandoObj.modoId &&
                (m.tipoModo === "mostrador" ||
                  m.tipoModo === "domicilio" ||
                  m.tipoModo === "recoger")) ||
              m.tipoModo === "mesas"
            );
          })
          .map((m) => {
            if (m.tipoModo === "mesas") {
              let mesasModo = this.$store.getters["mesasFiltradas"].filter(
                (mMesa) =>
                  mMesa.modo == m.id &&
                  mMesa.id != this.pedidoEditandoObj.mesa &&
                  !mMesa.pedido
              );

              return mesasModo.map((mesa) => {
                return {
                  modo: m,
                  mesa: mesa,
                  nombre: m.nombre + " MESA " + mesa.nombre,
                };
              });
            } else {
              return { modo: m, nombre: m.nombre };
            }
          })
          .flat(),
      ];
    },
    moverPedidoModoDialog: {
      get() {
        return this.$store.state.pedidos.moverPedidoModoDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setMoverPedidoModoDialog", v);
      },
    },
  },
  methods: {
    async cambiarModo(modo) {
      let mesaNueva = !!modo.mesa ? modo.mesa.id : false;
      let mesaAntigua = !!this.pedidoEditandoObj.mesa
        ? this.pedidoEditandoObj.mesa
        : false;
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoCopy",
        value: modo.modo,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modo",
        value: modo.modo.tipoModo == "mesas" ? "mesa" : modo.modo.tipoModo,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoId",
        value: modo.modo.id,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "mesaCopy",
        value: null,
      });

      if (mesaAntigua) {
        await this.$store.dispatch("pedidos/liberarMesa", mesaAntigua);
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "mesa",
          value: null,
        });
      }

      if (mesaNueva) {
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "copyMesa",
          value: modo.mesa,
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "mesa",
          value: mesaNueva,
        });
        await this.$store.dispatch("pedidos/grabarPedidoMesa", {
          mesa: mesaNueva,
          pedido: this.pedidoEditandoObj.id,
        });
      }
      this.moverPedidoModoDialog = false;
    },
  },
};
</script>

<style>
</style>