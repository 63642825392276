<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  chartId: "pedidos-horas-barras",
  extends: Bar,
  mixins: [reactiveProp],

  props: ["options"],
  mounted() {
    console.log(this);
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style>
</style>