<template>
  <v-card
    elevation="4"
    :class="!pedido ? 'libre' : 'con-comensales'"
    class="rounded-lg ficha-generica"
    @click="goPedido"
    :disabled="pedido && pedido.id == $store.state.impresion.pedidoOcupado"
  >
    <v-responsive class="d-flex rounded-0" :aspect-ratio="1.618">
      <v-img src="/img/fondoFichaFunEats.svg">
        <v-card-text style="position: relative" class="pa-0">
          <v-list class="pb-0 pt-0 pt-md-2" color="transparent">
            <v-list-item class="pl-0 pr-16">
              <v-list-item-content class="py-0 align-self-start">
                <v-list-item-title
                  class="
                    px-2 px-lg-3
                    text-uppercase
                    font-weight-black
                    black--text
                    text-lg-h5
                  "
                  :class="
                    $vuetify.breakpoint.xsOnly
                      ? 'py-1'
                      : $vuetify.breakpoint.mdAndDown
                      ? 'body-2'
                      : 'title'
                  "
                >
                  {{ nombreMostrar }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="pedidoComputed.nombrePersonalizadoTpv"
                  class="
                    px-2
                    pl-lg-3
                    pr-lg-10
                    text-lg-h6
                    mt-n2 mt-md-0
                    font-weight-black
                    cyan--text
                  "
                >
                  {{ pedidoComputed.nombrePersonalizadoTpv }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div
            v-if="numeroMesa !== undefined"
            class="
              card--numero-pedido
              px-2 px-lg-3
              text-lg-h2 text-sm-h3 text-h4
              mt-lg-2
              font-weight-black
              cyan--text
              text--lighten-2
            "
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'font-size: 2.75rem !important;'
                : $vuetify.breakpoint.lgAndUp
                ? 'font-size: 5rem !important;'
                : ''
            "
          >
            <span v-if="numeroMesa <= 9">0</span>{{ numeroMesa }}
          </div>
        </v-card-text>
        <v-card-subtitle
          :class="$vuetify.breakpoint.mdAndDown ? 'body-2 pt-0' : 'title'"
          class="px-2 mt-n3 mt-md-n1 mt-lg-n3 font-weight-medium"
          :style="$vuetify.breakpoint.xsOnly ? 'margin-top: -4px;' : ''"
        >
          {{ nombreClienteMostrar }}</v-card-subtitle
        >

        <!-- <v-card-subtitle
        :class="$vuetify.breakpoint.mdAndDown ? 'px-2' : ''"
        class="text-uppercase text-truncate"
      >
        {{ pedidoComputed.modo }}
      </v-card-subtitle> -->
        <v-card-actions class="mt-4 acciones-footer" v-if="pedidoComputed">
          <v-row align="end" class="pb-1">
            <v-col
              class="py-0"
              :class="$vuetify.breakpoint.xsOnly ? 'px-2' : ''"
              cols="6"
            >
              <div v-if="comensales" class="text-no-wrap">
                <v-icon
                  :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'"
                  color="blue-grey lighten-1"
                  class="mr-1"
                >
                  mdi-account-outline
                </v-icon>
                <span
                  :class="
                    $vuetify.breakpoint.mdAndDown ? 'body-2' : 'subtitle-1'
                  "
                  class="mr-1 font-weight-medium black--text"
                  >{{ comensales }}</span
                >
              </div>
              <div class="text-no-wrap mt-n2">
                <v-icon
                  :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'"
                  color="blue-grey lighten-1"
                  class="mr-1"
                >
                  mdi-clock-time-eight-outline
                </v-icon>
                <span
                  :class="
                    $vuetify.breakpoint.mdAndDown ? 'body-2' : 'subtitle-1'
                  "
                  class="font-weight-medium black--text"
                  >{{ pedidoComputed.fechaMostrarContador }}</span
                >
              </div>
            </v-col>
            <v-col class="py-0 px-2 pr-lg-3 text-right" cols="6">
              <span
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'px-1'
                    : $vuetify.breakpoint.mdAndDown
                    ? 'body-1'
                    : 'title'
                "
                class="font-weight-black text-no-wrap"
                >{{ precioTotal }}€</span
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-img>
    </v-responsive>
  </v-card>
</template>

<script>
import moment from "moment";
import ProductosCarrito from "@/components/ProductosCarrito.vue";
import { mapState } from "vuex";
import Cobrar from "./Cobrar.vue";
import { mostrarMoneda } from "@/utils/helper.utils.js";

export default {
  components: { ProductosCarrito, Cobrar },
  props: {
    mesa: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    pedido() {
      if (this.mesa.pedido) {
        var f = this.$store.state.pedidos.pedidosRealizandoTpv.find(
          (p) => p.id == this.mesa.pedido
        );
        //if (!f) this.$store.dispatch("pedidos/liberarMesa", this.mesa.id);

        return f ? f : null;
      } else {
        return false;
      }
    },
    mesaLibre() {
      return !this.mesa.pedido;
    },
    mensajesComputados() {
      return this.pedido.historial
        ? this.pedido.historial
            .filter((p) => p.mensaje.includes("Mensaje desde la web"))
            .map((p) => {
              return {
                ...p,
                fechaMostrar: moment.unix(p.fecha).format("DD-MM-YYYY HH:mm"),
              };
            })
        : [];
    },
    pedidoComputed() {
      if (this.pedido) {
        let tz =
          this.pedido.copyRest &&
          this.pedido.copyRest.confGeneral &&
          this.pedido.copyRest.confGeneral.zonaHoraria
            ? this.pedido.copyRest.confGeneral.zonaHoraria
            : "Europe/Madrid";
        let tiempo =
          this.pedido.modo == "domicilio"
            ? this.pedido.copyRest
              ? this.pedido.copyRest.tiempoDomicilio
              : 0
            : this.pedido.copyRest
            ? this.pedido.copyRest.tiempoRecogida
            : 0;
        let actualMoment = moment(this.$store.state.horaActual);
        let fechaMoment = moment(this.pedido.fechaServidor.toDate()).tz(tz);
        let entregaMoment = fechaMoment.clone().add(tiempo, "minutes");
        var durantion = moment.duration(actualMoment.diff(fechaMoment));
        return {
          ...this.pedido,
          fechaMostrarContador: parseInt(durantion.asMinutes()),

          fechaMostrar: moment(this.pedido.fechaServidor.toDate())
            .tz(
              !!this.pedido.copyRest.confGeneral.zonaHoraria
                ? this.pedido.copyRest.confGeneral.zonaHoraria
                : "Europe/Madrid"
            )
            .format("HH:mm"),
        };
      } else {
        return false;
      }
    },
    ...mapState({
      pedidoViendo: (state) => state.pedidos.pedidoEditandoObj,
    }),
    // pedidoViendo() {
    // 	return this.$store.state.pedido.pedidoEditandoObj;
    // },
    pedidoEditando: {
      get() {
        return this.$store.state.pedidos.pedidoEditando;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditando", v);
      },
    },
    // pedidoEditando: {
    //   get() {
    //     return this.$store.state.pedidos.pedidoEditandoObj;
    //   },
    //   set(v) {
    //     this.$store.commit("pedidos/setPedidoEditandoObj", v);
    //   },
    // },
    nombreMostrar() {
      if (!this.pedido) {
        return "MESA LIBRE";
      }
      var res = "";

      switch (this.pedido && this.pedido.modo) {
        case "mesa":
          //res = "Mesa · " + this.pedido.numPedidoRest;
          res = "Mesa";
          break;
      }
      return res;
    },
    numeroMesa() {
      if (this.mesa.nombre) {
        var mesaNumero = this.mesa.nombre.split(" ");
        return mesaNumero[mesaNumero.length - 1];
      } else {
        return undefined;
      }
    },
    nombreClienteMostrar() {
      if (this.pedido) {
        return this.pedido.copyUser && this.pedido.copyUser.phoneNumber
          ? this.pedido.copyUser.phoneNumber
          : this.pedido.copyUser && this.pedido.copyUser.displayName
          ? this.pedido.copyUser.displayName
          : this.pedido.copyUser && this.pedido.copyUser.email
          ? this.pedido.copyUser.email
          : "Cliente";
      }
    },
    comensales() {
      return this.pedido && this.pedido.comensales
        ? this.pedido.comensales
        : false;
    },
    color() {
      if (this.pedido) {
        switch (this.pedido.canalVenta) {
          case "propio": //Online propio
            return "2px solid #56a7a8";
          case "glovo":
            return "2px solid #f9c52e";
          case "justeat":
            return "2px solid #f27700";
          case "ubereats":
            return "2px solid #4fc83d";
          case "funeats":
            return "2px solid #56a7a8";
          case "tpv":
            return "2px solid #56a700";
        }
      }
    },
    precioTotal() {
      return this.pedido ? mostrarMoneda(this.pedido.precioTotal) : 0;
    },
  },
  methods: {
    goPedido() {
      if (!!this.pedido && !!this.pedido.cobrado) {
        return this.$store.dispatch("pedidos/liberarMesa", this.mesa.id);
      } else if (!!this.pedido) {
        this.pedidoEditando = this.pedido.id;
        this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
        this.$store.commit("pedidos/setPedidoDialog", true);
      } else {
        let modoObj = this.$store.state.datosTpv.modos.find(
          (m) => m.id == this.mesa.modo
        );
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "copyMesa",
          value: this.mesa,
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "modoId",
          value: this.mesa.modo,
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "modoCopy",
          value: !!modoObj ? modoObj : {},
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "uid",
          value: "0",
        });
        this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          key: "copyUser",
          value: {
            displayName: "Contado",
          },
        });
        if (this.mesa.preguntarComensales) {
          // this.$store.commit("pedidos/setPedidoEditandoObjValor", {
          //   key: "mesa",
          //   value: this.mesa.id,
          // });
          this.$store.commit("pedidos/setModoPantalla", "seleccionComensales");
          this.$store.commit("pedidos/setPedidoDialog", true);
        } else {
          console.log(this.mesa);

          this.crearPedido();
        }
      }
      // this.pedidoEditandoObj = this.pedido;
      // this.$router.push("/pedido/" + this.pedidoViendo.id);
    },
    crearPedido() {
      this.$store.dispatch("pedidos/crearPedidoEnMesa", {
        mesa: this.mesa,
        comensales: null,
      });
    },
  },
};
</script>

<style>
</style>