export default {
  productosArray: [],
  categorias: [],
  pidiendoDialog: false, //dialogo para agregar productos
  categoriaPidiendo: null,
  pasoPidiendoProducto: 0, //el paso pidiendo del producto
  pasoPidiendoProductoOpciones: 1, //el paso pidiendo del producto
  opcionesProductoDialogo: false, //modal de opciones pidiendo
  productoEditandoCarrito: null,
  productoPidiendoMenu: null,
  productoPidiendoMenuDialog: false,

  productosPidiendo: [],
  productoPidiendo: null,
  productoPidiendoOpciones: { //se crean tres arrays para mantener compatibilidad con Nuxt
    ids: [],
    nombres: [],
    nombresTpv: [],
    precios: [],
    incompatibilidades: []
  },
  catalogos: [
    {
      text: 'Online Domicilio',
      value: 'online-domicilio'
    },
    {
      text: 'Online Recoger',
      value: 'online-recoger'
    },
    {
      text: 'Tótem',
      value: 'totem'
    },
    {
      text: 'TPV Barra',
      value: 'tpv-barra'
    },
    {
      text: 'TPV Mostrador',
      value: 'tpv-mostrador'
    },
    {
      text: 'TPV Recoger',
      value: 'tpv-recoger'
    },
    {
      text: 'TPV Domicilio',
      value: 'tpv-domicilio'
    },
    {
      text: 'TPV Mesa',
      value: 'tpv-mesa'
    },
  ],
  productosCatalogos: [],
  productosTarifa: [],
  generarCatalogoSnackbar: false,
  generarCatalogoSegundos: 10,
  generarCatalogoSegundosInicial: 10,
  generarCatalogoTimeout: null,
  generarCatalogoTimeoutContador: null,
  resultadosBusqueda: []
}