<template>
  <div>
    <v-dialog
      :transition="$store.state.bajaCalidad ? 'none' : 'scale-transition'"
      v-model="clienteDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
      overlay-opacity=".9"
      v-if="clienteDialog"
      :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'"
    >
      <v-card
        flat
        :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
        color="blue-grey lighten-5"
        :min-height="pasoCliente == 'buscar' ? 524 : ''"
      >
        <v-card-title
          class="white pa-0"
          style="z-index: 1"
          :height="$vuetify.breakpoint.smAndDown ? '56' : '78'"
        >
          <!-- <v-spacer /> -->
          <v-list-item style="max-width: 100vw" class="">
            <v-list-item-content class="">
              <v-list-item-title>{{ titulo }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                 fab
                :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                color="primary"
                @click="clienteDialog = false"
                top
                right
              >
                <v-icon
                  :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                  color="black"
                  >mdi-close</v-icon
                >
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-card-title>
        <v-container v-if="pasoCliente == 'buscar'" fluid>
          <v-row>
            <v-col cols="12" md="8" lg="9">
              <v-text-field
                v-model="filtroTelefono"
                :readonly="$vuetify.breakpoint.mdAndUp"
                solo
                hide-details
                :height="
                  $vuetify.breakpoint.smAndDown
                    ? '60'
                    : $vuetify.breakpoint.mdAndDown
                    ? '70'
                    : '78'
                "
                elevation="2"
                class="rounded-lg mt-1 font-weight-light"
                :class="$vuetify.breakpoint.smAndDown ? 'title' : 'display-1'"
                type="tel"
                ref="inputBusqueda"
                autofocus
              >
              </v-text-field>

              <clientes-component :hideTextField="true" />
            </v-col>
            <v-col cols="12" md="4" lg="3" v-if="$vuetify.breakpoint.mdAndUp">
              <v-card elevation="0" class="transparent">
                <v-row no-gutters class="mb-2">
                  <v-col cols="12">
                    <KeyboardNumMini
                      ref="keyboardRefMini"
                      @onChange="onChange"
                      @onKeyPress="onKeyPress"
                      :input="filtroTelefono"
                      :maxLength="9"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!-- formDatos viendo los datos del cliente a título informativo-->

        <template class="border" v-else-if="pasoCliente == 'formDatos'">
          <v-card-text
            class="blue-grey lighten-5 border pt-4"
            :class="
              pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
                ? 'rounded-0'
                : 'rounded-b-lg'
            "
          >
            <div v-if="pedidoEditandoObj">
              <v-alert
                v-if="!!pedidoEditandoObj.facturaCliente"
                type="warning"
                dense
                colored-border
                border="left"
                class="mb-2 rounded-lg"
                icon="mdi-alert"
                >Pedido con factura generada. No se puede cambiar el
                cliente</v-alert
              >
              <v-row>
                <v-col cols="12" md="6">
                  <template
                    v-if="
                      pedidoEditandoObj.copyUser ||
                      (editarCliente &&
                        editarCliente.localNombre &&
                        editarCliente.localTelefono)
                    "
                  >
                    <div
                      v-if="
                        (pedidoEditandoObj.copyUser &&
                          pedidoEditandoObj.copyUser.displayName) ||
                        editarCliente.localNombre
                      "
                      class="font-weight-bold mb-2"
                      :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'title'"
                    >
                      {{
                        editarCliente.localNombre
                          ? editarCliente.localNombre
                          : pedidoEditandoObj.copyUser.displayName
                      }}
                    </div>
                    <div
                      v-if="
                        (pedidoEditandoObj.copyUser &&
                          pedidoEditandoObj.copyUser.phoneNumber) ||
                        editarCliente.localTelefono
                      "
                      class="d-flex flex-row align-start"
                      :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'body-1'"
                    >
                      <v-icon
                        :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                        class="mr-4"
                      >
                        mdi-phone
                      </v-icon>
                      {{
                        editarCliente.localTelefono
                          ? editarCliente.localTelefono
                          : pedidoEditandoObj.copyUser.phoneNumber
                      }}
                    </div>
                  </template>

                  <div
                    v-if="pedidoEditandoObj.direccion"
                    class="d-flex flex-row align-start"
                    :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'body-1'"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                      class="mr-4"
                    >
                      mdi-map-marker
                    </v-icon>
                    <div>
                      {{ pedidoEditandoObj.direccion.calle
                      }}<span v-if="pedidoEditandoObj.direccion.numero"
                        >, {{ pedidoEditandoObj.direccion.numero }}</span
                      ><span v-if="pedidoEditandoObj.direccion.cp"
                        >.
                        {{ pedidoEditandoObj.direccion.cp }}
                      </span>
                      <span v-if="pedidoEditandoObj.direccion.municipio">
                        - {{ pedidoEditandoObj.direccion.municipio }}
                      </span>
                      <span v-if="pedidoEditandoObj.direccion.provincia">
                        ({{ pedidoEditandoObj.direccion.provincia }})
                      </span>
                      <div
                        v-if="pedidoEditandoObj.direccion.adicional"
                        class="mt-1"
                      >
                        {{ pedidoEditandoObj.direccion.adicional }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      pedidoEditandoObj.repartoTexto &&
                      pedidoEditandoObj.repartoTexto != 'Sin más info'
                    "
                    class="d-flex flex-row align-start font-weight-regular"
                    :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'body-2'"
                    style="line-height: 1.2"
                  >
                    <v-icon
                      :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                      class="mr-4"
                    >
                      mdi-note-text
                    </v-icon>
                    {{ pedidoEditandoObj.repartoTexto }}
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div
                    v-if="
                      !!pedidoEditandoObj.direccion &&
                      Array.isArray(pedidoEditandoObj.direccion.coordenadas) &&
                      pedidoEditandoObj.direccion.coordenadas.length > 0
                    "
                  >
                    <GmapMap
                      :center="{
                        lat: pedidoEditandoObj.direccion.coordenadas[0],
                        lng: pedidoEditandoObj.direccion.coordenadas[1],
                      }"
                      :options="{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                        disableDefaultUI: false,
                      }"
                      :zoom="16"
                      map-type-id="terrain"
                      style="width: 100%; height: 400px"
                    >
                      <GmapMarker
                        :position="{
                          lat: pedidoEditandoObj.direccion.coordenadas[0],
                          lng: pedidoEditandoObj.direccion.coordenadas[1],
                        }"
                      />
                    </GmapMap>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions
            class="py-4 blue-grey lighten-5"
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
          >
            <v-row
              v-if="
                pedidoEditandoObj.fechaMostrar ||
                pedidoEditandoObj.copyUsuarioTpv
              "
              :style="
                $vuetify.breakpoint.smAndDown ? 'width: 100%;' : 'width: 20%;'
              "
              style="line-height: 1"
            >
              <v-col
                cols="auto"
                md="12"
                v-if="
                  pedidoEditandoObj.copyUsuarioTpv &&
                  pedidoEditandoObj.copyUsuarioTpv.nombre
                "
                class="pa-1"
              >
                <v-icon
                  size="18"
                  :class="$vuetify.breakpoint.smAndUp ? 'mt-n1' : ''"
                  left
                >
                  mdi-account-box
                </v-icon>
                <!-- <span v-if="$vuetify.breakpoint.smAndUp">
                  Atendido por
                </span> -->
                <span>
                  {{ pedidoEditandoObj.copyUsuarioTpv.nombre }}
                </span>
              </v-col>
              <v-spacer></v-spacer>

              <v-col
                cols="auto"
                md="12"
                v-if="pedidoEditandoObj.fechaMostrar"
                class="pa-1"
              >
                <v-icon
                  size="18"
                  :class="$vuetify.breakpoint.smAndUp ? 'mt-n1' : ''"
                  left
                >
                  mdi-clock-outline
                </v-icon>
                <!-- <span v-if="$vuetify.breakpoint.smAndUp">
                  Registrado a las
                </span> -->
                <span>
                  {{ pedidoEditandoObj.fechaMostrar }}
                </span>
              </v-col>
            </v-row>
            <v-row
              :style="
                $vuetify.breakpoint.smAndDown ? 'width: 100%;' : 'width: 80%;'
              "
              justify="end"
            >
              <v-col cols="12" sm="4" class="pa-1" v-if="editarCliente.id">
                <v-btn
                  @click="
                    $store.commit('clientes/setHistorialPedidosDialog', true)
                  "
                  large
                  color="primary"
                  class="black--text rounded-lg mr-4"
                  :disabled="!!pedidoEditandoObj.facturaCliente"
                >
                  Historial Pedidos</v-btn
                >
              </v-col>
              <v-col cols="12" sm="4" class="pa-1">
                <v-btn
                  @click="editarClienteMetodo"
                  large
                  block
                  color="primary"
                  class="black--text rounded-lg"
                  :disabled="esContado || !!pedidoEditandoObj.facturaCliente"
                >
                  EDITAR
                  <!-- DATOS DEL -->
                  CLIENTE
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="pa-1"
                v-if="pedidoEditandoObj.direccion"
              >
                <v-btn
                  @click="cambiarDireccion"
                  large
                  block
                  color="primary"
                  class="black--text rounded-lg"
                  :disabled="!!pedidoEditandoObj.facturaCliente"
                >
                  CAMBIAR DIRECCIÓN</v-btn
                >
              </v-col>
              <v-col cols="12" sm="4" class="pa-1">
                <v-btn
                  @click="cambiarCliente"
                  large
                  block
                  color="primary"
                  class="black--text rounded-lg"
                  :disabled="!!pedidoEditandoObj.facturaCliente"
                >
                  {{ esContado ? "ASIGNAR " : "CAMBIAR " }} CLIENTE</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </template>
        <template v-else-if="pasoCliente == 'editarCliente'">
          <v-card-text
            class="pt-4 blue-grey lighten-5"
            :class="
              pedidoEditandoObj.fechaMostrar || pedidoEditandoObj.copyUsuarioTpv
                ? 'rounded-0'
                : 'rounded-b-lg'
            "
          >
            <template v-if="pedidoEditandoObj">
              <!-- <v-alert
                  outlined
                  rounded=""
                  type="warning"
                  dense
                  colored-border
                  border="left"
                  class="mb-2 rounded-lg"
                  icon="mdi-alert"
                  >Al cambiar los datos del cliente se actualizarán en el
                  pedido</v-alert
                > -->
              <v-alert
                v-if="!!pedidoEditandoObj.facturaCliente"
                type="warning"
                dense
                colored-border
                border="left"
                class="mb-2 rounded-lg"
                icon="mdi-alert"
                >Pedido con factura generada. No se puede cambiar el
                cliente</v-alert
              >
              <template
                v-if="
                  pedidoEditandoObj.copyUser ||
                  (editarCliente.localNombre && editarCliente.localTelefono)
                "
              >
                <div
                  v-if="
                    (pedidoEditandoObj.copyUser &&
                      pedidoEditandoObj.copyUser.displayName) ||
                    editarCliente.localNombre
                  "
                  class="font-weight-bold mb-2"
                  :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'title'"
                >
                  {{
                    editarCliente.localNombre
                      ? editarCliente.localNombre
                      : pedidoEditandoObj.copyUser.displayName
                  }}
                </div>
                <div
                  v-if="
                    (pedidoEditandoObj.copyUser &&
                      pedidoEditandoObj.copyUser.phoneNumber) ||
                    editarCliente.localTelefono
                  "
                  class="d-flex flex-row align-start"
                  :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'body-1'"
                >
                  <v-icon
                    :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                    class="mr-4"
                  >
                    mdi-phone
                  </v-icon>
                  {{
                    editarCliente.localTelefono
                      ? editarCliente.localTelefono
                      : pedidoEditandoObj.copyUser.phoneNumber
                  }}
                </div>
              </template>
              <div
                v-if="pedidoEditandoObj.direccion"
                class="d-flex flex-row align-start"
                :class="$vuetify.breakpoint.mdAndUp ? 'title' : 'body-1'"
              >
                <v-icon
                  :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                  class="mr-4"
                >
                  mdi-map-marker
                </v-icon>
                <div>
                  {{ pedidoEditandoObj.direccion.calle
                  }}<span v-if="pedidoEditandoObj.direccion.numero"
                    >, {{ pedidoEditandoObj.direccion.numero }}</span
                  ><span v-if="pedidoEditandoObj.direccion.cp"
                    >.
                    {{ pedidoEditandoObj.direccion.cp }}
                  </span>
                  <span v-if="pedidoEditandoObj.direccion.municipio">
                    - {{ pedidoEditandoObj.direccion.municipio }}
                  </span>
                  <span v-if="pedidoEditandoObj.direccion.provincia">
                    ({{ pedidoEditandoObj.direccion.provincia }})
                  </span>
                  <div
                    v-if="pedidoEditandoObj.direccion.adicional"
                    class="mt-1"
                  >
                    {{ pedidoEditandoObj.direccion.adicional }}
                  </div>
                </div>
              </div>
              <!--  -->
              <div
                v-if="
                  pedidoEditandoObj.repartoTexto &&
                  pedidoEditandoObj.repartoTexto != 'Sin más info'
                "
                class="d-flex flex-row align-start font-weight-regular"
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'body-2'"
                style="line-height: 1.2"
              >
                <v-icon
                  :size="$vuetify.breakpoint.mdAndUp ? 32 : 24"
                  class="mr-4"
                >
                  mdi-note-text
                </v-icon>
                {{ pedidoEditandoObj.repartoTexto }}
              </div>
            </template>
            <v-row dense class="mt-3">
              <template v-if="!!editarCliente.id">
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Canal de registro"
                    disabled
                    v-model="editarCliente.canalRegistro"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Nombre en la web"
                    disabled
                    v-model="editarCliente.displayName"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    disabled
                    label="Teléfono web verificado"
                    v-model="editarCliente.phoneNumber"
                    messages="Teléfono usado para el login"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Email"
                    disabled
                    v-model="editarCliente.email"
                  />
                </v-col>
              </template>
              <v-col cols="12" md="8">
                <v-text-field
                  label="Nombre"
                  v-model="editarCliente.localNombre"
                  ref="localNombre"
                  @click="focusCampo(editarCliente, 'localNombre')"
                />
              </v-col>
              <!-- <v-col cols="12" md="6">
              <v-text-field
                label="1º Apellido"
                name="localApellido1"
                v-model="editarCliente.localApellido1"
                @click="focusCampo(editarCliente, 'localApellido1')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="2º Apellido"
                v-model="editarCliente.localApellido2"
                @click="focusCampo(editarCliente, 'localApellido2')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="NIF"
                v-model="editarCliente.localNif"
                @click="focusCampo(editarCliente, 'localNif')"
              />
            </v-col> -->
              <v-col cols="12" md="4">
                <v-text-field
                  label="Teléfono"
                  v-model="editarCliente.localTelefono"
                  ref="localTelefono"
                  @click="focusCampo(editarCliente, 'localTelefono')"
                />
              </v-col>
            </v-row>
            <template v-if="addClientePedido || editarCliente.id">
              <v-card-title class="px-0">Datos de facturación</v-card-title>
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Razón Social / Nombre Completo"
                    v-model="editarCliente.facturacionRazonSocial"
                    ref="facturacionRazonSocial"
                    @click="focusCampo(editarCliente, 'facturacionRazonSocial')"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="CIF / NIF"
                    v-model="editarCliente.facturacionNifCif"
                    ref="facturacionNifCif"
                    @click="focusCampo(editarCliente, 'facturacionNifCif')"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Direccion"
                    v-model="editarCliente.facturacionDireccion"
                    ref="facturacionDireccion"
                    @click="focusCampo(editarCliente, 'facturacionDireccion')"
                  />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Código Postal"
                    v-model="editarCliente.facturacionCp"
                    ref="facturacionCp"
                    @click="focusCampo(editarCliente, 'facturacionCp')"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Municipio"
                    v-model="editarCliente.facturacionMunicipio"
                    ref="facturacionMunicipio"
                    @click="focusCampo(editarCliente, 'facturacionMunicipio')"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Provincia"
                    v-model="editarCliente.facturacionProvincia"
                    ref="facturacionProvincia"
                    @click="focusCampo(editarCliente, 'facturacionProvincia')"
                  />
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-4 blue-grey lighten-5 rounded-b-lg">
            <v-btn
              color="secondary"
              large
              class="rounded-lg black--text"
              @click="pasoCliente = 'formDatos'"
              >Volver</v-btn
            >
            <v-spacer />
            <v-btn
              color="primary"
              large
              class="rounded-lg black--text"
              @click="guardarDatosCliente"
              :loading="guardarBotonLoading"
              >Guardar</v-btn
            >
          </v-card-actions>
        </template>

        <template v-else-if="pasoCliente == 'facturar'">
          <v-card-text class="blue-grey lighten-5" v-if="editarCliente">
            <v-row class="mt-3">
              <!-- <v-col cols="12">
                <v-checkbox
                  label="Actualizar datos del cliente y pedido"
                  v-model="actualizarDatosCliente"
                />
              </v-col> -->
              <!--                 <v-col cols="12">
                <v-alert
                  v-if="actualizarDatosCliente"
                  type="warning"
                  dense
                  colored-border
                  border="left"
                  class="mb-0 rounded-lg"
                  icon="mdi-alert"
                  >Al cambiar los datos de facturación se guardarán en el
                  cliente</v-alert
                ></v-col
              > -->
              <v-col cols="12" md="8">
                <v-text-field
                  label="Razón Social / Nombre Completo"
                  v-model="editarCliente.facturacionRazonSocial"
                  ref="facturacionRazonSocial"
                  @click="focusCampo(editarCliente, 'facturacionRazonSocial')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="CIF / NIF"
                  v-model="editarCliente.facturacionNifCif"
                  ref="facturacionNifCif"
                  @click="focusCampo(editarCliente, 'facturacionNifCif')"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  label="Direccion"
                  v-model="editarCliente.facturacionDireccion"
                  ref="facturacionDireccion"
                  @click="focusCampo(editarCliente, 'facturacionDireccion')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Código Postal"
                  v-model="editarCliente.facturacionCp"
                  ref="facturacionCp"
                  @click="focusCampo(editarCliente, 'facturacionCp')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Municipio"
                  v-model="editarCliente.facturacionMunicipio"
                  ref="facturacionMunicipio"
                  @click="focusCampo(editarCliente, 'facturacionMunicipio')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Provincia"
                  v-model="editarCliente.facturacionProvincia"
                  ref="facturacionProvincia"
                  @click="focusCampo(editarCliente, 'facturacionProvincia')"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions
            class="blue-grey lighten-5 py-4"
            :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'justify-end'"
          >
            <v-btn
              @click="cambiarCliente"
              large
              :block="$vuetify.breakpoint.xsOnly"
              color="primary"
              class="black--text rounded-lg ml-sm-4"
              :disabled="!!pedidoEditandoObj.facturaCliente"
            >
              {{ esContado ? "ASIGNAR " : "CAMBIAR " }} CLIENTE</v-btn
            >
            <v-btn
              :loading="generarFacturaLoading"
              @click="generarFactura"
              :disabled="!camposFacturaValido"
              color="primary"
              class="black--text rounded-lg ml-sm-4 ml-0 mt-2 mt-sm-0"
              large
              :block="$vuetify.breakpoint.xsOnly"
              >GENERAR FACTURA</v-btn
            >
          </v-card-actions>
        </template>
        <v-divider
          v-if="
            modoPantalla == 'seleccionCliente' &&
            (seleccionClientePedidoEnCurso || modo == 'recoger')
          "
        ></v-divider>
        <v-card-actions
          class="rounded-b-lg pt-4 blue-grey lighten-5 py-4 justify-end"
          v-if="
            !cambiandoCliente &&
            modoPantalla == 'seleccionCliente' &&
            (seleccionClientePedidoEnCurso || modo == 'recoger')
          "
        >
          <v-btn
            @click="continuarSinCliente"
            class="rounded-lg"
            color="primary black--text"
            large
          >
            Continuar sin cliente
          </v-btn>
          <!-- <v-btn
            v-if="cambiandoCliente"
            @click="volverPedido"
            class="rounded-lg ml-4"
            color="primary black--text"
            large
          >
            Volver al pedido
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIRECCIONES DIÁLOGO DE SELECCION-->
    <v-dialog
      overlay-opacity=".9"
      v-model="direccionesDialog"
      v-if="editarCliente"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
    >
      <v-card :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'">
        <v-card-title class="white py-3 pr-3" height="78">
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'body-1' : 'body-1'"
            class="font-weight-black"
          >
            Dirección entrega
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="direccionesDialog = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-0 px-0 blue-grey lighten-5">
          <v-list color="transparent">
            <template v-for="(dir, i) in direccionesComputed">
              <v-list-item
                two-line
                :value="dir.id"
                :key="dir.id"
                @click="elegirDireccion(dir)"
              >
                <v-list-item-icon
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-2'"
                >
                  <v-icon :x-large="$vuetify.breakpoint.mdAndUp">
                    mdi-map-marker
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="
                      $vuetify.breakpoint.mdAndUp
                        ? 'text-h5 font-weight-light'
                        : ''
                    "
                    >{{ dir.calle }} {{ dir.numero }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    {{ dir.adicional }} {{ dir.adicional ? "," : "" }}
                    {{ dir.municipio }} {{ dir.cp }}
                    {{ dir.provincia }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                  :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-2'"
                >
                  <v-btn
                    class="black--text"
                    outlined
                    fab
                    @click.stop="editarDireccion(dir)"
                    color="grey darken-2"
                  >
                    <v-icon :x-large="$vuetify.breakpoint.mdAndUp">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                :key="i"
                v-if="i < direccionesComputed.length - 1"
                class="mx-4"
              />
            </template>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          class="rounded-b-lg py-4 blue-grey lighten-5 justify-end"
        >
          <v-btn
            :disabled="direccionSeleccionada"
            large
            :block="$vuetify.breakpoint.smAndDown"
            color="primary"
            class="black--text"
            @click="nuevaDireccionDialog()"
            >Nueva Direccion</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIRECCIONES DIÁLOGO DE EDICION/AÑADIR-->
    <v-dialog
      v-model="direccionesFormDialog"
      max-width="800"
      overlay-opacity=".9"
      content-class="mt-n4 elevation-0"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
    >
      <v-card
        :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
        ref="direccionesFormDialog"
      >
        <v-card-title class="white py-3 pr-3" height="78">
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
            class="font-weight-black"
          >
            Formulario dirección {{ nombreClienteMostrar }}
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="direccionesFormDialog = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 blue-grey lighten-5">
          <v-alert
            color="warning"
            class="white--text"
            icon="mdi-alert"
            v-if="!direccionesForm.direccionLocal"
          >
            Esta dirección la añadió el usuario desde el portal web, y no se
            puede modificar
          </v-alert>
          <v-row dense>
            <v-col cols="12" v-if="!!direccionesForm.direccionLocal">
              <v-combobox
                :menu-props="{ auto: false, maxHeight: 270 }"
                clearable
                hide-details
                outlined
                persistent-hint
                solo
                auto-select-first
                ref="inputLocalizacion"
                autofocus
                v-model="textoInput"
                :items="locationEntries"
                :search-input.sync="locationSearchText"
                :loading="loadingInput"
                :disabled="loadingInput"
                no-filter
                item-text="value"
                item-value="id"
                item-color="primary darken-2"
                hide-no-data
                return-object
                error-messages
                label="Búsqueda calle y número"
                append-icon="mdi-magnify"
                @click:append="localizarTextoInput()"
                @input="localizarTextoInput()"
                @keydown.enter="localizarTextoInput()"
                max-width="100%"
                class="combo--no-gira"
              >
                <template v-slot:item="{ index, item, on }">
                  <div
                    class="d-flex align-center"
                    style="width: 100%"
                    v-if="item.encontrado === true"
                  >
                    {{ item.value }}
                    <v-spacer></v-spacer>
                    <!-- <v-chip class="primary mx-1 black--text" small
                      >Recargo:
                      {{ mostrarMoneda(item.datosReparto.recargo) }}</v-chip
                    > -->
                    <v-chip class="primary ml-1 black--text font-weight-bold"
                      >+{{ mostrarMoneda(item.datosReparto.recargo) }}€</v-chip
                    >
                    <v-chip
                      class="orange lighten-3 ml-1 black--text font-weight-bold"
                      ><!-- Pedido mínimo: -->
                      Desde:
                      {{ mostrarMoneda(item.datosReparto.minimo) }}€</v-chip
                    >
                  </div>
                  <v-list-item
                    class="grey--text grey lighten-3"
                    v-else-if="item.encontrado === false"
                  >
                    {{ item.value }}
                    <!-- <v-chip class="warning lighten-1 mx-1 black--text" small
                      >Fuera de reparto</v-chip
                    > -->
                  </v-list-item>
                  <v-list-item v-else>
                    {{ item.value }}
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="Calle"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.calle"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Número"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.numero"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                label="Información adicional"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.adicional"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Código Postal"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.cp"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Municipio"
                :disabled="!direccionesForm.direccionLocal"
                v-model="direccionesForm.municipio"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Provincia"
                v-model="direccionesForm.provincia"
                :disabled="!direccionesForm.direccionLocal"
                @input="direccionesFormCambio = true"
              />
            </v-col>
            <v-col
              cols="12"
              v-if="
                Array.isArray(direccionesForm.coordenadas) &&
                direccionesForm.coordenadas.length > 0
              "
            >
              <GmapMap
                :center="{
                  lat: direccionesForm.coordenadas[0],
                  lng: direccionesForm.coordenadas[1],
                }"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUI: false,
                }"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 210px"
              >
                <GmapMarker
                  :position="{
                    lat: direccionesForm.coordenadas[0],
                    lng: direccionesForm.coordenadas[1],
                  }"
                />
              </GmapMap>
            </v-col>
            <!--             <v-col cols="12" md="6">
              <v-text-field
                label="Pais"
                v-model="direccionesForm.pais"
                :disabled="!direccionesForm.direccionLocal"
                @input="direccionesFormCambio = true"
              />
            </v-col> -->
          </v-row>
        </v-card-text>
        <template v-if="direccionesForm.direccionLocal">
          <v-divider></v-divider>
          <v-card-actions class="py-4 blue-grey lighten-5 rounded-b-lg">
            <v-spacer />
            <v-btn
              :disabled="!direccionesFormCambio"
              color="primary"
              class="rounded-lg black--text"
              @click="guardarDireccion"
              :loading="guardarBotonLoading"
              >Guardar</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <cliente-historial-pedidos />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { key } from "firebase-key";
import {
  getSugerencias,
  coordenadasDireccion,
  pointInPolygon,
  formatearDireccionGoogle,
} from "@/utils/googlemaps.js";

import { mostrarIdioma, mostrarMoneda } from "@/utils/helper.utils.js";
import { gmapApi } from "vue2-google-maps";
import KeyboardNumMini from "./KeyboardNumMini";

import ClientesComponent from "./ClientesComponent.vue";
import ClienteHistorialPedidos from "./ClienteHistorialPedidos.vue";
export default {
  components: { ClientesComponent, KeyboardNumMini, ClienteHistorialPedidos },
  watch: {
    locationSearchText(newVal) {
      var _vue = this;
      var typingTimer;
      var buscando = false;
      var intervaloBuscar = 500;
      // If less than 3 chars typed, do not search
      if (!newVal || newVal.length <= 4) {
        this.locationEntries = [];
        return;
      }

      if (!buscando && !this.seleccionado) {
        //clearTimeout(typingTimer);
        //typingTimer = setTimeout(() => {
        // Call the method from the previous section here
        buscando = true;
        getSugerencias(newVal)
          .then((res) => {
            if (res != "ZERO_RESULTS") {
              _vue.locationEntries = res;
              if (!!_vue.timeoutBusqueda) clearTimeout(_vue.timeoutBusqueda);
              _vue.timeoutBusqueda = setTimeout(() => {
                coordenadasDireccion(res).then((r) => {
                  console.log(r);
                  _vue.locationEntries = r.map((l) => {
                    let restaurantsPosibles = [];
                    let activado = false;
                    let minimo = 0;
                    let recargo = 0;
                    let productoRecargo;
                    let inPolygon = false;
                    this.$store.state.restauranteObj.zonasReparto.forEach(
                      (e1) => {
                        activado = e1.siDomicilio;
                        minimo = e1.domicilioMinimo;
                        recargo = e1.domicilioRecargo;
                        productoRecargo = e1.productoRecargo;
                        console.log(e1);
                        if (activado) {
                          inPolygon = pointInPolygon(
                            l.coordenadas,
                            e1.poligono
                          );
                          if (inPolygon) {
                            let alreadyIn = restaurantsPosibles.findIndex(
                              (e) =>
                                e.idRest == this.$store.state.restauranteObj.id
                            );
                            if (alreadyIn > -1) {
                              if (
                                parseFloat(minimo) <
                                restaurantsPosibles[alreadyIn].minimo
                              ) {
                                restaurantsPosibles[alreadyIn].minimo =
                                  parseFloat(minimo);
                                restaurantsPosibles[alreadyIn].recargo =
                                  parseFloat(recargo);
                              }
                            } else {
                              let add = {
                                idRest: this.$store.state.restauranteObj.id,
                                minimo: parseFloat(minimo),
                                recargo: parseFloat(recargo),
                                productoRecargo: productoRecargo,
                              };

                              restaurantsPosibles.push(add);
                            }
                          }
                        }
                      }
                    );
                    console.log(restaurantsPosibles);
                    return {
                      ...l,
                      encontrado: !!restaurantsPosibles[0],
                      ...(!!restaurantsPosibles[0]
                        ? {
                            ...restaurantsPosibles[0],
                          }
                        : {}),
                      value: l.value,
                      datosReparto: !!restaurantsPosibles[0]
                        ? {
                            recargo: parseFloat(restaurantsPosibles[0].recargo),
                            minimo: parseFloat(restaurantsPosibles[0].minimo),
                          }
                        : null,
                    };
                  });
                });
              }, 2000);
            } else {
              if (_vue.modo == "domicilio") {
                _vue.locationEntries = [
                  { value: "No se localiza. Escribir a mano." },
                ];
              } else {
              }
            }
          })
          .catch(function (err) {
            console.log(err);
            // error handling goes here
          })
          .finally(function () {
            buscando = false;
          });
        //}, intervaloBuscar);
      }
    },
    direccionesFormDialog(v) {
      if (!v) {
        this.textoInput = null;
        this.locationSearchText = "";
        this.direccionesForm = {
          calle: "",
          numero: "",
          adicional: "",
          municipio: "",
          cp: "",
          provincia: "",
          coordenadas: [],
          pais: "",
        };
        this.direccionesFormCambio = false;
      } else {
        console.log("está en agregar direcciones");
        if (!!this.$refs.inputLocalizacion) {
          setTimeout(() => {
            this.$refs.inputLocalizacion.focus();
          }, 1000);
        }
      }
    },
    clienteDialog(v) {
      if (!v) {
        this.pasoCliente = "buscar";
        this.filtroTelefono = "";
        this.$store.commit("clientes/setClientesFiltrados", []);
        console.log(this.estaComenzandoPedido);
        if (this.estaComenzandoPedido) {
          // this.pasoCliente = "buscar";
          this.pedidoEditando = null;
          this.pedidoEditandoObj = {};
        }
      } else if (this.pedidoEditandoObj && this.pedidoEditandoObj.id) {
        if (this.pedidoEditandoObj.uid != "0") {
          this.$store
            .dispatch("clientes/getCliente", this.pedidoEditandoObj.uid)
            .then((r) => {
              console.log(r);
              this.editarCliente = r;
            });
        }
      }

      if (v && !this.$vuetify.breakpoint.mdAndUp && this.$refs.inputBusqueda) {
        setTimeout(() => {
          this.$refs.inputBusqueda.focus();
        }, 1000);
      }
    },
    pasoCliente(v) {
      if (v == "facturar") {
        this.editarCliente = {
          //inicializamos valores si no los tiene
          facturacionRazonSocial: "",
          facturacionNifCif: "",
          facturacionCp: "",
          facturacionDireccion: "",
          facturacionProvincia: "",
          facturacionMunicipio: "",
          ...(this.pedidoEditandoObj && this.pedidoEditandoObj.copyUser
            ? this.pedidoEditandoObj.copyUser
            : {}),
        };
      }
    },
    direccionesDialog(v) {
      if (
        v &&
        Array.isArray(this.direccionesComputed) &&
        this.direccionesComputed.length === 0
      ) {
        this.direccionesDialog = false;
        this.nuevaDireccionDialog();
      }
    },
  },
  data: () => {
    return {
      direccionesFormDialog: false,
      direccionesFormCambio: false,

      direccionesForm: {
        direccionLocal: false,
        id: null,
        calle: "",
        numero: "",
        adicional: "",
        municipio: "",
        cp: "",
        provincia: "",
        coordenadas: [],
        pais: "",
      },
      direccionSeleccionada: null,

      guardarBotonLoading: false,
      actualizarDatosCliente: true,
      generarFacturaLoading: false,
      textoInput: "",
      loadingInput: false,
      locationSearchText: "",
      locationEntries: [],
    };
  },
  computed: {
    addClientePedido() {
      return !(
        this.modoPantalla == "seleccionCliente" || !!this.pedidoEditandoObj
      );
    },
    modoObj() {
      let f = this.$store.state.datosTpv.modos.find((m) => m.id == this.modoid);
      return f ? f : "";
    },
    estaPidiendoAgregandoClienteSinDirecciones() {
      return (
        this.editarCliente &&
        (!this.editarCliente.direccionesLocal ||
          (this.editarCliente.direccionesLocal &&
            this.editarCliente.direccionesLocal.length == 0)) &&
        (!this.editarCliente.direcciones ||
          (this.editarCliente.direcciones &&
            this.editarCliente.direcciones.length == 0))
      );
    },
    nombreClienteMostrar() {
      if (this.editarCliente) {
        return this.editarCliente.nombreLocal
          ? this.editarCliente.nombreLocal
          : this.editarCliente.displayName
          ? this.editarCliente.displayName
          : "Sin nombre";
      } else {
        return "";
      }
    },
    direccionesComputed() {
      return [
        ...(this.editarCliente.direcciones
          ? this.editarCliente.direcciones
          : []),
        ...(this.editarCliente.direccionesLocal
          ? this.editarCliente.direccionesLocal
          : []),
      ];
    },
    camposFacturaValido() {
      return (
        this.editarCliente &&
        this.editarCliente.facturacionRazonSocial &&
        this.editarCliente.facturacionRazonSocial.length > 0 &&
        this.editarCliente.facturacionNifCif.length > 0
      );
    },
    editarCliente: {
      get() {
        return this.$store.state.clientes.editarCliente;
      },
      set(v) {
        this.$store.commit("clientes/setEditarCliente", v);
      },
    },
    cambiandoCliente: {
      get() {
        return this.$store.state.pedidos.cambiandoCliente;
      },
      set(v) {
        this.$store.commit("pedidos/setCambiandoCliente", v);
      },
    },
    seleccionClientePedidoEnCurso() {
      return !!this.cambiandoCliente;
    },
    pasoCliente: {
      //buscar, formDatos, formFactura, direcciones
      get() {
        return this.$store.state.clientes.pasoCliente;
      },
      set(v) {
        this.$store.commit("clientes/setPasoCliente", v);
      },
    },
    pedidoEditando: {
      get() {
        return this.$store.state.pedidos.pedidoEditando;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditando", v);
      },
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      },
    },
    estaComenzandoPedido() {
      return this.modoPantalla == "seleccionCliente";
    },
    modoPantalla: {
      //seleccionCliente si está en pedido
      get() {
        return this.$store.state.pedidos.modoPantalla;
      },
      set(v) {
        this.$store.commit("pedidos/setModoPantalla", v);
      },
    },
    filtroTelefono: {
      get() {
        return this.$store.state.clientes.filtro.ftelefono;
      },
      set(v) {
        console.log(v);
        this.$store.commit("clientes/setFiltroKey", {
          key: "ftelefono",
          value: v,
        });
      },
    },
    hayPedido() {
      return this.pedidoEditandoObj && this.pedidoEditandoObj.id;
    },
    ...mapState({
      // pedidoEditandoObj: (state) => state.pedidos.pedidoEditandoObj,
      facturacionContableFacturar: (state) =>
        state.pedidos.facturacionContableFacturar,
      clienteDialogFullscreen: (state) =>
        state.clientes.clienteDialogFullscreen,
    }),
    direccionesDialog: {
      get() {
        return this.$store.state.clientes.direccionesDialog;
      },
      set(v) {
        this.$store.commit("clientes/setDireccionesDialog", v);
      },
    },
    esContado() {
      return this.pedidoEditandoObj && this.pedidoEditandoObj.uid == "0";
    },
    titulo() {
      if (this.estaComenzandoPedido) {
        return (
          "SELECCIONE CLIENTE  " +
          (this.pedidoEditandoObj.modoCopy
            ? " - " + this.pedidoEditandoObj.modoCopy.nombre.toUpperCase()
            : "")
        );
      } else if (this.pasoCliente == "formDatos") {
        if (!!this.pedidoEditandoObj)
          return (
            "INFORMACIÓN CLIENTE - " +
            this.$store.getters["pedidos/tituloPedido"].toUpperCase()
          );
      } else if (this.pasoCliente == "buscar") {
        return (
          "Seleccione cliente" +
          (!!this.hayPedido
            ? " - " + this.$store.getters["pedidos/tituloPedido"].toUpperCase()
            : "")
        );
      } else if (this.pasoCliente == "editarCliente") {
        return (
          "EDITANDO CLIENTE" +
          (!!this.hayPedido
            ? " - " + this.$store.getters["pedidos/tituloPedido"].toUpperCase()
            : "")
        );
      } else if (this.pasoCliente == "facturar") {
        return (
          "FACTURACIÓN" +
          (!!this.hayPedido
            ? " - " +
              this.$store.getters["pedidos/tituloPedido"].toUpperCase() +
              " - CANTIDAD: " +
              mostrarMoneda(this.facturacionContableFacturar.pagado) +
              " €"
            : "")
        );
      }
    },
    clienteDialog: {
      get() {
        return this.$store.state.clientes.clienteDialog;
      },
      set(v) {
        this.$store.commit("clientes/setClienteDialog", v);
      },
    },
    modo() {
      return this.$route.params.modotipo;
    },
    modoid() {
      return this.$route.params.modoid;
    },
  },
  methods: {
    mostrarMoneda(s) {
      return mostrarMoneda(s);
    },
    localizarTextoInput() {
      if (
        this.textoInput &&
        this.textoInput.valueBruto &&
        this.textoInput.valueBruto.address_components
      ) {
        let dirFormato = formatearDireccionGoogle(
          this.textoInput.valueBruto.address_components
        );

        this.direccionesForm = {
          ...this.direccionesForm,
          ...dirFormato,
          coordenadas: this.textoInput.coordenadas,
        };
        this.direccionesFormCambio = true;
      }
    },

    editarDireccion(dir) {
      this.direccionesFormDialog = true;
      this.direccionesForm = JSON.parse(JSON.stringify(dir));
    },
    filtrar() {
      this.loading = true;
      this.$store
        .dispatch("clientes/filtrarClientes")
        .finally(() => (this.loading = false));
    },

    guardarDireccion() {
      if (!!this.direccionesForm.id) {
        //edita
        var direccionesLocalAux = this.editarCliente.direccionesLocal;
        let fI = direccionesLocalAux.findIndex(
          (d) => d.id == this.direccionesForm.id
        );
        if (fI > -1) {
          direccionesLocalAux[fI] = this.direccionesForm;
          this.guardarBotonLoading = true;
          this.$store
            .dispatch("clientes/saveDireccionesLocal", {
              idCliente: this.editarCliente.id,
              direccionesLocal: direccionesLocalAux,
            })
            .then((r) => {
              this.$store.commit("snackbar/setMensajeSuccess", {
                msj: "Dirección actualizada",
              });
              this.filtrar();
              this.editarCliente = JSON.parse(
                JSON.stringify(this.editarCliente)
              );
              this.direccionesFormDialog = false;
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("snackbar/setMensajeDanger", {
                msj: "Error al actualizar la dirección: " + e,
              });
            })
            .finally(() => (this.guardarBotonLoading = false));
        } else {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al actualiar la dirección",
          });
        }
      } else {
        //nueva dirección
        let centinelaSinDireccion =
          !!this.estaPidiendoAgregandoClienteSinDirecciones;
        var direccionesLocalAux = this.editarCliente.direccionesLocal
          ? this.editarCliente.direccionesLocal
          : [];
        var dirAdd = { ...this.direccionesForm, id: key() };
        direccionesLocalAux.push(dirAdd);

        this.guardarBotonLoading = true;
        console.log(this.editarCliente);
        this.$store
          .dispatch("clientes/saveDireccionesLocal", {
            idCliente: this.editarCliente.id,
            direccionesLocal: direccionesLocalAux,
          })
          .then((r) => {
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Dirección añadida",
            });

            this.filtrar();
            if (centinelaSinDireccion) {
              this.elegirDireccion(dirAdd);
            }
            this.editarCliente.direccionesLocal = direccionesLocalAux;
            this.direccionesFormDialog = false;
          })
          .catch((e) => {
            console.log(e);
            this.$store.commit("snackbar/setMensajeDanger", {
              msj: "Error al añadir la dirección: " + e,
            });
          })
          .finally(() => (this.guardarBotonLoading = false));
        // this.$store.dispatch("clientes/getCliente",this.editarCliente.id)
      }
    },
    nuevaDireccionDialog() {
      this.direccionesForm.direccionLocal = true;
      this.direccionesFormDialog = true;
      // this.mostrarTeclado = true;
    },
    elegirDireccion(dir) {
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "direccion",
        value: dir,
      });
      this.direccionesDialog = false;
      this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
      this.$store.commit("pedidos/setPedidoDialog", true);
      this.$store.commit("clientes/setClienteDialog", false);
    },
    generarFactura() {
      this.generarFacturaLoading = true;

      var promesa = this.actualizarDatosCliente
        ? this.$store
            .dispatch("clientes/saveCliente", this.editarCliente)
            .then(() =>
              this.$store.dispatch("pedidos/savePedido", {
                id: this.pedidoEditandoObj.id,
                copyUser: {
                  ...this.pedidoEditandoObj.copyUser,
                  ...this.editarCliente,
                },
              })
            )
        : Promise.resolve();

      promesa
        .then((r) =>
          this.$store.dispatch("pedidos/generarFactura", {
            pedido: this.pedidoEditandoObj,
            pago: this.$store.state.pedidos.facturacionContableFacturar,
            copyUser: this.editarCliente,
          })
        )
        .then(() => {
          setTimeout(() => {
            let fDesglose = this.pedidoEditandoObj.cobradoDesglose.find(
              (c) =>
                c.codigoFactura ==
                this.$store.state.pedidos.facturacionContableFacturar
                  .codigoFactura
            );
            this.$store.dispatch("pedidos/impresionFacturaSimplificada", {
              pedido: this.pedidoEditandoObj,
              factSimplificada: !!fDesglose
                ? fDesglose
                : this.$store.state.pedidos.facturacionContableFacturar,
            });
          }, 2500);

          this.clienteDialog = false;
        })
        .finally(() => {
          this.generarFacturaLoading = false;
        });
    },
    guardarDatosCliente() {
      this.guardarBotonLoading = true;
      if (!this.editarCliente.direccionesLocal)
        this.editarCliente.direccionesLocal = [];

      this.$store
        .dispatch("clientes/saveCliente", this.editarCliente)
        .then((r) => {
          console.log(r);
          if (!!this.editarCliente.id) {
            //editar
            if (this.cambiandoCliente) {
              //está cambaidno los datos en un pedido en curso, se actualizan los datos del pedid
              console.log(this.editarCliente);
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "copyUser",
                value: {
                  ...this.editarCliente,
                  displayName: this.editarCliente.localNombre
                    ? this.editarCliente.localNombre
                    : this.editarCliente.displayName
                    ? this.editarCliente.displayName
                    : "",
                  phoneNumber: this.editarCliente.localTelefono
                    ? this.editarCliente.localTelefono
                    : this.editarCliente.phoneNumber
                    ? this.editarCliente.phoneNumber
                    : "",
                },
              });
            }
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Cliente actualizado",
            });
            // this.editarDialog = false;
            // this.filtrar();
            this.$store.dispatch("clientes/filtrarClientes");
          } else {
            //nuevo
            this.editarCliente.id = r.id;
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Cliente creado",
            });
          }
          if (!!this.pedidoEditandoObj) {
            //está agregando un clientes mientras hacía un pedido
            this.$store.commit("pedidos/setPedidoEditandoObjValor", {
              key: "uid",
              value: this.editarCliente.id,
            });
            this.$store.commit("pedidos/setPedidoEditandoObjValor", {
              key: "copyUser",
              value: {
                ...this.editarCliente,
                displayName: this.editarCliente.localNombre
                  ? this.editarCliente.localNombre
                  : this.editarCliente.displayName
                  ? this.editarCliente.displayName
                  : "",
                phoneNumber: this.editarCliente.localTelefono
                  ? this.editarCliente.localTelefono
                  : this.editarCliente.phoneNumber
                  ? this.editarCliente.phoneNumber
                  : "",
              },
            });
            console.log(this.editarCliente);
            if (
              this.pedidoEditandoObj.modo == "domicilio" &&
              !this.cambiandoCliente
            ) {
              this.direccionesForm.direccionLocal = true;
              this.direccionesFormDialog = true;
            } else {
              this.clienteDialog = false;
            }
          }
          if (!!r && !!r.id) this.filtroTelefono = r.id;

          // this.editarDialog = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al actualizar el cliente: " + e,
          });
        })
        .finally(() => (this.guardarBotonLoading = false));
    },
    focusCampo(obj, prop) {
      if (this.$store.state.necesarioTeclado) {
        this.$store.commit("teclado/setMostrarTeclado", true);
        //this.$store.commit("teclado/setLimpiarInput", true);

        this.$store.commit("teclado/setActualValor", obj[prop]);
        this.$store.commit("teclado/setCampoEditarObj", obj);
        this.$store.commit("teclado/setCampoEditarPropiedad", prop);
        setTimeout(() => {
          console.log(obj);
          console.log(prop);
          let pos = obj[prop] && obj[prop].length ? obj[prop].length : 0;
          this.$refs[prop].$el
            .querySelector("input")
            .setSelectionRange(pos, pos);
        }, 200);
      }
    },
    volverPedido() {
      this.modoPantalla = "pedidoCarrito";
      this.clienteDialog = false;
      this.$store.commit("pedidos/setCobrarDialogFullscreen", true);
      this.$store.commit("pedidos/setCambiandoCliente", false);
    },
    editarClienteMetodo() {
      this.cambiandoCliente = true;

      if (this.pedidoEditandoObj.uid) {
        this.pasoCliente = "editarCliente";

        this.$store.dispatch(
          "clientes/buscarClienteEditar",
          this.pedidoEditandoObj.uid
        );
        this.abrirInfoCliente = false;
        // this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
      }
    },
    cambiarCliente() {
      this.cambiandoCliente = true;
      this.pasoCliente = "buscar";
      // this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
      // this.$store.commit("pedidos/setCobrarDialogFullscreen", false);
      // this.$store.commit("clientes/setPasoCliente", "buscar");
    },
    cambiarDireccion() {
      this.direccionesDialog = true;
    },
    continuarSinCliente() {
      console.log(this.modoObj);
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modo",
        value: this.modo,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoId",
        value: this.modoid,
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoTexto",
        value: !!this.modoObj ? this.modoObj.nombre : "",
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "modoCopy",
        value: !!this.modoObj ? this.modoObj : {},
      });

      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "uid",
        value: "0",
      });
      this.$store.commit("pedidos/setPedidoEditandoObjValor", {
        key: "copyUser",
        value: {
          displayName: "Contado",
        },
      });
      this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
      // this.$store.commit("pedidos/setCobrarDialogFullscreen", true);
      this.$store.commit("clientes/setClienteDialog", false);
      this.$store.commit("pedidos/setPedidoDialog", true);

      this.$store.commit("pedidos/setCambiandoCliente", false);

      this.$store.commit("setPaginaPaginacionPedidos", 0);
    },
    onChange(input) {
      if (input.length >= 10) {
        this.alertaMensaje =
          "El número de teléfono no debe tener más de 9 dígitos.";
        this.alertaDialogo = true;
      } else {
        this.filtroTelefono = input;
      }
    },
    onKeyPress(button) {
      if (button == "C") {
        setTimeout(() => {
          this.filtroTelefono = "";
        }, 100);
      }
    },
  },
  mounted() {
    this.$gmapApiPromiseLazy({}).then(() => {
      return;
    });
  },
};
</script>

<style>
.border {
  border: 1px solid #000;
}
</style>