<template>
  <v-card
    elevation="4"
    :style="{ border: color }"
    class="rounded-lg"
    @click="crearPedido"
  >
    <v-responsive class="d-flex rounded-0" :aspect-ratio="1.618">
      <v-img src="/img/fondoFichaFunEats.svg">
        <v-card-text style="position: relative" class="pa-0">
          <!-- <v-fab-transition>
          <v-btn color="pink" dark absolute bottom right fab @click="goPedido">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </v-fab-transition> -->

          <div
            class="pa-2 text-uppercase font-weight-black black--text"
            :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'title'"
            :color="color"
          >
            LIBRE {{ mesa.nombre }}
          </div>
        </v-card-text>
        <v-card-title
          :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'title'"
          class="font-weight-medium"
        >
          Otro texto</v-card-title
        >

        <v-card-subtitle class="text-uppercase text-truncate">
          {{ mesa.nombre }}
        </v-card-subtitle>
        <v-card-actions class="mt-4 acciones-footer">
          <!-- <v-list-item class="grow">
          <v-row align="end">
            <v-col class="pb-0 px-0" cols="8">
              <v-icon
                :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'"
                color="blue-grey lighten-1"
                class="mr-1"
              >
                mdi-account-outline
              </v-icon>
              <span
                :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'subtitle-1'"
                class="mr-1 font-weight-medium black--text"
                >{{ comensales }}</span
              >
              <span class="mr-1">-</span>
              <v-icon
                :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'"
                color="blue-grey lighten-1"
                class="mr-1"
              >
                mdi-clock-time-eight-outline
              </v-icon>
              <span
                :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'subtitle-1'"
                class="font-weight-medium black--text"
                >{{ pedidoComputed.fechaMostrar }}</span
              >
            </v-col>
            <v-col class="text-right pb-0 px-0">
              <span
                :class="$vuetify.breakpoint.mdAndDown ? 'body-2' : 'title'"
                class="font-weight-black"
                >12,95 €</span
              >
            </v-col>
          </v-row>
        </v-list-item> -->
        </v-card-actions>
      </v-img>
    </v-responsive>
  </v-card>
</template>

<script>
import moment from "moment";
import ProductosCarrito from "@/components/ProductosCarrito.vue";
import { mapState } from "vuex";
import Cobrar from "./Cobrar.vue";
export default {
  components: { ProductosCarrito, Cobrar },
  props: {
    mesa: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    mesaLibre() {
      return !this.mesa.pedido;
    },

    ...mapState({
      pedidoViendo: (state) => state.pedidos.pedidoEditandoObj,
    }),
    // pedidoViendo() {
    // 	return this.$store.state.pedido.pedidoEditandoObj;
    // },

    color() {
      return "2px solid #56a7a8";
      switch (this.pedido.canalVenta) {
        case "propio": //Online propio
          return "2px solid #56a7a8";
        case "glovo":
          return "2px solid #f9c52e";
        case "justeat":
          return "2px solid #f27700";
        case "ubereats":
          return "2px solid #4fc83d";
        case "funeats":
          return "2px solid #56a7a8";
        case "tpv":
          return "2px solid #56a700";
      }
    },
  },
  methods: {
    // goPedido() {
    //   this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
    //   this.$store.commit("pedidos/setPedidoDialog", true);
    //   this.pedidoEditando = this.pedido.id;
    //   // this.$router.push("/pedido/" + this.pedidoViendo.id);
    // },
  },
};
</script>

<style>
</style>