export default {
  setPedidos(state, p) {
    state.pedidosArray = p;
  },
  setPedidosListener(state, l) {
    state.pedidosListener = l;
  },
  setPedidosRealizandoTpvListener(state, l) {
    state.pedidosRealizandoTpvListener = l;
  },
  setPedidosRealizandoTpv(state, p) {
    state.pedidosRealizandoTpv = p;
  },
  setPedidosDirectorioMatriz(state, p) {
    state.pedidosDirectorioMatriz = p;
  },
  setPedidosImprimir(state, p) {
    state.pedidosImprimir = p;
  },
  setPedidosImprimirGestionando(state, p) {
    state.pedidosImprimirGestionando = p;
  },
  addPedidoImprimirGestionando(state, p) {
    state.pedidosImprimirGestionando.push(p);
  },
  removePedidoImprimirGestionando(state, p) {
    let fI = state.pedidosImprimirGestionando((i) => i.id == p.id);
    if (fI > -1) state.pedidosImprimirGestionando.splice(fI, 1);
  },
  setPedidoEditando(state, p) {
    state.pedidoEditando = p;
  },
  setSiguienteNumRestDiaListener(state, f) {
    state.siguienteNumRestDiaListener = f;
  },
  setSiguienteNumRestListener(state, f) {
    state.siguienteNumRestListener = f;
  },
  setSiguienteNumRestDia(state, i) {
    state.siguienteNumRestDia = i;
  },
  setSiguienteNumRest(state, i) {
    state.siguienteNumRest = i;
  },
  setPedidoEditandoFlag(state, p) {
    state.pedidoEditandoFlag = p;
  },
  setPedidoEditandoObj(state, obj) {
    state.pedidoEditandoObj = obj;
  },
  setPedidoEditandoObjValor(state, payload) {
    state.pedidoEditandoObj[payload.key] = payload.value;
  },
  setCobrarDialog(state, b) {
    state.cobrarDialog = b;
  },
  setCobrarDialogFullscreen(state, b) {
    state.cobrarDialogFullscreen = b;
  },
  setModoPantalla(state, s) {
    state.modoPantalla = s;
  },
  setPedidoDialog(state, b) {
    state.pedidoDialog = b;
  },
  setAccionesDialog(state, b) {
    state.accionesDialog = b;
  },
  setFacturacionDialog(state, b) {
    state.facturacionDialog = b;
  },
  setFacturacionContableFacturar(state, f) {
    state.facturacionContableFacturar = f;
  },
  setCambiandoCliente(state, b) {
    state.cambiandoCliente = b;
  },
  setFacturandoPedido(state, b) {
    state.facturandoPedido = b;
  },
  setMoverPedidoModoDialog(state, b) {
    state.moverPedidoModoDialog = b;
  },
  setDescuentosDialog(state, b) {
    state.descuentosDialog = b;
  },
  setHistorialDialog(state, b) {
    state.historialDialog = b;
  },
  setAbonoDialog(state, b) {
    state.abonoDialog = b;
  },
  setGrabandoPedido(state, b) {
    state.grabandoPedido = b;
  },

  setEditarProductoObj(state, obj) {
    state.editarProductoObj = obj;
  },
  setEditarProductoMenuObj(state, obj) {
    state.editarProductoMenuObj = obj;
  },
  setAbriendoNoGrabar(state, v) {
    state.abriendoNoGrabar = v;
  },
};
