import { db } from '@/firebase'
import moment from 'moment';
import { VAutocomplete } from 'vuetify/lib';
const STX = 0x02;
const ETX = 0x03;
const ACK = 0x06;
const NAK = 0x15;

export default {
    vincularSerie() {
        return navigator.serial.requestPort()
            .then(device => {
                console.log(device)
            })

            .catch(error => { console.error(error); });
    },
    vincularUsb() {
        return navigator.usb.requestDevice({ filters: [] })
            .then(device => {

            })
            .catch(error => { console.error(error); });
    },
    vincularHid() {
        if ("hid" in navigator) {
            // The WebHID API is supported.
            console.log("soportado")
        } else {
            console.log("nosoooportaado")
        }
        return navigator.hid.requestDevice({ filters: [] })
            .then(device => {
                console.log(device)
            })
            .catch(error => { console.error(error); });
    },

    verSerie({ rootState }) {
        return navigator.serial.getPorts()
            .then(devices => {

                return devices.map((d, i) => {

                    return {
                        ...d,
                        productId: rootState.auth.user.email + '_serie_' + i,
                        productName: "Puerto serie " + rootState.auth.user.email + ' ' + i,
                        puerto: 'serie'
                    }
                });
            })
            .catch(error => { console.error(error); });
    },
    verSerieNativo({ }) {
        return navigator.serial.getPorts()
            .then(devices => {
                console.log(devices);
                return devices
            })
    },
    verUsbNativo() {
        return navigator.usb.getDevices()
            .then(devices => {
                return devices
            })
    },
    verUsb() {
        return navigator.usb.getDevices()
            .then(devices => {
                // return devices
                return devices.map(d => {
                    return {
                        ...d,
                        productId: d.productId,
                        productName: d.productName,
                        puerto: 'usb'
                    }
                });
            })
    },
    verHid() {
        return navigator.hid.getDevices()
            .then(devices => {
                console.log(devices);
                return devices.map(d => {
                    return d;
                    console.log(d);
                    let copia = {
                        productId: d.productId,
                        productName: d.productName,
                        vendorId: d.vendorId,
                    }
                    copia.puerto = 'hid';
                    return copia

                });
            })
    },
    verUsbPorId({ dispatch }, id) {
        return dispatch('verUsbNativo').then(r => r.filter(d => d.productId == id));
    },
    verHidPorId({ dispatch }, id) {
        return dispatch('verHid').then(r => r.filter(d => d.productId == id));
    },
    enviarDatosDispositivo({ dispatch, commit }, { datos, dispositivo }) {
        var device;
        commit('setDispositivoEnUso', dispositivo.productId)
        return dispatch('verUsbPorId', dispositivo.productId).then(r => {
            if (r.length == 0) {
                return Promise.reject("No se encuentra el dispositivo");
            } else {
                device = r[0];
                return device.open()
            }
        })
            .then(() => device.selectConfiguration(1)) // Select configuration #1 for the device.
            .then(() => device.claimInterface(0))
            .then(() => {

                // const ESC_INIT = [0x1b, 0x40];
                // const CORTE = [0x1D, 0x56, 49];
                // var init = new Uint8Array([...ESC_INIT])
                // var corte = new Uint8Array([...CORTE])
                // var text = "prueba de texto prueba de texto prueba de texto prueba de texto prueba de texto prueba de texto prueba de texto prueba de texto prueba de texto prueba de texto prueba de texto "
                // var data = new Uint8Array(text.split('').map(char => char.charCodeAt(0)))
                return device.transferOut(1, datos);
            })
            .then(result => {
                return true;
            })
            .catch(error => {
                commit("snackbar/setMensajeDanger", {
                    msj: "Error al enviar los datos: " + error,
                }, { root: true });
                console.log(error);

                return false;
            }) // Request exclusive control over interface #2.
            .finally(() => {
                commit('setDispositivoLibre', dispositivo.productId)
            })

    },
    imprimirPrueba() {





        const options = {
            // preview: false, // Preview in window or print
            // width: '275px', // width of content body
            // margin: '0 0 30px 0', // margin of content body
            // copies: 1, // Number of copies to print
            printerName: "Xprinter_USB_Printer_P_2", // printerName: string, check it at webContent.getPrinters()
            timeOutPerLine: 4000,// Two seconds of wait for each print instruction
            silent: true
        }

        const data = [
            // {
            //     type: 'image',
            //     path: path.join(__dirname, 'assets/banner.png'),     // file path
            //     position: 'center',                                  // position of image: 'left' | 'center' | 'right'
            //     width: '60px',                                           // width of image in px; default: auto
            //     height: '60px',                                          // width of image in px; default: 50 or '50px'
            // }, 
            {
                type: 'text',                                       // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
                value: 'SAMPLE HEADING',
                style: `text-align:center;`,
                css: { "font-weight": "700", "font-size": "18px" }
            },
            // {
            //     type: 'text',                       // 'text' | 'barCode' | 'qrCode' | 'image' | 'table'
            //     value: 'Secondary text',
            //     style: `text-align:left;color: red;`,
            //     css: { "text-decoration": "underline", "font-size": "10px" }
            // }, {
            //     type: 'barCode',
            //     value: 'HB4587896',
            //     height: 12,                     // height of barcode, applicable only to bar and QR codes
            //     width: 1,                       // width of barcode, applicable only to bar and QR codes
            //     displayValue: true,             // Display value below barcode
            //     fontsize: 8,
            // }, {
            //     type: 'qrCode',
            //     value: 'https://github.com/Hubertformin/electron-pos-printer',
            //     height: 55,
            //     width: 55,
            //     style: 'margin: 10 20px 20 20px'
            // }, {
            //     type: 'table',
            //     // style the table
            //     style: 'border: 1px solid #ddd',
            //     // list of the columns to be rendered in the table header
            //     tableHeader: ['Animal', 'Age'],
            //     // multi dimensional array depicting the rows and columns of the table body
            //     tableBody: [
            //         ['Cat', 2],
            //         ['Dog', 4],
            //         ['Horse', 12],
            //         ['Pig', 4],
            //     ],
            //     // list of columns to be rendered in the table footer
            //     tableFooter: ['Animal', 'Age'],
            //     // custom style for the table header
            //     tableHeaderStyle: 'background-color: #000; color: white;',
            //     // custom style for the table body
            //     tableBodyStyle: 'border: 0.5px solid #ddd',
            //     // custom style for the table footer
            //     tableFooterStyle: 'background-color: #000; color: white;',
            // },
            // // {
            // //     type: 'table',
            // //     style: 'border: 1px solid #ddd',             // style the table
            // //     // list of the columns to be rendered in the table header
            // //     tableHeader: [{ type: 'text', value: 'Animal' }, { type: 'image', path: path.join(__dirname, 'icons/animal.png') }],
            // //     // multi dimensional array depicting the rows and columns of the table body
            // //     tableBody: [
            // //         [{ type: 'text', value: 'Cat' }, { type: 'image', path: './animals/cat.jpg' }],
            // //         [{ type: 'text', value: 'Dog' }, { type: 'image', path: './animals/dog.jpg' }],
            // //         [{ type: 'text', value: 'Horse' }, { type: 'image', path: './animals/horse.jpg' }],
            // //         [{ type: 'text', value: 'Pig' }, { type: 'image', path: './animals/pig.jpg' }],
            // //     ],
            // //     // list of columns to be rendered in the table footer
            // //     tableFooter: [{ type: 'text', value: 'Animal' }, 'Image'],
            // //     // custom style for the table header
            // //     tableHeaderStyle: 'background-color: #000; color: white;',
            // //     // custom style for the table body
            // //     tableBodyStyle: 'border: 0.5px solid #ddd',
            // //     // custom style for the table footer
            // //     tableFooterStyle: 'background-color: #000; color: white;',
            // // },
        ]

        ipcRenderer.send("imprimir")


    },
    volcarDispositivosAlServidor({ rootState, rootGetters }, dispositivos) {
        return dispositivos.map((imp, i) => {

            let productId = imp.productId ? imp.productId : imp.id

            let f = rootState.datosTpv.dispositivosTpv.find(d => d.productId == productId)
            let id = rootState.auth.user.email + '_' + productId.toString();
            return db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('secreto').collection('dispositivos').doc(id)
                .set({
                    productId: productId,
                    productName: imp.productName ? imp.productName : ("com-" + i),
                    origen: 'tpv',
                    cuenta: rootState.auth.user.email,
                    tipo: imp.tipo ? imp.tipo : 'sin-especificar',
                    puerto: imp.puerto ? imp.puerto : 'sin-especificar',
                    ...!!f ? f : {}
                }, {
                    merge: true
                })
        })
        // var dispositivosTpv = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('secreto').collection('dispositivos')
    },
    conectarLectorCodigo({ state, commit, rootGetters, dispatch }) {
        let lectores = rootGetters['lectores']
        var device;
        return dispatch('verHidPorId', lectores[0].productId)
            .then(r => {
                device = r[0]
                if (!device.opened)
                    return device.open()
            })
            // .then(() => device.selectConfiguration(1)) // Select configuration #1 for the device.
            .then(() => {
                device.oninputreport = (e) => {
                    commit('setCodigoLector', e)
                }

                device.addEventListener("inputreport", event => {

                    const { data, device, reportId } = event;

                    // Handle only the Joy-Con Right device and a specific report ID.
                    if (device.productId !== 0x2007 && reportId !== 0x3f) return;

                    const value = data.getUint8(0);
                    if (value === 0) return;

                    const someButtons = { 1: "A", 2: "X", 4: "B", 8: "Y" };
                });
            })
            .catch(error => {
                commit("snackbar/setMensajeDanger", {
                    msj: "Error al enviar los datos: " + error,
                }, { root: true });
                console.log(error);
            }) // Request exclusive control over interface #2.

    },
    desconectarLectorCodigo({ state, commit, rootGetters, dispatch }) {
        let lectores = rootGetters['lectores']
        var device;
        return dispatch('verHidPorId', lectores[0].productId)
            .then(r => {

                device = r[0]
                if (!device.opened)
                    return device.open()
            })
            // .then(() => device.selectConfiguration(1)) // Select configuration #1 for the device.
            .then(() => {
                device.oninputreport = null
            })
            .catch(error => {
                commit("snackbar/setMensajeDanger", {
                    msj: "Error al enviar los datos: " + error,
                }, { root: true });
                console.log(error);
            }) // Request exclusive control over interface #2.

    },
    conectarBalanza({ state, commit, rootGetters, dispatch }) {

        let balanzas = rootGetters['balanzas']
        if (!balanzas[0]) return Promise.reject("sin balanzas")
        let balanza = balanzas[0]
        // var protocolo = balanza.tipo == 'serie' ? dispatch('verSerie') : dispatch('verUsb')
        return dispatch('verSerieNativo')
            .then(r => {
                if (r.length > 0) {
                    let i = parseInt(balanza.numeroPuerto);
                    if (isNaN(i)) {
                        i = 0;
                    }
                    commit('setBalanza', r[i])


                    commit('setLeerBalanza', true);
                    return state.balanza.open({ baudRate: parseInt(balanza.baudRate), parity: 'none', dataBits: 8, stopBits: 1 });
                } else {
                    return Promise.reject("Sin balanza")
                }
            })
            .then(async () => {

                let reader;


                while (state.balanza.readable && state.leerBalanza) {
                    commit('setReaderBalanza', state.balanza.readable.getReader());

                    try {
                        var i = 0;
                        var inicio = true;
                        var msj = [];
                        while (true) {

                            const { value, done } = await state.readerBalanza.read();

                            if (done) {
                                // reader.cancel() has been called.
                                break;
                            }
                            // value is a Uint8Array.
                            value.forEach(v => {
                                if (v == 2) {//inicio

                                    msj = "";
                                } else if (v == 3) {//fin
                                    if (!isNaN(parseFloat(msj.substring(1))))
                                        commit('setPesoBalanza', parseFloat(msj.substring(1)))
                                } else { //cadena
                                    msj += String.fromCharCode(v)
                                }
                            })

                        }
                    } catch (error) {
                        console.log(error)
                        // Handle error...
                    } finally {
                        // Allow the serial port to be closed later.
                        state.readerBalanza.releaseLock();
                    }
                }

                await state.balanza.close();
                commit('setLeerBalanza', false)

            })
            .catch(e => console.log(e))


    },
    async cerrarBalanza({ state, commit, dispatch }) {
        commit('setLeerBalanza', false)
        state.readerBalanza.cancel();
        await dispatch('conectarBalanza')
        commit('setBalanza', null)


    }
}
