<template>
  <v-card
    elevation="4"
    :disabled="
      !!pedidoComputed.eliminado ||
      pedidoComputed.id == $store.state.impresion.pedidoOcupado
    "
    class="rounded-lg ficha-generica"
    @click="goPedido"
    :class="
      pedidoComputed.cobrado
        ? 'cobrada'
        : !!pedidoComputed.eliminado
        ? 'eliminada'
        : !!pedidoComputed.solicitarEliminado
        ? 'orange lighten-1'
        : ''
    "
  >
    <!-- :style="{ border: color }" -->
    <v-responsive class="d-flex rounded-0" :aspect-ratio="1.618">
      <v-img src="/img/fondoFichaFunEats.svg">
        <v-card-text style="position: relative" class="pa-0">
          <!-- :color="color" -->
          <v-list class="pb-0 pt-0 pt-md-2" color="transparent">
            <v-list-item class="pl-0 pr-16">
              <v-list-item-content class="py-0 align-self-start">
                <v-list-item-title
                  class="
                    px-2 px-lg-3
                    text-uppercase
                    font-weight-black
                    black--text
                    text-lg-h5
                  "
                  :class="
                    $vuetify.breakpoint.xsOnly
                      ? 'py-1 body-2'
                      : $vuetify.breakpoint.mdAndDown
                      ? 'body-2'
                      : 'title'
                  "
                >
                  {{ nombreMostrar }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="pedidoComputed.nombrePersonalizadoTpv"
                  class="
                    px-2
                    pl-lg-3
                    pr-lg-10
                    text-lg-h6
                    mt-n2 mt-md-0
                    font-weight-black
                    cyan--text
                  "
                  :class="$vuetify.breakpoint.mdAndDown ? 'caption' : ''"
                >
                  {{ pedidoComputed.nombrePersonalizadoTpv }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div
            v-if="pedido.numPedidoRestDia"
            class="
              card--numero-pedido
              px-2 px-lg-3
              text-lg-h2 text-sm-h3 text-h4
              mt-lg-2
              font-weight-black
              cyan--text
              text--lighten-2
            "
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'font-size: 2.5rem !important;'
                : $vuetify.breakpoint.lgAndUp
                ? 'font-size: 5rem !important;'
                : ''
            "
          >
            <span v-if="pedido.numPedidoRestDia <= 9">0</span
            >{{ pedido.numPedidoRestDia }}
          </div>
        </v-card-text>
        <v-card-subtitle
          :class="$vuetify.breakpoint.mdAndDown ? 'caption pt-0' : 'title'"
          class="px-2 mt-n3 mt-md-n1 mt-lg-n3 font-weight-medium"
          :style="$vuetify.breakpoint.xsOnly ? 'margin-top: -4px;' : ''"
        >
          {{ nombreClienteMostrar }}</v-card-subtitle
        >
        <!--       <v-card-subtitle
        :class="$vuetify.breakpoint.mdAndDown ? 'px-2' : ''"
        class="text-uppercase text-truncate"
      >
        {{ pedidoComputed.modo }}
      </v-card-subtitle> -->
        <div class="warning-status">
          <v-icon
            v-if="pedidoComputed.estadoCocina == 'parado'"
            :size="$vuetify.breakpoint.mdAndDown ? '22' : '32'"
            color="yellow darken-4"
            class="mr-1"
          >
            mdi-pause
          </v-icon>

          <v-icon
            v-if="
              pedidoComputed.estado == 'finalizado' &&
              pedidoComputed.impresionLocalEstado != 'impreso' &&
              pedidoComputed.printNodeEstado != 'imprimido'
            "
            :size="$vuetify.breakpoint.mdAndDown ? '22' : '32'"
            color="red lighten-1"
            class="mr-1"
          >
            mdi-printer
          </v-icon>
        </div>
        <v-card-actions class="mt-4 acciones-footer">
          <v-row align="end" class="pb-1">
            <v-col
              class="py-0"
              :class="$vuetify.breakpoint.xsOnly ? 'px-2' : ''"
              cols="6"
            >
              <div v-if="comensales" class="text-no-wrap">
                <v-icon
                  :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'"
                  color="blue-grey lighten-1"
                  class="mr-1"
                >
                  mdi-account-outline
                </v-icon>
                <span
                  :class="
                    $vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'
                  "
                  class="mr-1 font-weight-medium black--text"
                  >{{ comensales }}</span
                >
              </div>
              <div class="text-no-wrap mt-n2">
                <v-icon
                  :size="$vuetify.breakpoint.mdAndDown ? '12' : '22'"
                  color="blue-grey lighten-1"
                  class="mr-1"
                >
                  mdi-clock-time-eight-outline
                </v-icon>

                <span
                  :class="
                    $vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'
                  "
                  class="font-weight-medium black--text"
                  >{{ pedidoComputed.fechaMostrarContador }}</span
                >
              </div>
              <!-- if DOMICILIO -->
              <div
                v-if="
                  pedidoComputed.modo == 'domicilio' &&
                  $vuetify.breakpoint.smAndUp
                "
                class="text-no-wrap mt-n2"
              >
                <v-icon
                  :size="$vuetify.breakpoint.mdAndDown ? '16' : '28'"
                  color="blue-grey lighten-1"
                  class="mr-1"
                >
                  mdi-moped-outline
                </v-icon>
                <!-- if RECOGER -->
                <!-- <v-icon
                :size="$vuetify.breakpoint.mdAndDown ? '14' : '25'"
                color="blue-grey lighten-1"
                class="mr-1 ml-2"
              >
                mdi-store-outline
              </v-icon> -->
                <span
                  :class="
                    $vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'
                  "
                  class="font-weight-medium black--text"
                  >{{ pedidoComputed.horaEntrega }}h</span
                >
              </div>
              <div
                v-if="pedidoComputed.horaCobrado && $vuetify.breakpoint.smAndUp"
                class="text-no-wrap mt-n2"
              >
                <v-icon
                  :size="$vuetify.breakpoint.mdAndDown ? '16' : '28'"
                  color="blue-grey lighten-1"
                  class="mr-1"
                >
                  mdi-cash-multiple
                </v-icon>
                <!-- if RECOGER -->
                <!-- <v-icon
                :size="$vuetify.breakpoint.mdAndDown ? '14' : '25'"
                color="blue-grey lighten-1"
                class="mr-1 ml-2"
              >
                mdi-store-outline
              </v-icon> -->
                <span
                  :class="
                    $vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'
                  "
                  class="font-weight-medium black--text"
                  >{{ pedidoComputed.horaCobrado }}h</span
                >
              </div>
            </v-col>
            <v-col class="py-0 px-2 pr-lg-3 text-right" cols="6">
              <span
                :class="
                  $vuetify.breakpoint.xsOnly
                    ? 'px-1'
                    : $vuetify.breakpoint.mdAndDown
                    ? 'body-1'
                    : 'title'
                "
                class="font-weight-black text-no-wrap"
                >{{ totalMostrar }}€</span
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-img>
    </v-responsive>
  </v-card>
</template>

<script>
import moment from "moment";
import ProductosCarrito from "@/components/ProductosCarrito.vue";
import { mapState } from "vuex";
import Cobrar from "./Cobrar.vue";
import { mostrarMoneda } from "@/utils/helper.utils.js";

export default {
  components: { ProductosCarrito, Cobrar },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    totalMostrar() {
      return !!this.pedido.precioTotal
        ? mostrarMoneda(this.pedido.precioTotal)
        : "0.00";
    },
    mensajesComputados() {
      return this.pedido.historial
        ? this.pedido.historial
            .filter((p) => p.mensaje.includes("Mensaje desde la web"))
            .map((p) => {
              return {
                ...p,
                fechaMostrar: moment.unix(p.fecha).format("DD-MM-YYYY HH:mm"),
              };
            })
        : [];
    },
    pedidoComputed() {
      let tz =
        this.pedido.copyRest &&
        this.pedido.copyRest.confGeneral &&
        this.pedido.copyRest.confGeneral.zonaHoraria
          ? this.pedido.copyRest.confGeneral.zonaHoraria
          : "Europe/Madrid";
      let tiempo =
        this.pedido.modo == "domicilio"
          ? this.pedido.copyRest
            ? this.pedido.copyRest.tiempoDomicilio
            : 0
          : this.pedido.copyRest
          ? this.pedido.copyRest.tiempoRecogida
          : 0;
      let actualMoment = moment(this.$store.state.horaActual);
      let fechaMoment = moment(this.pedido.fechaServidor.toDate()).tz(tz);
      let entregaMoment = fechaMoment.clone().add(tiempo, "minutes");
      let cobradoMostrar = !!this.pedido.cobrado
        ? moment.unix(this.pedido.cobrado).tz(tz).format("HH:mm")
        : false;

      var durantion = moment.duration(actualMoment.diff(fechaMoment));
      let fechaMostrarContador =
        parseInt(durantion.asMinutes()) < 60
          ? parseInt(durantion.asMinutes()) + `'`
          : fechaMoment.format("HH:mm");
      return {
        ...this.pedido,
        fechaMostrar: fechaMoment.format("HH:mm"),
        fechaMostrarContador: fechaMostrarContador,
        horaEntrega: entregaMoment.format("HH:mm"),
        horaCobrado: cobradoMostrar,
      };
    },
    ...mapState({
      pedidoViendo: (state) => state.pedidos.pedidoEditandoObj,
    }),
    // pedidoViendo() {
    // 	return this.$store.state.pedido.pedidoEditandoObj;
    // },
    pedidoEditando: {
      get() {
        return this.$store.state.pedidos.pedidoEditando;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditando", v);
      },
    },
    nombreMostrar() {
      var res = "";
      let f = this.$store.state.datosTpv.modos.find(
        (m) => m.id == this.pedido.modoId
      );
      return (f && f.nombre ? f.nombre : this.pedido.modo).toUpperCase();
      //+
      //" " +
      //this.pedido.numPedidoRestDia

      // switch (this.pedido.modo) {
      //   case "domicilio":
      //     res = f ? f.nombre : "" + " " + this.pedido.numPedidoRestDia;
      //     break;
      //   case "recoger":
      //     res = f ? f.nombre : "" + " " + this.pedido.numPedidoRestDia;
      //     break;
      //   case "mostrador":
      //     res = f ? f.nombre : "" + " " + this.pedido.numPedidoRestDia;
      //     break;
      // }
      // return res + (!!this.pedido.eliminado ? " (Eliminado)" : "");
    },
    nombreClienteMostrar() {
      return this.pedido.copyUser && this.pedido.copyUser.phoneNumber
        ? this.pedido.copyUser.phoneNumber
        : this.pedido.copyUser && this.pedido.copyUser.displayName
        ? this.pedido.copyUser.displayName
        : this.pedido.copyUser && this.pedido.copyUser.email
        ? this.pedido.copyUser.email
        : "Cliente";
    },
    comensales() {
      return this.pedido.comensales ? this.pedido.comensales : false;
    },
    color() {
      switch (this.pedido.canalVenta) {
        case "propio": //Online propio
          return "2px solid #56a7a8";
        case "glovo":
          return "2px solid #f9c52e";
        case "justeat":
          return "2px solid #f27700";
        case "ubereats":
          return "2px solid #4fc83d";
        case "funeats":
          return "2px solid #56a7a8";
        case "tpv":
          return "2px solid #56a700";
      }
    },
  },
  methods: {
    goPedido() {
      if (!!this.pedido.cobrado) {
        // this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
        // this.$store.commit("pedidos/setPedidoDialog", true);
        this.$store.commit("pedidos/setCobrarDialog", true);
        this.pedidoEditando = this.pedido.id;
        this.$store.dispatch("pedidos/setPedidoObj", this.pedido.id);
      } else {
        this.$store.commit("pedidos/setModoPantalla", "pedidoCarrito");
        this.$store.commit("pedidos/setPedidoDialog", true);
        this.pedidoEditando = this.pedido.id;
      }

      // this.$router.push("/pedido/" + this.pedidoViendo.id);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.card--numero-pedido {
  position: absolute;
  top: 0;
  right: -4px;
}
.warning-status {
  position: absolute;
  right: 0;
  top: 50%;
}
</style>