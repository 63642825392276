<template>
  <v-dialog persistent fullscreen v-model="zcierreFinDialog" scrollable>
    <v-card id="capture" flat rounded="0" color="blue-grey lighten-5">
      <v-toolbar dark style="z-index: 1" height="78">
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          CIERRE DIARIO FINALIZAR
        </h2>
        <v-spacer></v-spacer>
        <v-select
          v-model="graficasMostrar"
          label="Visualización"
          :items="graficasMostrarItems"
          style="max-width: 200px"
          dense
          solo
          light
          hide-details
          class="elevation-0 mr-8"
          background-color="white"
          color="secondary"
        />
        <v-select
          v-model="tipoMostrar"
          label="Tipo mostrar"
          :items="tipoMostrarItems"
          style="max-width: 200px"
          dense
          solo
          light
          hide-details
          class="elevation-0 mr-8"
          background-color="white"
          color="secondary"
        />
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="zcierreFinDialog = false"
          top
          right
        >
          <v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 44" color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-4 blue-grey lighten-5 border">
        <v-row style="height: calc(100vh - 154px)">
          <!-- izquierda -->
          <v-col cols="12" md="8" class="flex-grow-1 scroll-y--helper">
            <v-row>
              <v-col cols="12">
                <v-card rounded="lg" elevation="1">
                  <v-card-text>
                    <v-row id="cabecera">
                      <v-col cols="12" md="3">
                        <div class="title text-center">Total ventas</div>
                        <div class="title font-weight-bold text-center">
                          {{ totalVentas }} €
                        </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="12" md="3">
                        <div class="title text-center">Total pedidos</div>
                        <div class="title font-weight-bold text-center">
                          {{ totalNumPedidos }}
                        </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="12" md="3" v-if="!!$store.state.zcierre">
                        <div class="title text-center">Hora inicio</div>
                        <div class="title font-weight-bold text-center">
                          {{ inicioMostrar }}
                        </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="12" md="3" v-if="!!$store.state.zcierre">
                        <div class="title text-center">Hora cierre</div>
                        <div class="title font-weight-bold text-center">
                          {{ finMostrar }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <template
              v-if="graficasMostrar === 'graficas' || finDiaConfirmarLoading"
            >
              <v-row id="graficos-donuts">
                <v-col cols="12" md="6">
                  <v-card rounded="lg" elevation="1">
                    <v-card-title class="grey--text text--darken-2"
                      >Pedidos/Mesas</v-card-title
                    >
                    <v-card-text>
                      <formas-pago-donut
                        id="modosDonut"
                        :chart-data="pedidosModosDonut"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="pedidosFormasPagoDonut">
                  <v-card rounded="lg" elevation="1">
                    <v-card-title class="grey--text text--darken-2"
                      >Formas de pago</v-card-title
                    >
                    <v-card-text>
                      <formas-pago-donut
                        id="formasPagoDonut"
                        :chart-data="pedidosFormasPagoDonut"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card rounded="lg" elevation="1">
                    <v-card-title class="grey--text text--darken-2"
                      >Productos más vendidos</v-card-title
                    >
                    <v-card-text>
                      <categorias-productos-area
                        id="productosMasVendidosDonut"
                        :chart-data="productosArea"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card rounded="lg" elevation="1">
                    <v-card-title class="grey--text text--darken-2"
                      >Categorías más vendidas</v-card-title
                    >
                    <v-card-text>
                      <categorias-productos-area
                        id="categoriasMasVendidosDonut"
                        :chart-data="categoriasArea"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="tipoMostrar == 'cobrados'">
                <v-col cols="12" class="pb-1">
                  <v-card rounded="lg" elevation="1">
                    <v-card-title class="grey--text text--darken-2"
                      >Ventas por horas</v-card-title
                    >
                    <v-card-text>
                      <pedidos-horas-barras
                        id="pedidosHorasBarras"
                        :chartData="pedidosHorasBarrasDatos"
                        :options="pedidosHorasBarrasOpciones"
                        style="height: 300px"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template
              v-if="graficasMostrar === 'tabla' || finDiaConfirmarLoading"
            >
              <!--                 <v-row class="ma-0" v-for="(r, i) in resumenCierreArray" :key="i">
                <v-col cols="12">
                  <div class="font-weight-bold">{{ r.nombre }}</div>
                  <v-row>
                    <v-col cols="2" class="text-center">
                      <div class="font-weight-bold">NÚMERO PEDIDOS</div>
                      <div class="font-weight-bold text-h5 success--text">
                        {{ r.numPedidos }}
                      </div>
                    </v-col>
                    <v-col cols="2" class="text-center">
                      <div class="font-weight-bold">CANTIDAD</div>
                      <div class="font-weight-bold text-h5 success--text">
                        {{ r.totalMostrar }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12">
                  <v-card id="formasPagoTabla" rounded="lg">
                    <v-card-text>
                      <v-simple-table class="mr-1 mb-1 overflow-hidden">
                        <thead>
                          <tr>
                            <th>FORMA DE PAGO</th>

                            <th class="text-right">Total vendido</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(r, i) in resumenCierreFormasPago"
                            :key="i"
                          >
                            <td class="font-weight-bold">{{ r.nombre }}</td>

                            <td
                              class="
                                text-right
                                font-weight-bold
                                success--text
                                text-h5
                              "
                            >
                              {{ r.totalMostrar }}€
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" class="pb-1">
                  <v-card id="modosTabla" rounded="lg">
                    <v-card-text>
                      <v-simple-table class="mr-1 mb-1 overflow-hidden">
                        <thead>
                          <tr>
                            <th>MODO</th>
                            <th class="text-center">Número de pedidos</th>
                            <th class="text-right">Total vendido</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(r, i) in resumenCierreModo" :key="i">
                            <td class="font-weight-bold">{{ r.nombre }}</td>
                            <td class="text-center text-h5">
                              {{ r.numPedidos }}
                            </td>
                            <td
                              class="
                                text-right
                                font-weight-bold
                                success--text
                                text-h5
                              "
                            >
                              {{ r.totalMostrar }}€
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-col>
          <!-- derecha -->
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="flex-grow-1"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-row>
              <v-col cols="12">
                <v-simple-table
                  class="mx-1 elevation-1 rounded-lg mb-1 overflow-hidden"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">Inicial</th>
                      <th class="text-center">Estimado</th>
                      <th class="text-center">Hay</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(c, i) in cuentasMostrar" :key="c.id">
                      <td class="caption">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ i + 1 }}</span>
                          </template>
                          <span>{{ c.nombre }}</span>
                        </v-tooltip>
                      </td>
                      <td
                        :class="
                          campoCambiando == 'hay_' + c.id
                            ? 'green lighten-3'
                            : 'grey lighten-3'
                        "
                        @click="
                          !!c.marcaCaja
                            ? cambiarSeleccion('hay_' + c.id)
                            : () => {
                                return;
                              }
                        "
                        class="
                          text-center
                          font-weight-bold
                          text--darken-2
                          caption
                          grey--text
                        "
                      >
                        {{ c.efectivoMostrar }}
                      </td>
                      <td
                        class="
                          text-center
                          font-weight-bold
                          text--darken-2
                          caption
                          grey--text
                        "
                      >
                        {{ !!c.marcaCaja ? c.efectivoFinDebeMostrar : "-" }}
                      </td>
                      <td
                        :class="
                          campoCambiando == 'hay_' + c.id
                            ? 'green lighten-3'
                            : 'grey lighten-3'
                        "
                        @click="
                          !!c.marcaCaja
                            ? cambiarSeleccion('hay_' + c.id)
                            : () => {
                                return;
                              }
                        "
                        class="
                          text-center
                          font-weight-bold
                          body-1
                          success--text
                        "
                      >
                        {{ !!c.marcaCaja ? c.efectivoFinHayMostrar : "-" }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-col cols="12">
                <KeyboardNumerico
                  keyboardClass="keyboard-zcierre"
                  noDividir
                  ref="keyboardRefNumerico"
                  @onChange="onChange"
                  @onKeyPress="onKeyPress"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="resumenCierreArray"
              disable-pagination
              disable-sort
              hide-default-footer
              :items-per-page="50"
              class="rounded-lg"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="rounded-b-lg blue-grey lighten-5">
        <v-spacer />
        <v-btn
          v-if="!!$store.state.zcierre"
          :loading="finDiaConfirmarLoading"
          @click="finDiaConfirmar"
          large
          color="primary"
          class="black--text rounded-lg"
          >CERRAR DÍA Y ENVIAR EMAIL</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import html2canvas from "html2canvas";
import KeyboardNumerico from "./KeyboardNumerico";
import { mostrarMoneda, isFloat } from "@/utils/helper.utils.js";
import jsPDF from "jspdf";
import { mapState } from "vuex";
import { storage } from "@/firebase";

import moment from "moment";
import PedidosHorasBarras from "./graficos/PedidosHorasBarras.vue";
import FormasPagoDonut from "./graficos/FormasPagoDonut.vue";
import CategoriasProductosArea from "./graficos/CategoriasProductosArea.vue";
export default {
  components: {
    KeyboardNumerico,
    PedidosHorasBarras,
    FormasPagoDonut,
    CategoriasProductosArea,
  },
  data() {
    return {
      tipoMostrar: "cobrados",
      graficasMostrar: "graficas",
      finDiaConfirmarLoading: false,
      campoCambiando: null,
      zcierreDatosData: {},
      ChartsBgColors: [
        "#00f4d9aa",
        "#fb8c00aa",
        "rgba(255, 206, 86, 0.6)",
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
        "rgba(54, 206, 0, 0.6)",
      ],
      ChartsBorderColors: [
        "#00f4d9",
        "#fb8c00",
        "rgba(255, 206, 86, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(54, 206, 0, 1)",
      ],
    };
  },

  watch: {
    zcierreFinDialog(v) {
      if (v) {
        this.calcularCierre();
      }
    },
    zcierreDatos: {
      handler(v) {
        this.calcularCierre();
      },
      deep: true,
    },
  },
  computed: {
    totalVentas() {
      if (this.resumenCierre[this.tipoMostrar]) {
        return mostrarMoneda(
          this.resumenCierre[this.tipoMostrar].totales.total
        );
      }
    },
    totalNumPedidos() {
      if (this.resumenCierre[this.tipoMostrar]) {
        return this.resumenCierre[this.tipoMostrar].totales.numPedidos;
      }
    },
    productosArea() {
      if (this.resumenCierre.productos) {
        var backgroundColor = this.ChartsBgColors;
        var borderColor = this.ChartsBorderColors;
        let prds = this.resumenCierre.productos.productos.sort(
          (a, b) => b.precioTotal - a.precioTotal
        );
        prds = prds.slice(0, 6);

        return {
          labels: prds.map((p) => p.nombre),
          datasets: [
            {
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              data: prds.map((p) => mostrarMoneda(p.precioTotal)),
            },
          ],
        };
      }
    },
    categoriasArea() {
      if (this.resumenCierre.productos) {
        var backgroundColor = this.ChartsBgColors;
        var borderColor = this.ChartsBorderColors;
        return {
          labels: this.resumenCierre.productos.categorias.map((p) => p.id),

          datasets: [
            {
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              data: this.resumenCierre.productos.categorias.map((p) =>
                mostrarMoneda(p.precioTotal)
              ),
            },
          ],
        };
      }
    },
    pedidosFormasPagoDonut() {
      if (this.tipoMostrar !== "cobrados") return false;
      if (this.resumenCierre[this.tipoMostrar]) {
        var backgroundColor = this.ChartsBgColors;
        var borderColor = this.ChartsBorderColors;
        return {
          labels: [
            "Efectivo",
            "Tarjeta local",
            "Tarjeta domicilio",
            "Tarjeta online",
            "Otro",
          ],

          datasets: [
            {
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              data: [
                mostrarMoneda(
                  this.resumenCierre[this.tipoMostrar].totales.efectivo
                ),
                mostrarMoneda(
                  this.resumenCierre[this.tipoMostrar].totales.tarjeta_local
                ),
                mostrarMoneda(
                  this.resumenCierre[this.tipoMostrar].totales.tarjeta_domicilio
                ),
                mostrarMoneda(
                  this.resumenCierre[this.tipoMostrar].totales.tarjeta_online
                ),
                mostrarMoneda(
                  this.resumenCierre[this.tipoMostrar].totales.otro
                ),
              ],
            },
          ],
        };
      }
    },
    pedidosModosDonut() {
      if (this.resumenCierre[this.tipoMostrar]) {
        var backgroundColor = this.ChartsBgColors;
        var borderColor = this.ChartsBorderColors;

        return {
          labels: this.resumenCierre[this.tipoMostrar].modos.map(
            (m) => m.nombre
          ),
          datasets: [
            {
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              data: this.resumenCierre[this.tipoMostrar].modos.map((h) =>
                mostrarMoneda(h.total)
              ),
            },
          ],
        };
      }
    },

    pedidosHorasBarrasOpciones() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },

          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              id: "Total",
              position: "left",
            },
          ],
        },
      };
    },
    pedidosHorasBarrasDatos() {
      var backgroundColor = this.ChartsBgColors;
      var borderColor = this.ChartsBorderColors;
      if (this.resumenCierre[this.tipoMostrar]) {
        let tramosHorarios = this.resumenCierre[
          this.tipoMostrar
        ].tramosHorarios.map((h) => h.nombre);

        return {
          labels: tramosHorarios,
          datasets: [
            {
              label: "Tarjeta Local",
              backgroundColor: backgroundColor[0],
              borderColor: borderColor[0],
              data: this.resumenCierre[this.tipoMostrar].tramosHorarios.map(
                (h) => h.tarjeta_local
              ),
            },
            {
              label: "Efectivo",
              backgroundColor: backgroundColor[1],
              data: this.resumenCierre[this.tipoMostrar].tramosHorarios.map(
                (h) => h.efectivo
              ),
            },
            {
              label: "Tarjeta online",
              backgroundColor: backgroundColor[2],
              data: this.resumenCierre[this.tipoMostrar].tramosHorarios.map(
                (h) => h.tarjeta_online
              ),
            },
          ],
        };
      } else {
        return;
      }
    },
    tipoMostrarItems() {
      return [
        { text: "Cobrados", value: "cobrados" },
        { text: "Pendientes", value: "pendientes" },
      ];
    },
    graficasMostrarItems() {
      return [
        { text: "Mostrar gráficas", value: "graficas" },
        { text: "Mostrar tablas", value: "tabla" },
      ];
    },
    totalEfectivo() {
      return this.resumenCierreArray[0].efectivo;
    },
    headers() {
      return [
        {
          text: "Modo",
          value: "nombre",
        },
        {
          text: "Número Pedidos",
          value: "numPedidos",
        },
        {
          text: "Total",
          value: "totalMostrar",
        },
        {
          text: "Efectivo",
          value: "efectivoMostrar",
        },
        {
          text: "Tarjeta online",
          value: "tarjeta_onlineMostrar",
        },
        {
          text: "Tarjeta domcilio",
          value: "tarjeta_domicilioMostrar",
        },
        {
          text: "Tarjeta local",
          value: "tarjeta_localMostrar",
        },
        {
          text: "En local",
          value: "localMostrar",
        },
        {
          text: "Otro",
          value: "otroMostrar",
        },
      ];
    },
    headers_() {
      return [
        { text: "-", value: "datos" },
        ...this.$store.state.datosTpv.modos.map((m) => {
          return {
            text: m.nombre.toUpperCase(),
            value: m.id,
          };
        }),
      ];
    },

    resumenCierreModo() {
      if (!this.resumenCierre.cobrados) return [];
      return [
        //this.resumenCierre[this.tipoMostrar].totales,
        ...Object.values(this.resumenCierre[this.tipoMostrar].modos),
        ...Object.values(this.resumenCierre[this.tipoMostrar].cuentas),
      ].map((p) => {
        return {
          ...p,
          totalMostrar: mostrarMoneda(p.total, true),
          efectivoMostrar: mostrarMoneda(p.efectivo, true),
          tarjeta_domicilioMostrar: mostrarMoneda(p.tarjeta_domicilio, true),
          tarjeta_onlineMostrar: mostrarMoneda(p.tarjeta_online, true),
          tarjeta_localMostrar: mostrarMoneda(p.tarjeta_local, true),
          localMostrar: mostrarMoneda(p.local, true),
          otroMostrar: mostrarMoneda(p.otro, true),
        };
      });
    },
    resumenCierreArray() {
      if (!this.resumenCierre.cobrados) return [];
      return [
        this.resumenCierre[this.tipoMostrar].totales,
        ...Object.values(this.resumenCierre[this.tipoMostrar].modos),
        ...Object.values(this.resumenCierre[this.tipoMostrar].cuentas),
      ].map((p) => {
        return {
          ...p,
          totalMostrar: mostrarMoneda(p.total, true),
          efectivoMostrar: mostrarMoneda(p.efectivo, true),
          tarjeta_domicilioMostrar: mostrarMoneda(p.tarjeta_domicilio, true),
          tarjeta_onlineMostrar: mostrarMoneda(p.tarjeta_online, true),
          tarjeta_localMostrar: mostrarMoneda(p.tarjeta_local, true),
          localMostrar: mostrarMoneda(p.local, true),
          otroMostrar: mostrarMoneda(p.otro, true),
        };
      });
    },
    resumenCierreFormasPago() {
      if (!this.resumenCierre.cobrados) return [];
      return [
        //this.resumenCierre[this.tipoMostrar].totales,
        {
          nombre: "EFECTIVO",
          total: this.resumenCierre[this.tipoMostrar].totales.efectivo,
          totalMostrar: mostrarMoneda(
            this.resumenCierre[this.tipoMostrar].totales.efectivo,
            true
          ),
        },
        {
          nombre: "TARJETA ONLINE",
          total: this.resumenCierre[this.tipoMostrar].totales.tarjeta_online,
          totalMostrar: mostrarMoneda(
            this.resumenCierre[this.tipoMostrar].totales.tarjeta_online,
            true
          ),
        },
        {
          nombre: "TARJETA DOMICILIO",
          total: this.resumenCierre[this.tipoMostrar].totales.tarjeta_domicilio,
          totalMostrar: mostrarMoneda(
            this.resumenCierre[this.tipoMostrar].totales.tarjeta_domicilio,
            true
          ),
        },
        {
          nombre: "TARJETA LOCAL",
          total: this.resumenCierre[this.tipoMostrar].totales.tarjeta_local,
          totalMostrar: mostrarMoneda(
            this.resumenCierre[this.tipoMostrar].totales.tarjeta_local,
            true
          ),
        },
        {
          nombre: "OTRO",
          total: this.resumenCierre[this.tipoMostrar].totales.otro,
          totalMostrar: mostrarMoneda(
            this.resumenCierre[this.tipoMostrar].totales.otro,
            true
          ),
        },
      ];
    },
    resumenCierre() {
      return this.$store.state.zcierreResumen;
    },
    zcierreFinDialog: {
      get() {
        return this.$store.state.zcierreFinDialog;
      },
      set(v) {
        this.$store.commit("setZCierreFinDialog", v);
      },
    },
    finMostrar() {
      return moment(this.horaActual).format("HH:mm");
    },
    inicioMostrar() {
      return moment.unix(this.zcierreDatosData.inicio).format("HH:mm");
    },
    cuentasMostrar() {
      return Array.isArray(this.zcierreDatosData.cuentas)
        ? this.zcierreDatosData.cuentas.map((c) => {
            let efectivoAuxDebe = parseFloat(c.efectivoFinDebe);
            let efectivoAuxHay = isNaN(parseFloat(c.efectivoFinHay))
              ? 0
              : parseFloat(c.efectivoFinHay);

            return {
              ...c,
              // efectivo: 0,
              efectivoFinDebeMostrar: mostrarMoneda(efectivoAuxDebe, true),
              efectivoFinHayMostrar: mostrarMoneda(efectivoAuxHay, true),
              efectivoMostrar: mostrarMoneda(c.efectivo, true),
            };
          })
        : [];
    },
    zcierreDialogMostrar() {
      return (
        this.zcierreDialog ||
        (this.configuracionTpv &&
          this.configuracionTpv.zcierreObligatorio &&
          !this.zcierre)
      );
    },
    ...mapState([
      "datosTpv",
      "horaActual",
      "zcierre",
      "zcierreDatos",
      "configuracionTpv",
    ]),
  },
  methods: {
    calcularCierre() {
      this.$store.dispatch("calcularCierre").then((r) => {
        this.zcierreDatosData = {
          ...this.zcierreDatos,
          cuentas: this.zcierreDatos.cuentas
            ? this.zcierreDatos.cuentas.map((z) => {
                if (!z.marcaCaja) {
                  //no tiene cajón efectivo
                }
                /*let f = this.resumenCierreArray.find(
                  (p) => !!p.id && p.id == z.email
                );*/

                let fI = this.resumenCierreArray.findIndex(
                  (resumen) => resumen.id === z.email
                );

                //this.resumenCierreArray[fI] es la cuenta iterando, y this.resumenCierreArray[0] son los totales
                return {
                  ...z,
                  efectivoFinDebe:
                    z.marcaCaja && fI > -1
                      ? this.resumenCierreArray[0].efectivo +
                        (z.efectivo ? z.efectivo : 0)
                      : 0, //al total le rrestamos las salidias en efectivo
                  efectivoFinHay: 0,
                  /*efectivoFinDebe:
                    (z.efectivo ? z.efectivo : 0) + (f ? f.efectivo : 0),
                    */
                };
              })
            : [],
        };
      });
    },
    cambiarSeleccion(id) {
      this.campoCambiando = id;
      this.$refs.keyboardRefNumerico.reset(); //pone a 0
    },
    onKeyPress(button) {
      if (button == "C") {
        this.$refs.keyboardRefNumerico.reset(); //pone a 0
        this.onChange(0);
      }
    },
    onChange(input) {
      if (!!this.campoCambiando) {
        let id = this.campoCambiando.substring(
          this.campoCambiando.indexOf("_") + 1,
          this.campoCambiando.length
        );

        let fEfectivo = this.zcierreDatosData.cuentas.find((c) => c.id == id);

        if (fEfectivo) {
          fEfectivo.efectivoFinHay = parseFloat(input / 100);
        }
      }
      // if (
      //   this.campoCambiando == "precio" ||
      //   this.campoCambiando == "precioBase"
      // )
      //   input = mostrarMoneda(parseInt(input) / 100);
      // this.nuevoProducto[this.nuevoProductoCampoCambiando] = input;
      // if (isFloat(parseFloat(this.inputTecladoNumerico)))
      //   this.inputTecladoNumerico = "0";
      // if (input.toString().charAt(input.length - 1) == "C") {
      //   this.inputTecladoNumerico = "0";
      // } else {
      //   this.inputTecladoNumerico = input;
      // }
      // if (isFloat(parseFloat(this.inputKeyboard))) this.inputKeyboard = "0";
    },
    inicioDia() {
      this.$store.dispatch("inicioZCierre", {
        cuentas: this.cuentas.map((c) => {
          return {
            efectivo: c.efectivo,
            id: c.id,
            nombre: c.nombre,
          };
        }),
        inicio: moment(this.$store.state.horaActual).unix(),
      });
    },

    finDiaConfirmar() {
      this.finDiaConfirmarLoading = true;

      setTimeout(() => {
        var cabecera;
        var modosDonut;
        var formasPagoDonut;
        var productosMasVendidosDonut;
        var categoriasMasVendidosDonut;
        var pedidosHorasBarras;
        var modosTabla;
        var formasPagoTabla;
        var storageRef;
        let promesas = [
          html2canvas(document.querySelector("#cabecera")),
          html2canvas(document.querySelector("#modosDonut")),
          html2canvas(document.querySelector("#formasPagoDonut")),
          html2canvas(document.querySelector("#productosMasVendidosDonut")),
          html2canvas(document.querySelector("#categoriasMasVendidosDonut")),
          html2canvas(document.querySelector("#pedidosHorasBarras")),
          html2canvas(document.querySelector("#modosTabla")),
          html2canvas(document.querySelector("#formasPagoTabla")),
        ];
        return Promise.all(promesas)
          .then((r) => {
            cabecera = r[0].toDataURL();
            modosDonut = r[1].toDataURL();
            formasPagoDonut = r[2].toDataURL();
            productosMasVendidosDonut = r[3].toDataURL();
            categoriasMasVendidosDonut = r[4].toDataURL();
            pedidosHorasBarras = r[5].toDataURL();
            modosTabla = r[6].toDataURL();
            formasPagoTabla = r[7].toDataURL();

            var centeredText = function (text, y) {
              var textWidth =
                (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
              doc.text(textOffset, y, text);
            };
            var doc = new jsPDF();
            const width = doc.internal.pageSize.getWidth();

            var img = new Image();
            img.src = require("@/assets/logof.png");
            doc.setFontSize(20);
            doc.addImage(img, "PNG", width / 2 - 40, 10, 80, 15);
            centeredText(
              "CIERRE " +
                this.$store.state.negocioObj.nombre.toUpperCase() +
                " " +
                this.$store.state.restauranteObj.nombre.toUpperCase(),
              38
            );
            doc.addImage(cabecera, 0, 45, width, 20);
            doc.addImage(modosDonut, 5, 80, 80, 80);
            doc.addImage(
              modosTabla,
              width / 2 - 15,
              80,
              120,
              8 * this.resumenCierreModo.length
            );
            doc.addImage(formasPagoDonut, 5, 180, 80, 80);
            doc.addImage(
              formasPagoTabla,
              width / 2 - 15,
              180,
              120,
              10 * this.resumenCierreFormasPago.length
            );
            let destino =
              "usuariosTpv/" +
              this.$store.state.restauranteObj.id +
              "/" +
              this.$store.state.auth.user.uid +
              "/zcierres/" +
              this.$store.state.zcierre.toString() +
              ".pdf";

            storageRef = storage.ref(destino);
            return storageRef.put(doc.output("arraybuffer"));
          })
          .then(() => storageRef.getDownloadURL())
          .then((fichero) => {
            this.$store.dispatch("imprimirz");
            return this.$store.dispatch("finZCierre", {
              ...this.zcierreDatosData,
              resumen: this.resumenCierre,
              estado: "cerrado",
              ficheroCierre: fichero,
            });
          })
          .then(() => {
            this.zcierreFinDialog = false;
          })
          .finally(() => (this.finDiaConfirmarLoading = false));
      }, 200);
    },
  },
  mounted() {},
};
</script>

<style>
</style>