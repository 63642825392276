<template>
  <v-row class="pa-2 pa-md-0">
    <v-col
      v-for="mesa in mesasMostrar"
      :key="mesa.id"
      class="pa-2 pa-md-3"
      cols="6"
      sm="4"
      md="3"
    >
      <mesa-card :mesa="mesa" />
    </v-col>
  </v-row>
</template>

<script>
var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
import MesaCard from "../components/MesaCard.vue";
import MesaCardLibre from "../components/MesaCardLibre.vue";
export default {
  components: {
    MesaCard,
    MesaCardLibre,
  },
  data() {
    return {
      modoMesaSeleccionado: null,
    };
  },
  computed: {
    modosMesas() {
      return this.$store.state.datosTpv.modos.map((m) => {
        return m;
      });
    },
    mesas() {
      return this.$store.state.datosTpv.mesas;
    },
    mesasMostrar() {
      return this.$store.state.datosTpv.mesas
        .filter((m) => m.modo == this.modoMesaSeleccionado)
        .map((m) => {
          return m;
        });
    },
  },
  methods: {
    inicializar() {
      if (this.$route.params.modomesa) {
        this.modoMesaSeleccionado = this.$route.params.modomesa;
      } else {
        this.$router.push("/");
      }
    },
  },
  created() {
    this.inicializar();
  },
  updated() {
    console.log("updated");
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.modoMesaSeleccionado = to.params.modomesa;
    // this.inicializar();

    next();
  },
};
</script>

<style>
</style>