export default {
    setUsuarios(state, u) {
        state.usuarios = u;
    },
    setUsuarioActual(state, obj) {
        if (!!obj) {
            state.usuarioActual = obj.id;
            state.usuarioActualObj = obj;
            localStorage.setItem('usuarioTpv', obj.id);
        } else {
            state.usuarioActual = null;
            localStorage.removeItem('usuarioTpv')
        }

    },
    // resetUsuarioActual(state){
    //     state.usuarioActual = null;
    //     state.usuarioActualObj = obj;
    //     localStorage.setItem('usuarioTpv', obj.id);
    // },
    setEleccionUsuarioDialog(state, b) {
        state.eleccionUsuarioDialog = b;
    }
}