// https://vuex.vuejs.org/en/mutations.html

export default {
  setModoFuncionamiento(state, m) {
    state.modoFuncionamiento = m
  },
  setLoaded(state, b) {
    state.loaded = b;
  },
  setRestauranteObj(state, rest) {
    state.restauranteObj = rest;
  },
  setNegocioObj(state, neg) {
    state.negocioObj = neg;
  },
  setTabNavegacionModos(state, i) {
    state.tabNavegacionModos = i;
  },
  setTiempoDomicilio(state, v) {
    state.tiempoDomicilio = v;
  },
  setTiempoRecogida(state, v) {
    state.tiempoRecogida = v;
  },
  setRecogidaActivada(state, v) {
    state.recogidaActivada = v;
  },
  setDomicilioActivado(state, v) {
    state.domicilioActivado = v;
  },
  setModoDesarrollo(state, modo) {
    state.modoDesarrollo = modo;
  },
  setImpuestos(state, imp) {
    state.impuestos = imp;
  },
  setDatosTpv(state, payload) {
    state.datosTpv[payload.key] = payload.value;
  },
  setConfiguracionTpv(state, obj) {
    state.configuracionTpv = obj;
  },
  setPedidoMesa(state, payload) {
    var datos = JSON.parse(JSON.stringify(state.datosTpv))
    let fI = datos.planos.plano1.findIndex(p => p.id == payload.mesa);
    if (fI > -1) {
      // console.log(state.datosTpv.planos.plano1[fI])
      datos.planos.plano1[fI].pedido = payload.pedido
      state.datosTpv = datos;
    }
  },
  setMostrarFabAdd(state, b) {
    state.mostrarFabAdd = b;
  },
  setCargandoLazySnack(state, b) {
    state.cargandoLazySnack = b;
  },
  setHoraActual(state, i) {
    state.horaActual = i;
  },
  setOnline(state, b) {
    state.online = b;
  },
  setUniqueId(state, i) {
    state.uniqueId = i;
    localStorage.setItem('uniqueId', i)
  },
  setOrdenadorConf(state, c) {
    state.ordenadorConf = c
  },
  setOrdenadorKey(state, payload) {
    state.ordenadorConf[payload.key] = payload.value
  },
  setAbrirCalendario(state, b) {
    state.abrirCalendario = b;
  },
  setAbrirInfoCliente(state, b) {
    state.abrirInfoCliente = b;
  },
  // setMostrarTeclado(state, v) {
  //   state.mostrarTeclado = v;
  // },
  // setDatosTpvUsuarios(state, datos) {
  //   state.datosTpvUsuarios = datos;
  // }
  //
  setNecesarioTeclado(state, b) {
    state.necesarioTeclado = b;
  },
  setBajaCalidad(state, b) {
    state.bajaCalidad = b;
  },
  //PAGINACIONES
  setModoPaginacion(state, b) {
    state.modoPaginacion = b;
  },
  setPaginacionManejando(state, s) {
    state.paginacionManejando = s;
  },
  setMaximoPaginacionPedidos(state, i) {
    state.maximoPaginacionPedidos = i;
  },
  setMostrandoPaginacionPedidos(state, i) {
    state.mostrandoPaginacionPedidos = i;
  },
  setPedidosPaginacionPedidos(state, i) {
    state.pedidosPaginacionPedidos = i;
  },
  setPaginaPaginacionPedidos(state, i) {
    state.paginaPaginacionPedidos = i
  },
  setMaximoPaginacionPedidos(state, i) {
    state.maximoPaginacionPedidos = i;
  },
  setMostrandoPaginacionPedidos(state, i) {
    state.mostrandoPaginacionPedidos = i;
  },
  setPedidosPaginacionPedidos(state, i) {
    state.pedidosPaginacionPedidos = i;
  },
  setPaginaPaginacionPedidos(state, i) {
    state.paginaPaginacionPedidos = i
  },
  setMaximoPaginacionCategorias(state, i) {
    state.maximoPaginacionCategorias = i;
  },
  setMostrandoPaginacionCategorias(state, i) {
    state.mostrandoPaginacionCategorias = i;
  },
  setCategoriasPaginacionCategorias(state, i) {
    state.categoriasPaginacionCategorias = i;
  },
  setPaginaPaginacionCategorias(state, i) {
    state.paginaPaginacionCategorias = i
  },
  setMaximoPaginacionProductos(state, i) {
    state.maximoPaginacionProductos = i;
  },
  setMostrandoPaginacionProductos(state, i) {
    state.mostrandoPaginacionProductos = i;
  },
  setCategoriasPaginacionProductos(state, i) {
    state.categoriasPaginacionProductos = i;
  },
  setPaginaPaginacionProductos(state, i) {
    state.paginaPaginacionProductos = i
  },
  //listado productos
  setMaximoPaginacionProductosListado(state, i) {
    state.maximoPaginacionProductosListado = i;
  },
  setMostrandoPaginacionProductosListado(state, i) {
    state.mostrandoPaginacionProductosListado = i;
  },
  setCategoriasPaginacionProductosListado(state, i) {
    state.categoriasPaginacionProductosListado = i;
  },
  setPaginaPaginacionProductosListado(state, i) {
    state.paginaPaginacionProductosListado = i
  },
  //Pistola
  setPistolaLeyendo(state, b) {
    state.pistolaLeyendo = b;
  },
  setPistolaTexto(state, s) {
    state.pistolaTexto = s;
  },
  setPistolaTextoDefinitivo(state, s) {
    state.pistolaTextoDefinitivo = s;
  },
  //producto info pistola
  setInfoProductoDialogo(state, b) {
    state.infoProductoDialogo = b;
  },
  setInfoProductoObj(state, obj) {
    state.infoProductoObj = obj;
  },

  //CIRRES
  setZCierre(state, z) {
    state.zcierre = z;
  },
  setZCierreFinDialog(state, b) {
    state.zcierreFinDialog = b
  },
  setZCierreDatos(state, obj) {
    state.zcierreDatos = obj
  },
  setZCierreResumen(state, a) {
    state.zcierreResumen = a
  },
  setSalidaDineroDialog(state, b) {
    state.salidaDineroDialog = b
  },
  //ACTUALIZACIONES
  setActualizacionDialog(state, b) {
    state.actualizacionDialog = b;
  },
  setConfiguracionTpvGeneral(state, obj) {
    state.configuracionTpvGeneral = obj;
  },
  //NAVEGACIÓN
  setTab(state, i) {
    state.tab = i;
  },
  setTabMenu(state) {
    state.tab = null;
  }



}
