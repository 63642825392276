var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3"},on:{"click":function($event){return _vm.$store.commit('setAbrirCalendario', true)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","outlined":"","color":hover ? 'grey lighten-4' : 'white'}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":"1.618"}},[_c('v-list-item-content',[_c('div',{staticClass:"text-center pb-6"},[_c('v-icon',{staticClass:"my-4",attrs:{"color":"teal darken-4","size":_vm.$vuetify.breakpoint.mdAndDown ? '35' : '45'}},[_vm._v("mdi-calendar")]),_c('div',{staticClass:"\n                  custom-badge\n                  calendario-badge\n                  primary\n                  elevation-3\n                  d-flex\n                  align-center\n                  justify-center\n                  font-weight-bold\n                "},[_vm._v(" "+_vm._s(_vm.$store.getters[ "calendario/numeroReservasNoConfirmadas" ].toString())+" ")])],1)]),_c('v-card-actions',{staticClass:"grey lighten-4 acciones-footer acciones--border-top"},[_c('span',{staticClass:"\n                text-uppercase\n                mx-auto\n                font-weight-bold\n                teal--text\n                text--darken-4\n                subtitle-2\n              "},[_vm._v("Reservas")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"3"},on:{"click":function($event){return _vm.$router.push('/comanderos')}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","outlined":"","color":hover ? 'grey lighten-4' : 'white'}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":"1.618"}},[_c('v-list-item-content',[_c('div',{staticClass:"text-center pb-6"},[_c('v-icon',{staticClass:"my-4",attrs:{"color":"teal darken-4","size":_vm.$vuetify.breakpoint.mdAndDown ? '35' : '45'}},[_vm._v("mdi-cellphone-text")])],1)]),_c('v-card-actions',{staticClass:"grey lighten-4 acciones-footer acciones--border-top"},[_c('span',{staticClass:"\n                text-uppercase\n                mx-auto\n                font-weight-bold\n                teal--text\n                text--darken-4\n                subtitle-2\n              "},[_vm._v("Comanderos")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",style:(hover ? 'cursor: pointer' : ''),attrs:{"disabled":"","elevation":"4","outlined":"","color":hover ? 'grey lighten-4' : 'white'}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":"1.618"}},[_c('v-list-item-content',[_c('div',{staticClass:"text-center pb-6"},[_c('v-icon',{staticClass:"my-4",attrs:{"color":"teal darken-4","size":_vm.$vuetify.breakpoint.mdAndDown ? '35' : '45'}},[_vm._v("mdi-food")])],1)]),_c('v-card-actions',{staticClass:"grey lighten-4 acciones-footer acciones--border-top"},[_c('span',{staticClass:"\n                text-uppercase\n                mx-auto\n                font-weight-bold\n                teal--text\n                text--darken-4\n                subtitle-2\n              "},[_vm._v("Productos")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"3"},on:{"click":function($event){_vm.$router.push(
        '/pedidos-internos'
      ) /*$store.commit('internos/SET_INTERNOS_DIALOG', true)*/}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","outlined":"","color":hover ? 'grey lighten-4' : 'white'}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":"1.618"}},[_c('v-list-item-content',[_c('div',{staticClass:"text-center pb-6"},[_c('v-icon',{staticClass:"my-4",attrs:{"color":"teal darken-4","size":_vm.$vuetify.breakpoint.mdAndDown ? '35' : '45'}},[_vm._v("mdi-cart-outline")])],1)]),_c('v-card-actions',{staticClass:"grey lighten-4 acciones-footer acciones--border-top"},[_c('span',{staticClass:"\n                text-uppercase\n                mx-auto\n                font-weight-bold\n                teal--text\n                text--darken-4\n                subtitle-2\n              "},[_vm._v("Pedidos Internos")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"3"},on:{"click":function($event){return _vm.$router.push('/notificaciones')}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","outlined":"","color":hover ? 'grey lighten-4' : 'white'}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":"1.618"}},[_c('v-list-item-content',[_c('div',{staticClass:"text-center pb-6"},[_c('v-icon',{staticClass:"my-4",attrs:{"color":"teal darken-4","size":_vm.$vuetify.breakpoint.mdAndDown ? '35' : '45'}},[_vm._v("mdi-bullhorn-outline")])],1)]),_c('v-card-actions',{staticClass:"grey lighten-4 acciones-footer acciones--border-top"},[_c('span',{staticClass:"\n                text-uppercase\n                mx-auto\n                font-weight-bold\n                teal--text\n                text--darken-4\n                subtitle-2\n              "},[_vm._v("Notificaciones")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","outlined":"","color":hover ? 'red darken-2' : 'red'},on:{"click":_vm.logout}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":"1.618"}},[_c('v-list-item-content',[_c('div',{staticClass:"text-center pb-6"},[_c('v-icon',{staticClass:"my-4",attrs:{"color":"white","size":_vm.$vuetify.breakpoint.mdAndDown ? '35' : '45'}},[_vm._v("mdi-power")])],1)]),_c('v-card-actions',{staticClass:"red darken-2 acciones-footer acciones--border-top"},[_c('span',{staticClass:"\n                text-uppercase\n                mx-auto\n                font-weight-bold\n                white--text\n                subtitle-2\n              "},[_vm._v("Desconectar")])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }