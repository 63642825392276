export default {
    mostrarTeclado: false,
    zindex: 0,
    dialogosConTeclado: [
        'direccionesFormDialog',
        'editarDialog'
    ],
    campoEditarObj: {},
    campoEditarPropiedad: '',
    actualValor: '',
    limpiarInput: false,
    input: '',
    vaDividir: false, //cuando pulsa el botón de dividir la cuenta
    cobroPorProductos: false,
    lanzarNumerico: null
}