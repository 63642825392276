// https://vuex.vuejs.org/en/actions.html
import firebase from 'firebase'
import { db, storage } from '@/firebase'
import moment from 'moment'
import { key, resetLastTimestamp } from "firebase-key";



import { zcierre, abrirCaja } from '../utils/imprimir.utils';
import { parDiasComparar } from '@/utils/dias.utils.js'



export default {
  inicioTpvAlternativo({ commit, rootState, state, dispatch, rootGetters }) {

    var p1 = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv)
      .onSnapshot((r) => {
        if (r.exists) {
          var rest = r.data();
          rest.id = r.id;
          const domicilio = (rest.tiempoDomicilio) ? rest.tiempoDomicilio : 0;
          const recogida = (rest.tiempoRecogida) ? rest.tiempoRecogida : 0;
          const modoHorarioRecoger = (rest.modoHorarioRecoger) ? (rest.modoHorarioRecoger == 'auto') : false;
          const modoHorarioDomicilio = (rest.modoHorarioDomicilio) ? (rest.modoHorarioDomicilio == 'auto') : false;
          const flag = (state.restauranteObj == null);//para llamar a toda la info
          commit('setTiempoDomicilio', domicilio);
          commit('setTiempoRecogida', recogida);
          commit('setRecogidaActivada', modoHorarioRecoger);
          commit('setDomicilioActivado', modoHorarioDomicilio);
          commit('setRestauranteObj', rest);
          if (flag) {
            dispatch('getConfiguracionTpv');
            dispatch('getAccionesTpv');
            if (rootState.auth.user.role == 'tpv')
              dispatch('calendario/getReservasSinConfirmar', {}, { root: true })
            setTimeout(() => {
              dispatch('pedidos/getPedidos', {}, { root: true })
              dispatch('pedidos/getPedidosRealizandoTpv', {}, { root: true })
              dispatch('pedidos/getNumPedidoRest', {}, { root: true })

            }, 2000)

          }
        } else {
          commit('setRestauranteObj', false); //no existe restaurante o no tiene 
        }
      })

    var p2 = db.collection('negocios').doc(rootState.auth.user.negocioTpv)
      .onSnapshot((n) => {
        if (n.exists) {
          var neg = n.data();
          neg.id = n.id;
          commit('setNegocioObj', neg);
        }
      })
    var mesasTpv = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('secreto').collection('mesas')
      .onSnapshot(r => {
        // r.docs.map(d => {
        commit('setDatosTpv', {
          key: 'mesas',
          value: r.size > 0 ? r.docs.map(m => {
            return {
              ...m.data(),
              id: m.id
            }
          }).sort((a, b) => (a.orden > b.orden) ? 1 : ((b.orden > a.orden) ? -1 : 0)) : []
        })
        // })
      })
    var modosTpv = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('secreto').collection('modos')
      .get().then(r => {
        // r.docs.map(d => {
        commit('setDatosTpv', {
          key: 'modos',
          value: r.size > 0 ? r.docs.map(m => {
            return {
              ...m.data(),
              id: m.id
            }
          }).sort((a, b) => (a.orden > b.orden) ? 1 : ((b.orden > a.orden) ? -1 : 0)) : []
        })
        // })
      })
    var cuentasTpv = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('secreto').collection('cuentas')
      .get().then(r => {
        // r.docs.map(d => {
        commit('setDatosTpv', {
          key: 'cuentas',
          value: r.size > 0 ? r.docs.map(m => {
            return {
              ...m.data(),
              id: m.id
            }
          }).sort((a, b) => (a.orden > b.orden) ? 1 : ((b.orden > a.orden) ? -1 : 0)) : []
        })
        if (rootGetters['impresionLocal']) {
          setTimeout(() => {
            dispatch('pedidos/getPedidosPendienteImprimir', {}, { root: true })
          }, 2000)
        }
        db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('secreto').collection('cuentas').doc(rootGetters['cuenta'].id).set({
          version: state.version
        }, { merge: true })
        commit('setModoPaginacion', rootGetters['cuenta'].paginacion)
        commit('setNecesarioTeclado', rootGetters['cuenta'].tecladoAsistido)
        commit('setBajaCalidad', rootGetters['cuenta'].bajaCalidad)


        // })
      })
      .catch(e => console.log(e))
    var dispositivosTpv = dispatch('dispositivosTpv')
    var qz = Promise.resolve();//dispatch('qz/conectar')
    var datosTpv = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').get()
      .then(r => {
        r.docs.map(d => {
          commit('setDatosTpv', {
            key: d.id,
            value: d.data()
          })
        })
        // commit('setDatosTpv', r.docs.map(d => {
        //   return {
        //     ...d.data(),
        //     id: d.id
        //   }
        // }))
      })
    var datosTpvUsuarios = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv')
      .doc('secreto').collection('usuarios')
      .where('activo', '==', true).get()
      .then(r => {
        commit('usuarios/setUsuarios', r.docs.map(d => {
          return {
            ...d.data(),
            id: d.id
          }
        }), { root: true })
        let user = localStorage.getItem('usuarioTpv')
        if (!!user) {
          dispatch('usuarios/setUsuarioActual', user, { root: true })
        }
      })

    //obtenemos versión servidor
    db.collection('configuracion').doc('tpv')
      .onSnapshot((r) => {
        if (r.exists) {
          console.log(r.data());
          commit('setConfiguracionTpvGeneral', r.data())
        }
      })
    if (rootState.auth.user.role == 'tpv')
      var not = dispatch('notificaciones/getNotificaciones');
    // var prod = dispatch('productos/getTodosCatalogos')
    // var cat = dispatch('productos/getCategorias')
    var imp = dispatch('getImpuestos')
    return Promise.all([p1, p2, not, imp, datosTpv, mesasTpv, modosTpv, datosTpvUsuarios, dispositivosTpv, cuentasTpv, qz]).then(() => {
      return
    })
      .catch((e) => { console.log(e); commit('setRestauranteObj', false) })
  },
  getConfiguracionTpv({ rootState, commit, dispatch }) {
    db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('configuracion')
      .onSnapshot((n) => {
        if (n.exists) {
          var conf = n.data();
          commit('setConfiguracionTpv', conf);
          console.log(conf);
          if (!!conf.cierreEnCurso && conf.zcierreObligatorio) {
            commit('setZCierre', conf.cierreEnCurso.toString())
            dispatch('getZCierre', conf.cierreEnCurso)
              .then((r) => {
                if (!!r) {
                  commit('setZCierreDatos', r)
                }
              })
          } else if (conf.cierreEnCurso === null) {
            commit('setZCierre', null)
          }
        }
      })
  },
  getAccionesTpv({ rootState, rootGetters, state, commit, dispatch }) {
    db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('acciones')
      .onSnapshot(r => {

        if (r.exists) {
          var acciones = r.data();

          if (acciones.reiniciar) {
            db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv)
              .collection('tpv').doc('acciones')
              .set({
                reiniciar: false
              }, { merge: true }).then(() => location.reload())
          }

          if (!!acciones.comprobarConexion) {
            if (acciones.comprobarConexion == rootState.auth.user.uid) {

              var userStatusDatabaseRef = firebase.database().ref('/status/' + rootState.auth.user.uid);

              var isOnlineForDatabase = {
                uid: rootState.auth.user.uid,
                state: 'online',
                rest: rootGetters.cuenta.marcaConectado ? state.restauranteObj.id : null,
                last_changed: firebase.database.ServerValue.TIMESTAMP,
              };
              userStatusDatabaseRef.set(isOnlineForDatabase)
                .then(() => {
                  db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv)
                    .collection('tpv').doc('acciones')
                    .set({
                      comprobarConexion: null
                    }, { merge: true })
                })
            }
          }
        }

      })
  },
  getUniqueId({ commit }) {
    let id = localStorage.getItem('uniqueId');
    let nuevo = false;
    if (!id) {
      id = key();
      nuevo = true;
    }
    commit('setUniqueId', !!id ? id : null)
    return nuevo;
  },
  dispositivosTpv({ rootState, commit, getters, state }) {
    db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv').doc('secreto').collection('dispositivos')
      .get().then(r => {
        // r.docs.map(d => {
        var dispositivos = r.size > 0 ? r.docs.map(m => {
          return {
            ...m.data(),
            id: m.id
          }
        }) : [];
        commit('setDatosTpv', {
          key: 'dispositivosTpv',
          value: dispositivos
        })
        var impresoras = getters['impresoras']
        // var ordenadores = getters['ordenadores']
        // console.log(ordenadores);
        // console.log(state.uniqueId);
        // let f = ordenadores.find(o => o.id == state.uniqueId)
        // console.log(f)
        // commit('setOrdenadorConf', f ? f : null)

        var tickets = getters['tickets']
        db.collection('negocios').doc(rootState.auth.user.negocioTpv)
          .collection('tickets')
          .get().then((r) => {
            const a = r.docs.map(doc => {
              var aux = doc.data();
              aux.id = doc.id;
              return aux;
            });

            commit('setDatosTpv', {
              key: 'tickets',
              value: a.filter(t => {
                return tickets.map(t => t.ticket).includes(t.id)
              }).map(t => {
                let fTicket = tickets.find(tAux => tAux.ticket == t.id)
                return {
                  ...(fTicket ? fTicket : {}),
                  ...t,
                }
              })
            });
            //descargamoos los logos caja si hay
            function getBase64Image(img) {

              var canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;
              var ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              var dataURL = canvas.toDataURL();
              return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            }


            var negocio = rootState.auth.user.negocioTpv

            let logosDescargar = a.filter(t => (t.tipo == 'caja2' || t.tipo == 'caja1') && !!t.logo).map(t => {
              let img = new Image();
              img.crossOrigin = "anonymous"
              img.addEventListener('load', () => {
                commit('impresion/pushLogosCaja', {
                  ticket: t.id,
                  datos: getBase64Image(img),
                })
              })
              img.src = t.logo
            })
          })
        // })
      })
  },
  cargaDespuesInicio({ dispatch, rootState }) {
    var p = [];
    if (rootState.auth.user.role == 'tpv') {
      p.push([
        dispatch('internos/getCategorias', {}, { root: true }),
        dispatch('internos/getProductos', {}, { root: true }),
        dispatch('directorio/getDirectorio', {}, { root: true })
      ])
    }
    return Promise.all(p)
      .then(() => {
        dispatch('cargaLazy').catch(e => console.log(e))
        if (rootState.directorio.directorioMatriz && rootState.auth.user.role == 'tpv')
          dispatch('pedidos/getPedidosDirectorioMatriz', {}, { root: true })
      })
      .catch(e => { console.log(e) })
  },
  cargaLazy({ commit, dispatch, state }) {//carga datos lazy por que son datos pesados, muestra en la tabla loading
    commit('setCargandoLazySnack', true);
    // dispatch('clientes/getClientes').then((r) => console.log(r)).catch(e => console.log(e))
    return Promise.all(state.cargaLazy.map(c => {
      c.loading = true;
      return dispatch(c.accion).then(() => c.loading = false).catch(e => console.log(e))
    })).finally(() => commit('setCargandoLazySnack', false))
  },
  inicioTpv({ commit }) {
    var f = firebase.functions().httpsCallable('httpTpvCargaInicial');
    return f().then((r) => {
      const rest = r.data[0];
      const domicilio = (rest.tiempoDomicilio) ? rest.tiempoDomicilio : 0;
      const recogida = (rest.tiempoRecogida) ? rest.tiempoRecogida : 0;
      const modoHorarioRecoger = (rest.modoHorarioRecoger) ? (rest.modoHorarioRecoger == 'auto') : false;
      const modoHorarioDomicilio = (rest.modoHorarioDomicilio) ? (rest.modoHorarioDomicilio == 'auto') : false;
      commit('setRestauranteObj', r.data[0]);
      commit('setNegocioObj', r.data[1]);
      commit('setTiempoDomicilio', domicilio);
      commit('setTiempoRecogida', recogida);
      commit('setRecogidaActivada', modoHorarioRecoger);
      commit('setDomicilioActivado', modoHorarioDomicilio);
    })
  },
  setTiempoDomicilio({ rootState, state, commit }, t) {
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          tiempoDomicilio: t
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setTiempoDomicilio', t) })

    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        tiempoDomicilio: t
      }, { merge: true })
        .then(() => { commit('setTiempoDomicilio', t) })
    }
  },
  setTiempoRecogida({ state, commit, rootState }, t) {
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          tiempoRecogida: t
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setTiempoRecogida', t) })

    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        tiempoRecogida: t
      }, { merge: true })
        .then(() => { commit('setTiempoRecogida', t) })
    }

  },
  setRecogidaActivada({ rootState, state, commit }, { v, t }) {
    let tiempo = (t) ? moment().unix() + (t * 60) : null
    const modoHorarioRecoger = (v) ? 'auto' : 'forzado_cierre';

    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          modoHorarioRecoger: modoHorarioRecoger,
          modoHorarioRecogerTiempo: tiempo
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setRecogidaActivada', t) })
    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        modoHorarioRecoger: modoHorarioRecoger,
        modoHorarioRecogerTiempo: tiempo
      }, { merge: true })
        .then(() => { commit('setRecogidaActivada', t) })
    }
  },
  setDomicilioActivado({ rootState, state, commit }, { v, t }) {
    let tiempo = (t) ? moment().unix() + (t * 60) : null
    const modoHorarioDomicilio = (v) ? 'auto' : 'forzado_cierre';
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          modoHorarioDomicilio: modoHorarioDomicilio,
          modoHorarioDomicilioTiempo: tiempo
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setDomicilioActivado', t) })
    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        modoHorarioDomicilio: modoHorarioDomicilio,
        modoHorarioDomicilioTiempo: tiempo

      }, { merge: true })
        .then(() => { commit('setDomicilioActivado', t) })
    }
  },
  imprimirz({ state, rootGetters, rootState, commit }) {
    // console.log(rootGetters['pedidos/pedidos']);
    const datosZFichero = zcierre(rootGetters['pedidos/pedidos'].filter(p => !!p.cobrado), state.zcierreResumen, state.restauranteObj)
    var ticketsImp = rootState.datosTpv.tickets.filter(t => {
      return t.tipo == 'caja2' || t.tipo == 'caja1'
    })
    if (ticketsImp.length > 0) {
      commit('impresion/addTrabajoPorImprimir', {
        id: 'zcierre' + '_' + moment().unix(),
        fecha: moment().unix(),
        nombre: 'CIERRE Z ',
        datos: datosZFichero,
        dispositivo: ticketsImp[0]
      }, { root: true })
    }
  },
  imprimirzAyer({ state, rootGetters, rootState, commit, dispatch }, { pedidos, fecha }) {
    // console.log(rootGetters['pedidos/pedidos']);

    const datosZFichero = zcierre(pedidos.filter(p => !!p.cobrado && !p.eliminado), state.zcierreResumen, state.restauranteObj, fecha)
    var ticketsImp = rootState.datosTpv.tickets.filter(t => {
      return t.tipo == 'caja2' || t.tipo == 'caja1'
    })
    if (ticketsImp.length > 0) {
      console.log("imprimir");
      commit('impresion/addTrabajoPorImprimir', {
        id: 'zcierre' + '_' + moment().unix(),
        fecha: moment().unix(),
        nombre: 'CIERRE Z ',
        datos: datosZFichero,
        dispositivo: ticketsImp[0]
      }, { root: true })
    }
  },
  abrirCajon({ state, rootGetters, rootState, dispatch, commit }) {
    // console.log(rootGetters['pedidos/pedidos']);
    const abrirCajaDatos = abrirCaja()//(rootGetters['pedidos/pedidos'], state.restauranteObj)
    var ticketsImp = rootState.datosTpv.tickets.filter(t => {
      return t.tipo == 'caja2' || t.tipo == 'caja1'
    })

    if (ticketsImp.length > 0) {
      commit('impresion/addTrabajoPorImprimir', {
        id: 'abrir_caja' + '_' + moment().unix(),
        fecha: moment().unix(),
        nombre: 'ABRIR CAJÓN ',
        datos: abrirCajaDatos,
        dispositivo: ticketsImp[0]
      }, { root: true })
      /*return dispatch('configuracion/enviarDatosDispositivo', {
        datos: abrirCajaDatos,
        dispositivo: ticketsImp[0]
      }, { root: true })*/
    }
  },
  getImpuestos({ commit }) {
    let devolver = [];
    return db.collection('impuestos').get()
      .then((res) => {
        res.forEach(doc => {
          let aux = doc.data();
          aux.id = doc.id;
          devolver.push(aux);
        });

        commit('setImpuestos', devolver)
      })
  },
  firestoreConexion({ }, disabled = true) {
    if (disabled) {
      return firebase.firestore().disableNetwork()
        .then(() => {
          // Do offline actions
          // ...
        });
    } else {
      return firebase.firestore().enableNetwork()
        .then(() => {
          // Do offline actions
          // ...
        });
    }
  },
  reiniciar({ }) {
    window.location.reload()
  },
  //PAGINACIÓN
  cambiarPagina({ state, commit }, i) {
    switch (state.paginacionManejando) {
      case 'pedidos':
        commit('setPaginaPaginacionPedidos', state.paginaPaginacionPedidos + i)
        break;
      case 'categorias':
        commit('setPaginaPaginacionCategorias', state.paginaPaginacionCategorias + i)
        break;
      case 'productos':
        commit('setPaginaPaginacionProductos', state.paginaPaginacionProductos + i)
        break;
      case 'productosListado':
        commit('setPaginaPaginacionProductosListado', state.paginaPaginacionProductosListado + i)
        break;
    }
  },
  //AUDIO
  reproducirSonido() {
    var audio = new Audio('audio_file.mp3');
    audio.play();
  },
  //ZCIERRE
  inicioZCierre({ rootState, commit, dispatch }, datos) {
    var cierre = db.collection('negocios').doc(rootState.auth.user.negocioTpv).collection('cierrestpv')
    return cierre.doc(datos.inicio.toString()).set(datos, { merge: true })
      .then(() => {
        return db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv')
          .doc('configuracion')
          .set({ 'cierreEnCurso': datos.inicio.toString() }, { merge: true })
      })
      .then(() => {
        commit('setZCierre', datos.inicio.toString());
        commit('setZCierreDatos', datos);

        //dispatch('pedidos/getPedidos', {}, { root: true })
        //dispatch('pedidos/getPedidosRealizandoTpv', {}, { root: true })
        //dispatch('pedidos/getNumPedidoRest', {}, { root: true })

      })
  },
  getZCierre({ rootState }, id) {
    var cierre = db.collection('negocios').doc(rootState.auth.user.negocioTpv).collection('cierrestpv')
    return cierre.doc(id.toString()).get()
      .then(r => {
        return r.exists ? r.data() : null
      })
  },
  finZCierre({ rootState, commit, state, dispatch }, datos) {
    const cierreId = state.zcierre;
    var cierre = db.collection('negocios').doc(rootState.auth.user.negocioTpv).collection('cierrestpv')
    return cierre.doc(state.zcierre.toString()).set({ fin: moment().tz('Europe/Madrid').unix(), datos: datos, estado: 'cerrado' }, { merge: true })
      .then(() => {
        // return dispatch('enviarCierre', state.zcierre)//probar
        //return dispatch('enviarCierre', state.zcierre)

        return db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('tpv')
          .doc('configuracion')
          .set({ 'cierreEnCurso': null }, { merge: true })
      })
      .then(() => {
        return dispatch('enviarCierre', cierreId)
      })
      .then(() => {
        commit('setZCierre', null);
        commit('setZCierreDatos', {});
        dispatch('pedidos/quitarListenerPedidos', {}, { root: true })
      })
      .catch(e => console.log(e))
  },
  guadarSalidaDinero({ rootState, commit, state, dispatch }, datos) {
    var cierre = db.collection('negocios').doc(rootState.auth.user.negocioTpv).collection('cierrestpv')
    return cierre.doc(state.zcierre.toString())
      .set({ salidaDinero: firebase.firestore.FieldValue.arrayUnion(datos) }, { merge: true })

  },
  enviarCierre({ }, id) {
    // var doc = new jsPDF();
    // doc.setFontSize(20);
    // doc.text("CIERRE " + datosNegocio.nombre.toUpperCase() + ' ' + datosRest.nombre.toUpperCase(), 35, 25);
    // doc.addImage("img/logoFunEatsNegro.png", "PNG", 15, 40, 180, 180);
    // return doc.save(datosCierre.id + '.pdf');
    var f = firebase.functions().httpsCallable('httpTpvEnviarCierre');
    return f({ idCierre: id })
  },
  loginComandero({ rootState }, data) {
    var f = firebase.functions().httpsCallable('httpTpvComanderoLogin');
    return f(data)
      .then((r) => {

        return r.data
      })
  },
  permitirLoginComandero({ rootState }, id) {
    return db.collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv).collection('tpv')
      .doc('secreto').collection('cuentas')
      .doc(id).collection('loginComandero')
      .add({
        permitirHasta: moment().unix() + 60 * 5 // 5 miniutos
      })
      .then(r => r.id)
  },
  calcularCierre({ rootGetters, rootState, commit, dispatch, state }, pedidos = null) {
    var tramosHorarios = []
    var promesa = !!state.zcierre ? dispatch('getZCierre', state.zcierre.toString()) : Promise.resolve(false)
    var start = new Date().getTime();




    return promesa
      .then((r) => {
        var salidaDinero = !!r && !!r.salidaDinero ? r.salidaDinero : []
        return new Promise((resolve) => {
          let objBase = {
            nombre: '',
            // tipo: 'formaPago',
            total: 0,
            numPedidos: 0,
            //formas pago
            efectivo: 0,
            tarjeta_domicilio: 0,
            tarjeta_online: 0,
            tarjeta_local: 0,
            local: 0,
            otro: 0,
            varios: 0
          }
          var res = {}; //cobrados
          var resPendientes = {}; //peendientes

          res.totales = {
            ...objBase,
            nombre: 'TOTALES'
          }
          resPendientes.totales = {
            ...objBase,
            nombre: 'TOTALES'
          }
          var productos = {
            productos: [],
            categorias: []
          }

          let salidasDinero = {
            ...objBase,
            total: salidaDinero.reduce((a, b) => a + b.cantidad, 0),
            efectivo: salidaDinero.filter(s => s.metodo == 'efectivo').reduce((a, b) => a + b.cantidad, 0),
            numPedidos: salidaDinero.length,
            nombre: 'SALIDAS DINERO'
          }
          let auxModos = rootState.datosTpv.modos.map(m => {
            return {
              ...objBase,
              nombre: m.nombre.toUpperCase(),
              id: m.id
            }
          })
          res.modos = auxModos.map(m => {
            return { ...m }
          });
          resPendientes.modos = auxModos.map(m => {
            return { ...m }
          });
          let auxCuentas = rootState.datosTpv.cuentas.map(m => {
            return {
              ...objBase,
              nombre: 'CUENTA ' + m.nombre.toUpperCase(),
              id: m.email
            }
          })
          res.cuentas = auxCuentas.map(c => {
            return { ...c }
          });
          resPendientes.cuentas = auxCuentas.map(c => {
            return { ...c }
          });

          let auxTramos = tramosHorarios.map(t => {
            return {
              ...objBase,
              nombre: 'HORA ' + t,
              id: 'hora_' + t
            }
          })
          res.tramosHorarios = [...auxTramos];
          resPendientes.tramosHorarios = [...auxTramos];

          var devolver = {
            salidasDinero: salidasDinero,
            productos: productos,
            cobrados: { ...res },
            pendientes: { ...resPendientes }
          }

          const pedidosDefinitivos = !!pedidos ? pedidos : rootGetters['pedidos/pedidos']
          pedidosDefinitivos.filter(p => !p.eliminado).forEach((p, i) => {
            if (!!p.modoId || p.estado === 'finalizado' && (!state.zcierre || !!p.zcierre)) { //tiene modoId o es desde la web
              //productos
              p.productos.forEach(p => {
                let fIP = devolver.productos.productos.findIndex(pA => pA.id === p.id)
                if (fIP > -1) {
                  devolver.productos.productos[fIP].cantidad += p.cantidad
                  devolver.productos.productos[fIP].precioTotal += p.precioProductoTotal
                } else {
                  devolver.productos.productos.push({
                    id: p.id,
                    cantidad: p.cantidad,
                    precioTotal: p.precioProductoTotal,
                    nombre: p.nombre
                  })
                }
                let fIC = devolver.productos.categorias.findIndex(pC => pC.id === p.categoria)
                if (fIC > -1) {
                  devolver.productos.categorias[fIC].cantidad += p.cantidad
                  devolver.productos.categorias[fIC].precioTotal += p.precioProductoTotal
                } else if (!!p.categoria) {
                  devolver.productos.categorias.push({
                    id: p.categoria,
                    cantidad: p.cantidad,
                    precioTotal: p.precioProductoTotal
                  })
                }

              })

              //pedido
              var aux = !!p.cobrado ? 'cobrados' : 'pendientes';
              devolver[aux].totales.total += parseFloat(p.precioTotal);
              devolver[aux].totales.numPedidos++;
              if (Array.isArray(p.cobradoDesglose)) {
                p.cobradoDesglose.forEach(c => {
                  devolver[aux].totales[c.formaPago] += parseFloat(c.pagado)
                })

                let fIndex = devolver[aux].modos.findIndex(r => p.modoId && r.id == p.modoId);
                if (fIndex > -1) {
                  devolver[aux].modos[fIndex].total += parseFloat(p.precioTotal);
                  devolver[aux].modos[fIndex].numPedidos++;
                  p.cobradoDesglose.forEach(c => {
                    devolver[aux].modos[fIndex][c.formaPago] += parseFloat(c.pagado)
                  })
                }
                if (!!p.cuentaTpv) {
                  let fIndexCuenta = devolver[aux].cuentas.findIndex(r => p.cuentaTpv && r.id == p.cuentaTpv);
                  if (fIndexCuenta > -1) {
                    devolver[aux].cuentas[fIndexCuenta].total += parseFloat(p.precioTotal);
                    devolver[aux].cuentas[fIndexCuenta].numPedidos++;
                    p.cobradoDesglose.forEach(c => {
                      devolver[aux].cuentas[fIndexCuenta][c.formaPago] += parseFloat(c.pagado)
                    })
                  }
                }
                if (!!p.fechaServidor) {
                  let hora = moment(p.fechaServidor.toDate()).format('HH:00')

                  let fI = devolver[aux].tramosHorarios.findIndex(h => hora == h.id)
                  if (fI > -1) {
                    devolver[aux].tramosHorarios[fI].total += parseFloat(p.precioTotal);
                    devolver[aux].tramosHorarios[fI].numPedidos++;
                    p.cobradoDesglose.forEach(c => {
                      devolver[aux].tramosHorarios[fI][c.formaPago] += parseFloat(c.pagado)
                    })
                  } else {
                    devolver[aux].tramosHorarios.push({
                      ...objBase,
                      nombre: hora + 'h',
                      /* nombre: 'HORA ' + hora, */
                      id: hora
                    })
                    let fI2 = devolver[aux].tramosHorarios.findIndex(h => hora == h.id)
                    if (fI2 > -1) {
                      devolver[aux].tramosHorarios[fI2].total += parseFloat(p.precioTotal);
                      devolver[aux].tramosHorarios[fI2].numPedidos++;
                      p.cobradoDesglose.forEach(c => {
                        devolver[aux].tramosHorarios[fI2][c.formaPago] += parseFloat(c.pagado)
                      })
                    }
                  }
                }
              }
            }
          })
          devolver.cobrados.tramosHorarios = devolver.cobrados.tramosHorarios.sort((a, b) => {

            return (moment(a.id, 'HH:mm').unix()) - (moment(b.id, 'HH:mm').unix())
          })

          commit('setZCierreResumen', devolver)
          var end = new Date().getTime();
          var time = end - start;
          console.log('Execution time: ' + time);
          resolve();
        })
      })

  }





}