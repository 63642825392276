<template>
  <div v-if="pedidoEditandoObj">
    <v-row
      class="mx-0 pl-1"
      v-if="$vuetify.breakpoint.mdAndUp && modoPaginacion"
    >
      <v-col class="pb-0 pl-1 pr-2" cols="12" lg="6">
        <div class="d-flex">
          <v-btn
            :disabled="paginaActualPaginacion.actual == 0"
            large
            color="primary black--text"
            class="mr-2 px-12 rounded-lg"
            @click="cambiarPagina(-1)"
            ><v-icon>mdi-arrow-left-bold</v-icon></v-btn
          >
          <v-spacer />
          <div class="mx-2 text-center">
            <!-- {{ paginaActualPaginacion }} -->
            <div class="text-h5 pa-0">
              {{ paginaActualPaginacion.actual + 1 }} /
              {{ paginaActualPaginacion.maximo }}
            </div>
            <div class="caption">
              ({{ paginaActualPaginacion.mostrando }} items)
            </div>
          </div>
          <v-spacer />
          <v-btn
            :disabled="
              paginaActualPaginacion.actual + 1 == paginaActualPaginacion.maximo
            "
            large
            color="primary black--text"
            class="ml-2 px-12 rounded-lg"
            @click="cambiarPagina(1)"
            ><v-icon>mdi-arrow-right-bold</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <!--busqueda-->
    <v-row v-if="buscar.length > 0" justify="center">
      <v-btn @click="reiniciarBusqueda" color="primary" class="black--text mb-4"
        >Reiniciar búsqueda</v-btn
      >
    </v-row>

    <v-row
      v-if="resultadosBusqueda.length == 0 && buscar.length > 2"
      justify="center"
      class="mt-3"
    >
      <v-alert color="warning" class="white--text"
        >No se han encontrado coincidencias. Ignorando la búsqueda</v-alert
      >
    </v-row>
    <template v-if="resultadosBusqueda.length > 0">
      <v-row class="pa-2 pa-md-0 mt-n4">
        <v-col
          cols="4"
          md="3"
          lg="2"
          v-for="p in resultadosBusqueda"
          :key="p.id"
          class="pa-2 pa-md-3"
        >
          <producto-card
            :texto="p.nombre"
            :esMenu="p.menu"
            :tieneOpciones="p.opciones"
            @accion="elegirProducto(p)"
            :cantidad="cantidadCarrito(p)"
            :precio="p.precioMostrar"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else
      ><!--pasos-->
      <!--paso pidiendo categoría-->

      <template v-if="pasoPidiendo == 0">
        <!-- <v-btn @click="conectarLector">PISTOOLA</v-btn> -->
        <v-row class="mx-0">
          <v-col cols="4" md="3" lg="2" class="pa-2">
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="nuevoProductoDialogo = true"
                elevation="4"
                class="rounded-lg ficha-generica"
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'primary lighten-4' : 'primary borde-blanco'"
              >
                <v-responsive :aspect-ratio="1.618" class="align-center">
                  <v-card-text
                    class="text-uppercase font-weight-bold text-center"
                    :class="
                      $vuetify.breakpoint.smAndDown
                        ? 'caption'
                        : $vuetify.breakpoint.mdAndDown
                        ? 'body-2'
                        : 'body-2'
                    "
                    :style="
                      $vuetify.breakpoint.smAndDown ? 'line-height:1;' : ''
                    "
                  >
                    <v-row justify="center">CREAR NUEVO PRODUCTO </v-row>
                  </v-card-text>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <v-col
            cols="4"
            md="3"
            lg="2"
            class="pa-2"
            v-for="c in categoriasDefinitivas"
            :key="c.id"
          >
            <producto-card :texto="c.nombre" @accion="elegirCategoria(c)" />
          </v-col>
        </v-row>
      </template>
      <!--paso pidiendo producto-->
      <template v-else-if="pasoPidiendo == 1">
        <v-row class="mx-0">
          <v-col class="pa-2" cols="4" md="3" lg="2">
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="pasoPidiendo--"
                elevation="4"
                class="rounded-lg ficha-generica"
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'primary lighten-4' : 'primary borde-blanco'"
              >
                <v-responsive :aspect-ratio="1.618" class="align-center">
                  <v-card-text
                    class="text-uppercase font-weight-bold text-center"
                    :class="
                      $vuetify.breakpoint.smAndDown
                        ? 'caption'
                        : $vuetify.breakpoint.mdAndDown
                        ? 'body-2'
                        : 'body-1'
                    "
                    :style="
                      $vuetify.breakpoint.smAndDown ? 'line-height:1;' : ''
                    "
                  >
                    <v-row justify="center">
                      <v-btn icon>
                        <v-icon x-large class="black--text"
                          >mdi-arrow-left-bold</v-icon
                        >
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <v-col
            cols="4"
            md="3"
            lg="2"
            class="pa-2"
            v-for="p in productosDefinitivos"
            :key="p.id"
          >
            <producto-card
              :texto="p.nombreTpv"
              :esMenu="p.menu"
              :tieneOpciones="p.opciones"
              @accion="elegirProducto(p)"
              :cantidad="cantidadCarrito(p)"
              :precio="p.precioMostrar"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else-if="pasoPidiendo == 2 && !!menuPidiendo">
        <v-row class="pa-2 pa-md-0 mt-n4">
          <v-col cols="12">
            <v-card-subtitle class="py-0">
              <v-row align="center">
                <v-col
                  cols="12"
                  v-if="this.$store.state.pedidos.editarProductoMenuObj"
                >
                  Estás editando un menú {{ menuPidiendo.nombre }}
                </v-col>
                <v-col cols="12" v-else>
                  Estás añadiendo un nuevo menú {{ menuPidiendo.nombre }}
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-col>
          <v-col cols="4" md="3" class="pa-2 pa-md-3">
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="volver"
                elevation="4"
                class="rounded-lg ficha-generica"
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'primary lighten-4' : 'primary borde-blanco'"
              >
                <v-responsive :aspect-ratio="1.618" class="align-center">
                  <v-card-text
                    class="text-uppercase font-weight-bold text-center"
                    :class="
                      $vuetify.breakpoint.smAndDown
                        ? 'caption'
                        : $vuetify.breakpoint.mdAndDown
                        ? 'body-2'
                        : 'body-1'
                    "
                    :style="
                      $vuetify.breakpoint.smAndDown ? 'line-height:1;' : ''
                    "
                  >
                    <v-row justify="center">
                      <v-btn icon>
                        <v-icon x-large class="black--text"
                          >mdi-arrow-left-bold</v-icon
                        >
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <!-- cabecera grupos menu-->
          <v-col
            cols="4"
            md="3"
            class="pa-2 pa-md-3"
            v-for="(panel, i) in menuPidiendo.grupos"
            :key="panel.id"
          >
            <producto-card
              :texto="panel.nombreMostrar"
              @accion="pasoPidiendoMenu = i + 1"
              :esMenu="pasoPidiendoMenu === i + 1"
            />
          </v-col>
          <!-- productos menu-->

          <v-col
            cols="4"
            md="3"
            class="pa-2 pa-md-3"
            v-for="p in menuPidiendo.grupos[pasoPidiendoMenu - 1]
              .productosGrupo"
            :key="p.id"
          >
            <producto-card
              :texto="p.nombreMostrar"
              @accion="elegirProducto(p)"
            />
          </v-col>
        </v-row>
      </template>
    </template>
    <!--dialogo opciones productos -->
    <v-dialog
      v-if="!!productoPidiendo && !!opcionesProductoDialogo"
      v-model="opcionesProductoDialogo"
      content-class="elevation-0"
      overlay-opacity="0.90"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
    >
      <v-card :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'">
        <v-card-title class="rounded-t-lg pa-0">
          <v-list class="py-0 transparent" style="width: 100%">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">{{
                  nombreProducto
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ precioProductoSeleccionado }} €</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-icon class="my-2">
                <v-btn
                  fab
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                  :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                  color="primary"
                  @click="opcionesProductoDialogo = false"
                  ><v-icon
                    :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                    color="black"
                    >mdi-close</v-icon
                  ></v-btn
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-title>
        <!--stepper-->
        <v-card-text class="pa-0 blue-grey lighten-5">
          <!-- :alt-labels="$vuetify.breakpoint.smAndDown" -->
          <v-stepper
            v-model="pasoPidiendoOpciones"
            class="transparent stepper--sticky-header"
            elevation="0"
          >
            <v-stepper-header
              class="grey lighten-5 elevation-0"
              style="align-content: center"
              :style="
                $vuetify.breakpoint.smAndDown ? 'height:26px;' : 'height:36px;'
              "
            >
              <template v-for="(panel, i) in cabeceraStepperOpciones">
                <v-stepper-step
                  :key="panel.id"
                  :step="i + 1"
                  :style="'max-width: ' + 100 / opciones.length + '%;'"
                  :class="$vuetify.breakpoint.smAndDown ? 'py-4' : 'py-1'"
                  class=""
                >
                  {{ panel.nombreMostrar }}
                </v-stepper-step>
                <v-spacer :key="`spacer_${i}`" />
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="(panel, i) in opciones"
                :step="i + 1"
                :key="i"
                class="pa-0 overflow-y-auto"
                style="min-height: 440px"
              >
                <!--                 <v-card-title>{{ panel.nombreMostrar }}
                    <v-card-subtitle>({{ panel.tipo }})</v-card-subtitle>
                    <v-card-subtitle v-if="panel.max > 0">Máximo {{ panel.max }}</v-card-subtitle>
                    <v-card-subtitle v-if="panel.min > 0">Mínimo {{ panel.min }}</v-card-subtitle>
                  </v-card-title> -->
                <v-card-title
                  v-if="$vuetify.breakpoint.smAndDown"
                  class="body-1 font-weight-bold"
                >
                  {{ panel.nombreMostrar }}
                  <v-spacer></v-spacer>
                  <template v-if="panelActual.validacion.msj == 'Correcto'">
                    <v-icon color="success">mdi-check-bold</v-icon>
                  </template>
                  <span
                    v-else-if="
                      panelActual.validacion.msj != 'Seleccione una opción'
                    "
                    :class="
                      panelActual.validacion.msj > 0
                        ? 'grey--text'
                        : 'warning--text'
                    "
                  >
                    {{ panelActual.validacion.msj }}
                  </span>
                </v-card-title>
                <v-card-subtitle
                  v-if="
                    panel.max > 0 ||
                    panel.min > 0 ||
                    $vuetify.breakpoint.mdAndUp
                  "
                >
                  <v-row class="align-center">
                    <v-col cols="12" sm="6" v-if="$vuetify.breakpoint.mdAndUp">
                      <template v-if="panel.max > 0 && panel.min > 0">
                        Selecciona al menos {{ panel.min }} ingrediente<span
                          v-if="panel.min != 1"
                          >s</span
                        >
                        y un máximo de {{ panel.max }}.
                      </template>
                      <template v-else-if="panel.min > 0">
                        Selecciona al menos {{ panel.min }} ingrediente<span
                          v-if="panel.min != 1"
                          >s</span
                        >.
                      </template>
                      <template v-else-if="panel.max > 0">
                        Puedes seleccionar hasta {{ panel.max }} ingredientes.
                      </template>
                      <template v-else-if="$vuetify.breakpoint.mdAndUp">
                        &nbsp;
                      </template>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      class="text-center text-sm-right pt-0 pb-sm-0"
                      v-if="panelActual.validacion.ok == false && panel.min > 0"
                    >
                      <!-- {{ panelActual.validacion.msj }} -->
                      <!--  <v-alert
                        dense
                        colored-border
                        border="left"
                        color="orange"
                        type="warning"
                        icon="mdi-alert"
                        rounded="lg"
                        elevation="2"
                        class="d-inline-block mb-0 py-1"
                      >
                        <span class="caption grey--text text--darken-1">
                          {{ panelActual.validacion.msj }}
                        </span>
                      </v-alert> -->
                    </v-col>
                  </v-row>
                </v-card-subtitle>

                <v-card-text>
                  <v-row class="px-1">
                    <v-col
                      :cols="panel.opciones.length > 20 ? 4 : 4"
                      :md="panel.opciones.length > 20 ? 2 : 2"
                      :lg="panel.opciones.length > 20 ? 1 : 2"
                      v-if="!(pasoPidiendoOpciones == 1)"
                      class="pa-2"
                    >
                      <v-card
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        color="pink lighten-4"
                        :disabled="pasoPidiendoOpciones == 1"
                        @click="pasoAtras"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center text-center"
                        >
                          <v-icon> mdi-arrow-left-bold </v-icon>
                        </v-responsive></v-card
                      >
                    </v-col>
                    <v-col
                      :cols="panel.opciones.length > 20 ? 4 : 4"
                      :md="panel.opciones.length > 20 ? 2 : 2"
                      :lg="panel.opciones.length > 20 ? 1 : 2"
                      class="pa-2"
                      v-for="opcion in opcionesCorregidas(panel.opciones)"
                      :key="opcion.id"
                    >
                      <producto-card
                        :texto="opcion.nombreTpv"
                        @accion="elegirOpcion(opcion, panel)"
                        :precio="
                          opcion.precioCobrarMostrar > 0
                            ? '+' + opcion.precioCobrarMostrar
                            : null
                        "
                        :muchasOpciones="panel.opciones.length > 20"
                        :seleccionado="opcion.cantidad > 0 ? true : false"
                      />
                    </v-col>
                    <v-col
                      :cols="panel.opciones.length > 20 ? 4 : 4"
                      :md="panel.opciones.length > 20 ? 2 : 2"
                      :lg="panel.opciones.length > 20 ? 1 : 2"
                      class="pa-2"
                      v-if="
                        !(!panelActual.validacion.ok || !pasoAdelanteBool())
                      "
                    >
                      <v-card
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        color="pink lighten-4"
                        :disabled="
                          !panelActual.validacion.ok || !pasoAdelanteBool()
                        "
                        @click="pasoAdelante()"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center text-center"
                        >
                          <v-icon> mdi-arrow-right-bold </v-icon>
                        </v-responsive></v-card
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'flex-column'"
          class="py-4 blue-grey lighten-5 rounded-b-lg"
        >
          <v-row no-gutters justify="end" style="width: 100%">
            <v-col cols="6" md="3" class="pr-2">
              <v-btn
                large
                block
                class="black--text"
                :disabled="pasoPidiendoOpciones == 1"
                @click="pasoAtras"
                color="primary"
              >
                <v-icon> mdi-arrow-left-bold </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" md="3" class="pl-2">
              <v-btn
                large
                block
                class="black--text"
                :disabled="!panelActual.validacion.ok || !pasoAdelanteBool()"
                @click="pasoAdelante()"
                color="primary"
              >
                <v-icon> mdi-arrow-right-bold </v-icon>
              </v-btn>
            </v-col>
            <v-spacer />
            <!-- <v-col cols="12" md="3" class="pr-2">
              <v-btn
                v-if="
                  !$store.state.pedidos.editarProductoObj && !this.menuPidiendo
                "
                large
                block
                class="black--text"
                :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0 mt-2'"
                color="primary"
                style="max-width: 100%"
                @click="addProductoBtn(false)"
                :disabled="!addOpcionValido"
                >Añadir y pedir otro
                {{ categoriaPidiendo.nombre }}
              </v-btn></v-col
            > -->
            <v-col cols="12" md="3" class="pl-2">
              <v-btn
                large
                block
                class="black--text"
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-2'"
                color="primary"
                style="max-width: 100%"
                @click="addProductoBtn(false)"
                :disabled="!addOpcionValido"
                >Añadir
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="productoPidiendo"
      v-model="productoPesoDialogo"
      max-width="800"
      content-class="blue-grey lighten-5 rounded-lg"
      persistent
    >
      <v-card class="border" color="transparent">
        <v-card-title>
          <span
            class="font-weight-black text-uppercase"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'caption'"
          >
            {{ nombreProducto }}
          </span>
          <v-spacer />
          <span
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'v-card__subtitle pa-0'"
          >
            {{ precioPesoTotal }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                readonly
                label="PESO (Kg)"
                :value="pesoEnVivo"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                readonly
                label="PRECIO (€/Kg)"
                :value="precioPesoBase"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-2 flex-column'"
        >
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            color="warning"
            @click="productoPesoDialogo = false"
            class="rounded-lg"
            >Cancelar</v-btn
          >
          <v-spacer />
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            class="black--text rounded-lg"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0 mt-2'"
            color="primary"
            @click="addProductoPeso"
            >Aceptar y añadir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="nuevoProductoDialogo"
      max-width="800"
      content-class="nuevo-producto-dialogo blue-grey lighten-5 rounded-lg"
      persistent
    >
      <v-card color="blue-grey lighten-5">
        <v-card-title class="white py-3 pr-3">
          <v-row align="center" no-gutters>
            <v-col cols="auto" md="6">
              <h2
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'caption'"
                class="font-weight-black text-uppercase"
              >
                Nuevo producto
              </h2>
            </v-col>
            <v-col cols="auto" md="3">
              <v-checkbox
                hide-details
                class="mt-0 pt-0"
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-4'"
                v-model="nuevoProducto.porPeso"
              >
                <template v-slot:label>
                  <span
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'text-h6' : 'caption ml-n2'
                    "
                  >
                    Por peso
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
            <v-col cols="3" class="text-right">
              <v-btn
                fab
                :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                color="primary"
                @click="nuevoProductoDialogo = false"
                top
                right
              >
                <v-icon
                  :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                  color="black"
                  >mdi-close</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col md="6">
              <v-text-field
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                v-model="nuevoProducto.nombre"
                label="Introduce nombre"
                outlined
                hide-details
                ref="nuevoProductoNombre"
                @focus="setNuevoProductoCampoCambiando('nombre')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!nuevoProducto.porPeso">
              <v-text-field
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                v-model="nuevoProducto.precio"
                step="0.1"
                label="Introduce precio"
                outlined
                hide-details
                @focus="setNuevoProductoCampoCambiando('precio')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-else>
              <v-text-field
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                label="PRECIO (€/Kg)"
                v-model="nuevoProducto.precioBase"
                @focus="setNuevoProductoCampoCambiando('precioBase')"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!nuevoProducto.porPeso">
              <v-text-field
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                v-model="nuevoProducto.cantidad"
                type="number"
                step="1"
                label="Cantidad"
                outlined
                hide-details
                @focus="setNuevoProductoCampoCambiando('cantidad')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-else>
              <v-text-field
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                readonly
                label="PESO (Kg)"
                :value="pesoEnVivo"
                outlined
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                class="
                  white
                  headline
                  font-weight-bold
                  centered-input
                  rounded-lg
                "
                v-model="nuevoProducto.impuesto"
                :items="$store.getters['impuestosSelect']"
                label="Impuesto"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pb-4" v-if="$store.state.necesarioTeclado">
          <div
            class="tecladoNuevoProducto"
            v-show="crearNuevoProductoTipoTeclado == 'completo'"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-row
              justify="center"
              v-show="crearNuevoProductoTipoTeclado == 'numerico'"
            >
              numerico
              <v-col cols="12" sm="6" md="5">
                <KeyboardNumerico
                  keyboardClass="keyboard-producto-nuevo"
                  ref="keyboardRefNumerico"
                  noDividir
                  @onChange="onChangeTecladoNuevoProductoNumerico"
                  @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                  :input="inputTecladoNumerico"
                />
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-2 flex-column'"
        >
          <v-spacer />
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            class="black--text rounded-lg"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0 mt-2'"
            color="primary"
            @click="addNuevoProducto"
            >Aceptar y añadir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialogo menu, no se usa, se usa en la parte izuiqerda en el pasoPidiendo 2-->
    <v-dialog v-model="menuDialog" max-width="800" v-if="!!menuPidiendo">
      <v-card>
        <v-card-title>MENU {{ menuPidiendo.nombre }}</v-card-title>
        <v-card-text>
          <v-stepper
            non-linear
            v-model="pasoPidiendoMenu"
            class="transparent"
            elevation="0"
          >
            <v-stepper-header
              class="grey lighten-5 elevation-0"
              style="align-content: center"
            >
              <template v-for="(panel, i) in menuPidiendo.grupos">
                <v-stepper-step
                  editable
                  :key="i"
                  :step="i + 1"
                  :style="
                    'max-width: ' + 100 / menuPidiendo.grupos.length + '%;'
                  "
                >
                  {{ panel.nombreMostrar }}
                </v-stepper-step>
                <v-spacer :key="`spacer_${i}`" />
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="(panel, i) in menuPidiendo.grupos"
                :key="i"
                :step="i + 1"
              >
                <v-row class="pa-2 pa-md-0 mt-n4 mx-n4">
                  <v-col
                    cols="6"
                    md="3"
                    v-for="producto in panel.productosGrupo"
                    :key="producto.id"
                    class="pa-2 pa-md-3"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        @click="elegirProducto(producto)"
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        :style="hover ? 'cursor: pointer' : ''"
                        :color="producto.cantidad > 0 ? 'primary' : 'white'"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center"
                        >
                          <v-card-text
                            class="text-uppercase font-weight-black text-center"
                            :class="
                              $vuetify.breakpoint.mdAndDown
                                ? 'body-2'
                                : 'body-1'
                            "
                          >
                            <v-row justify="center">
                              {{ producto.nombreTpv }}
                            </v-row>
                            <v-row
                              class="mt-5"
                              justify="center"
                              v-if="
                                !!producto.precioCobrarMostrar &&
                                producto.precioCobrarMostrar != '0.00'
                              "
                            >
                              <v-chip
                                small
                                class="grey--text text--darken-4"
                                :color="
                                  producto.cantidad > 0 ? 'white' : 'primary'
                                "
                                >+ {{ producto.precioCobrarMostrar }} €</v-chip
                              >
                            </v-row>
                          </v-card-text>
                        </v-responsive>
                      </v-card></v-hover
                    ></v-col
                  ></v-row
                >
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            color="warning"
            @click="menuDialog = false"
            class="rounded-lg"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KeyboardNumerico from "./KeyboardNumerico";

import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import { mapState, mapGetters } from "vuex";
import { key } from "firebase-key";

import moment from "moment";
import {
  mostrarIdioma,
  mostrarMoneda,
  isFloat,
  chunkArray,
  parsePrecios,
  redondeo,
} from "@/utils/helper.utils.js";
import ProductoCard from "./ProductoCard.vue";

export default {
  components: { KeyboardNumerico, ProductoCard },
  data() {
    return {
      addOpcionDisponible: false,
      inputTecladoNumerico: "",
      loadingBuscar: false,
      buscar: "",
      //pasoPidiendoOpciones: 1,
      pasoPidiendoOpcionesAnterior: [],
      // pasoPidiendo: 0,
      //opcionesProductoDialogo: false,
      productoPesoDialogo: false,
      nuevoProductoDialogo: false,
      nuevoProductoCampoCambiando: null,
      nuevoProductoTeclado: null,
      nuevoProducto: {
        nombre: "",
        precio: 0,
        cantidad: 1,
        impuesto: "",
        porPeso: false,
        precioBase: 0,
        peso: 0,
      },
      pasoPidiendoMenu: 1,
    };
  },
  watch: {
    editarProductoObj(v) {
      if (v) {
      }
    },
    menuDialog(v) {
      if (!v) {
        this.$store.commit("productos/setProductoPidiendoMenu", null);
      }
    },
    pistolaTextoDefinitivo(v) {
      if (v.length > 0) {
        this.buscarPorCodigo(v)
          .then(() => {
            // this.$store.commit("setPistolaTextoDefinitivo", "");
          })
          .finally(() => {
            this.$store.commit("setPistolaTextoDefinitivo", "");
          });
      } else {
      }
      // this.$store.commit("setPistolaTextoDefinitivo", "");
    },
    "nuevoProducto.porPeso"(v) {
      if (v) {
        this.$store.dispatch("configuracion/conectarBalanza");
      } else {
        this.$store.dispatch("configuracion/cerrarBalanza");
      }
    },
    productoPesoDialogo(v) {
      if (!v) {
        this.$store.dispatch("configuracion/cerrarBalanza");
      }
    },
    nuevoProductoDialogo(v) {
      if (!v) {
        this.nuevoProducto = {
          nombre: "",
          precio: 0,
          impuesto: "",
          cantidad: 1,
          porPeso: false,
          precioBase: 0,
          peso: 0,
        };
        this.$store.dispatch("teclado/esconderTeclado");
      } else {
        this.$store.commit("teclado/setLanzarNumerico");
        this.nuevoProducto.impuesto =
          this.$store.state.restauranteObj &&
          this.$store.state.restauranteObj.impuesto
            ? this.$store.state.restauranteObj.impuesto
            : "";
        setTimeout(() => {
          this.$refs.nuevoProductoNombre.focus();
        }, 100);
        // this.$refs.nuevoProductoNombre.$el.input.focus();
        if (this.$store.state.necesarioTeclado && !this.nuevoProductoTeclado) {
          setTimeout(() => {
            this.nuevoProductoTeclado = new Keyboard("tecladoNuevoProducto", {
              onChange: this.onChangeTecladoNuevoProducto,
              onKeyPress: this.onKeyPressTecladoNuevoProducto,
              /**
               * layout, display, theme ... aquí *
               **/
            });
          }, 100);
        }
      }
    },
    addProducto(v) {
      if (!v) {
        setTimeout(() => {
          this.pasoPidiendo = 0;
          this.$store.commit("productos/setCategoriaPidiendo", null);
          this.$store.commit("productos/setProductoPidiendo", null);
        }, 500);
      }
    },
    pasoPidiendo(v) {
      if (v === 0) {
        this.$store.commit("setPaginacionManejando", "categorias");
        this.$store.commit("setPaginaPaginacionCategorias", 0);
        this.$store.commit("productos/reiniciarProductosPidiendo");
      } else if (v === 1) {
        this.$store.commit("setPaginaPaginacionProductos", 0);
        this.$store.commit("setPaginacionManejando", "productos");
      } else if (v === 2) {
        this.pasoPidiendoMenu = 1;

        //this.pasoPidiendoOpciones = 1;
        //this.opcionesProductoDialogo = true;
      }
    },
    pasoPidiendoOpciones(v) {
      // if (this.opciones && this.opciones.length === this.pasoPidiendoOpciones)
    },
    buscar(v) {
      if (v.length > 2) {
        var f = new Promise((resolve) => {
          let res = this.$store.getters["productos/PRODUCTOS_CATALOGO"]
            .filter((p) =>
              p.nombreMostrar.toUpperCase().includes(this.buscar.toUpperCase())
            )
            .map((p) => {
              return {
                ...p,
                nombre: mostrarIdioma(p.nombre),
                precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
              };
            });
          resolve(res);
        });
        this.loadingBuscar = true;
        f.then((r) => {
          this.resultadosBusqueda = r;
        }).finally(() => (this.loadingBuscar = false));
      } else {
        this.resultadosBusqueda = [];
      }
    },
    opcionesProductoDialogo(v) {
      if (!v) {
        this.$store.commit("productos/reiniciarProductosPidiendo");
        //this.editarProductoObj = null;
      }
    },
  },
  computed: {
    // disabled() {
    //   return !!this.editarProductoObj && this.$vuetify.breakpoint.mdAndUp;
    // },
    menuDialog: {
      get() {
        return this.$store.state.productos.productoPidiendoMenuDialog;
      },
      set(v) {
        this.$store.commit("productos/setProductoPidiendoMenuDialog", v);
      },
    },
    ...mapState({
      productoPidiendoMenu: (state) => state.productos.productoPidiendoMenu,
    }),
    menuPidiendo() {
      //return this.$store.getters["productos/MENU_PIDIENDO"];
      return !!this.productoPidiendoMenu
        ? {
            ...this.productoPidiendoMenu,
            grupos: this.productoPidiendoMenu
              ? this.productoPidiendoMenu.grupos.map((g) => {
                  return {
                    ...g,
                    nombreMostrar: mostrarIdioma(g.nombre),
                    descripcionMostrar: mostrarIdioma(g.descripcion),
                    productosGrupo: g.productosGrupo
                      .map((p) => {
                        let foundP = this.$store.getters[
                          "productos/PRODUCTOS_CATALOGO"
                        ].find((pG) => pG.id == p.producto);

                        if (!!foundP) {
                          return {
                            ...foundP,
                            nombre: mostrarIdioma(foundP.nombre),
                            nombreTpv: foundP.nombreTpv
                              ? foundP.nombreTpv
                              : mostrarIdioma(foundP.nombre),
                            precioMostrar:
                              foundP.precio && foundP.precio > 0
                                ? mostrarMoneda(foundP.precio)
                                : "0.00",
                          };
                        }
                      })
                      .filter((p) => !!p),
                  };
                })
              : [],
          }
        : null;
    },
    panelActual() {
      return this.opciones[this.pasoPidiendoOpciones - 1];
    },
    resultadosBusqueda() {
      return this.$store.state.productos.resultadosBusqueda;
    },
    codigoLector: {
      get() {
        return this.$store.state.configuracion.codigoLector;
      },
      set(v) {
        this.$store.commit("configuracion/setCodigoLector", v);
      },
    },
    crearNuevoProductoTipoTeclado() {
      if (
        this.nuevoProductoCampoCambiando == "precio" ||
        this.nuevoProductoCampoCambiando == "cantidad" ||
        this.nuevoProductoCampoCambiando == "precioBase"
      ) {
        return "numerico";
      } else {
        return "completo";
      }
    },
    // resultadosBusqueda() {
    //   if (this.buscar.trim().length > 0) {
    //     return this.$store.getters["productos/PRODUCTOS_CATALOGO"]
    //       .filter((p) =>
    //         p.nombreMostrar.toUpperCase().includes(this.buscar.toUpperCase())
    //       )
    //       .map((p) => {
    //         return {
    //           ...p,
    //           nombre: mostrarIdioma(p.nombre),
    //           precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
    //         };
    //       });
    //   } else {
    //     return [];
    //   }
    // },

    opcionesProductoDialogo: {
      get() {
        return this.$store.state.productos.opcionesProductoDialogo;
      },
      set(v) {
        this.$store.commit("productos/setOpcionesProductoDialogo", v);
      },
    },
    pasoPidiendo: {
      //0 categorías, 1 productos, 2 menú
      get() {
        return this.$store.state.productos.pasoPidiendoProducto;
      },
      set(v) {
        this.$store.commit("productos/setPasoPidiendoProducto", v);
      },
    },
    pasoPidiendoOpciones: {
      get() {
        return this.$store.state.productos.pasoPidiendoProductoOpciones;
      },
      set(v) {
        this.$store.commit("productos/setPasoPidiendoProductoOpciones", v);
      },
    },
    addOpcionValido() {
      return (
        (this.addOpcionDisponible ||
          this.pasoPidiendoOpciones == this.opciones.length) &&
        this.opciones
          .map(
            (op, i) =>
              i + 1 != this.pasoPidiendoOpciones ||
              this.pasoPidiendoOpcionesAnterior.includes(i + 1) ||
              op.validacion.ok
          )
          .every((op) => op) //comprobamos que las vvalidacionese son en eel panel acutal o alguno qu ha recorrido
      );
    },
    disabledCard() {
      return !(!!this.categorias && this.categorias.length > 0);
    },
    categorias() {
      return this.$store.state.productos.categorias
        .map((c) => {
          return {
            ...c,
            nombre: mostrarIdioma(c.nombre),
            cantidad: this.$store.getters[
              "productos/PRODUCTOS_CATALOGO"
            ].filter((p) =>
              !!p.categoriaTpv ? p.categoriaTpv == c.id : p.categoria == c.id
            ).length,
          };
        })
        .sort((a, b) => {
          if (this.ordenCategoriaProductos == "orden") {
            return a.orden > b.orden ? 1 : b.orden > a.orden ? -1 : 0;
          } else {
            return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
          }
        })
        .filter((c) => c.cantidad > 0);
    },
    categoriasPaginados() {
      let categorias = chunkArray(
        this.categorias,
        this.categoriasPaginacionCategorias
      );
      let res = categorias[this.paginaPaginacionCategorias];
      this.$store.commit("setMaximoPaginacionCategorias", categorias.length);
      this.$store.commit(
        "setMostrandoPaginacionCategorias",
        res ? res.length : 0
      );
      return res;
    },
    categoriasDefinitivas() {
      return this.modoPaginacion ? this.categoriasPaginados : this.categorias;
    },
    productosTodos() {
      return this.$store.getters["productos/PRODUCTOS_CATALOGO"].map((p) => {
        return {
          ...p,
          nombre: mostrarIdioma(p.nombre),
          nombreTpv: p.nombreTpv ? p.nombreTpv : mostrarIdioma(p.nombre),
          precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
        };
      });
    },
    productos() {
      // this.$store.state.productos.productosCatalogos.find(pc =>pc.catalogo=)
      return this.$store.getters["productos/PRODUCTOS_CATALOGO"]
        .filter((p) =>
          !!p.categoriaTpv
            ? p.categoriaTpv == this.categoriaPidiendo.id
            : p.categoria == this.categoriaPidiendo.id
        )
        .map((p) => {
          let corregido = parsePrecios(p);
          return {
            ...p,
            nombre: mostrarIdioma(p.nombre),
            nombreTpv: p.nombreTpv ? p.nombreTpv : mostrarIdioma(p.nombre),
            precioMostrar: !!corregido.precioCorregido
              ? "Desde " + mostrarMoneda(corregido.precioCorregido)
              : p.precio
              ? mostrarMoneda(p.precio)
              : "",
          };
        })
        .sort((a, b) => {
          if (this.ordenCategoriaProductos == "orden") {
            return a.orden > b.orden ? 1 : b.orden > a.orden ? -1 : 0;
          } else {
            return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
          }
        });
    },
    productosPaginados() {
      let productos = chunkArray(
        this.productos,
        this.productosPaginacionProductos
      );
      let res =
        this.productos.length === 0
          ? []
          : productos[this.paginaPaginacionProductos];
      this.$store.commit("setMaximoPaginacionProductos", productos.length);
      this.$store.commit(
        "setMostrandoPaginacionProductos",
        res ? res.length : 0
      );
      return res;
    },
    productosDefinitivos() {
      return (
        this.modoPaginacion ? this.productosPaginados : this.productos
      ).map((p) => {
        // let c = this.pedidoEditandoObj.productos.filter(
        //   (pC) => pC.id == p.id
        // ).length;
        return {
          ...p,

          // cantidad: c > 0 ? "x " + c.toString() : false,
        };
      });
    },
    pedidoEditandoObj() {
      return this.$store.state.pedidos.pedidoEditandoObj;
    },
    nombreCategoria() {
      return this.categoriaPidiendo ? this.categoriaPidiendo.nombre : "";
    },
    nombreProducto() {
      return this.productoPidiendo ? this.productoPidiendo.nombre : "";
    },
    ...mapGetters(["paginaActualPaginacion"]),

    ...mapState([
      "pistolaTextoDefinitivo",
      "modoPaginacion",
      "categoriasPaginacionCategorias",
      "paginaPaginacionCategorias",
      "productosPaginacionProductos",
      "paginaPaginacionProductos",
      "configuracionTpv",
    ]),
    ordenCategoriaProductos() {
      return !!this.configuracionTpv && !!this.configuracionTpv.ordenProductos
        ? this.configuracionTpv.ordenProductos
        : null; //por defecto es por nombre
    },
    ...mapState({
      productoPidiendo: (state) => state.productos.productoPidiendo,
      productoPidiendoOpciones: (state) =>
        state.productos.productoPidiendoOpciones,
      categoriaPidiendo: (state) => state.productos.categoriaPidiendo,
      pesoBalanza: (state) => state.configuracion.pesoBalanza,
    }),
    addProducto: {
      get() {
        return this.$store.state.productos.pidiendoDialog;
      },
      set(v) {
        this.$store.commit("productos/setPidiendoDialog", v);
      },
    },
    cabeceraStepperOpciones() {
      return this.opciones.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.nombreMostrar === value.nombreMostrar)
      );
    },
    opciones() {
      if (this.productoPidiendo.opciones) {
        const opcionesSeleccionadas = this.productoPidiendoOpciones.ids;
        let devolver = this.productoPidiendo.opciones
          .map((panel) => {
            let msj = [];
            const opcionesDelPanel = this.opcionesCorregidas(
              panel.opciones
            ).map((op) => op.id);

            const opcionesSeleccionadasDelPanel = opcionesSeleccionadas.filter(
              (op) => opcionesDelPanel.includes(op)
            );
            //.filter((op) => opcionesSeleccionadas.includes(op));
            let okSimple = panel.opciones
              .map((op) => op.id)
              .some((r) => opcionesSeleccionadas.includes(r));
            let okMulti =
              (!panel.max ||
                panel.max >= opcionesSeleccionadasDelPanel.length) &&
              (!panel.min || panel.min <= opcionesSeleccionadasDelPanel.length);
            let okUltra =
              (!panel.max ||
                panel.max >= opcionesSeleccionadasDelPanel.length) &&
              (!panel.min || panel.min <= opcionesSeleccionadasDelPanel.length);
            let ingredientesString =
              opcionesSeleccionadasDelPanel.length - panel.max == 1 ||
              panel.min - opcionesSeleccionadasDelPanel.length == 1
                ? " ingrediente"
                : " ingredientes";
            if (panel.tipo === "simple" && !okSimple)
              msj.push("Seleccione una opción");
            if (
              panel.tipo != "simple" &&
              panel.max &&
              panel.max < opcionesSeleccionadasDelPanel.length
            )
              msj.push(
                "-" + (opcionesSeleccionadasDelPanel.length - panel.max) /* +
                  ingredientesString */
              );
            if (
              panel.tipo != "simple" &&
              panel.min &&
              panel.min > opcionesSeleccionadasDelPanel.length
            )
              msj.push(
                "+" + (panel.min - opcionesSeleccionadasDelPanel.length) /* +
                  ingredientesString */
              );

            let ok =
              (panel.tipo === "simple" && okSimple) ||
              (panel.tipo == "multi" && okMulti) ||
              (panel.tipo == "ultramulti" && okUltra);
            return {
              ...panel,
              nombreMostrar: mostrarIdioma(panel.nombre),
              validacion: {
                ok: ok,
                msj: !ok ? msj.join(", ") : "Correcto",
              },
              opciones: this.opcionesCorregidas(panel.opciones).map((op) => {
                let f = this.productoPidiendoOpciones.ids.filter(
                  (opPidiendo) => opPidiendo == op.id
                );
                return {
                  ...op,
                  cantidad: f.length,
                  precioCobrarMostrar: op.precioCobrar
                    ? mostrarMoneda(op.precioCobrar)
                    : "0.00",

                  nombreMostrar: mostrarIdioma(op.nombre),
                  nombreTpv: op.nombreTpv
                    ? op.nombreTpv
                    : mostrarIdioma(op.nombre),
                };
              }),
            };
          })
          .filter((o) => o.opciones.length > 0);

        return devolver;
      } else {
        return [];
      }
    },
    precioProductoSeleccionado() {
      return mostrarMoneda(
        this.productoPidiendo.precio +
          this.productoPidiendoOpciones.precios.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
      );
    },
    precioPesoTotal() {
      return mostrarMoneda(this.productoPidiendo.precio * this.pesoEnVivo);
    },
    precioPesoBase() {
      return mostrarMoneda(this.productoPidiendo.precio);
    },
    pesoEnVivo() {
      return this.pesoBalanza;
    },
    incompatibilidadesActuales() {
      return this.productoPidiendoOpciones.incompatibilidades.flat();
    },

    editarProductoObj: {
      get() {
        return this.$store.state.pedidos.editarProductoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoObj", v);
      },
    },
  },
  methods: {
    volver() {
      this.pasoPidiendo--;

      if (this.pasoPidiendo < 2) {
        this.$store.commit("productos/setProductoPidiendoMenu", null);
        this.$store.commit("pedidos/setEditarProductoMenuObj", null);
        this.$store.commit("pedidos/setEditarProductoObj", null);
      }
    },
    opcionMultipleUltraCantidad(item) {
      return this.productoPidiendoOpciones && this.productoPidiendoOpciones.ids
        ? this.productoPidiendoOpciones.ids.filter((i) => i == item.id).length
        : 0;
    },
    clickOpcionMultipleUltra(op, accion) {
      this.$store.commit("productos/addRemoveProductoPidiendoOpcionUltra", {
        incompatibilidades: op.incompatibilidades,
        nombreMostrar: op.nombreMostrar,
        nombreTpv: op.nombreTpv,
        id: op.id,
        precioCobrar: op.precioCobrar,
        cantidad: accion == "mas" ? 1 : -1,
      });
    },
    opcionesCorregidas(opciones) {
      return opciones
        .map((op) => {
          return {
            ...op,
            incompatible: this.incompatibilidadesActuales.includes(op.id),
          };
        })
        .filter((op) => !op.incompatible);
    },
    cantidadCarrito(p) {
      let c = this.pedidoEditandoObj.productos.filter((pC) => pC.id == p.id);
      let cantidad = c.reduce((a, b) => a + b.cantidad, 0);
      return cantidad > 0 ? "x " + cantidad : false;
    },
    buscarPorCodigo(v) {
      if (v.length > 2) {
        var f = new Promise((resolve) => {
          let res = this.$store.getters["productos/PRODUCTOS_CATALOGO"]
            .filter((p) => {
              return (
                !!p.codigoLector &&
                p.codigoLector.toUpperCase().includes(v.toUpperCase())
              );
            })
            .map((p) => {
              return {
                ...p,
                nombre: mostrarIdioma(p.nombre),
                precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
              };
            });
          resolve(res);
        });
        this.loadingBuscar = true;
        return f
          .then((r) => {
            if (r.length > 0)
              return this.$store.dispatch("pedidos/addProducto", {
                producto: r[0],
                cantidad: 1,
              });
          })
          .finally(() => (this.loadingBuscar = false));
      } else {
        return Promise.resolve();
        // this.resultadosBusqueda = [];
      }
    },

    reiniciarBusqueda() {
      this.buscar = "";
      // this.$store.commit("teclado/setActualValor", this.buscar);
      this.focusBuscar();
    },
    setNuevoProductoCampoCambiando(campo) {
      this.nuevoProductoCampoCambiando = campo;

      if (this.nuevoProductoTeclado) {
        this.$refs.keyboardRefNumerico.reset();
        if (campo == "nombre") {
          this.nuevoProductoTeclado.setInput(this.nuevoProducto[campo]);
        } else if (campo == "precio" || campo == "precioBase") {
          // this.inputTecladoNumerico = this.nuevoProducto[campo].toString();
          this.inputTecladoNumerico = "";
        } else if (campo == "cantidad") {
          this.inputTecladoNumerico = "";
          // this.nuevoProducto[campo].toString();
        }
      }
    },
    focusBuscar() {
      if (this.$store.state.necesarioTeclado) {
        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit("teclado/setActualValor", this.buscar);
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this);
        this.$store.commit("teclado/setCampoEditarPropiedad", "buscar");
      }
    },
    onChangeTecladoNuevoProducto(input) {
      // this.nuevoProductoCampoCambiando = input;
      if (
        this.nuevoProductoCampoCambiando == "precio" ||
        this.nuevoProductoCampoCambiando == "precioBase"
      )
        input = parseInt(input) / 100;
      this.nuevoProducto[this.nuevoProductoCampoCambiando] = input;

      if (
        (this.nuevoProductoCampoCambiando == "precio" ||
          this.nuevoProductoCampoCambiando == "precioBase" ||
          this.nuevoProductoCampoCambiando == "cantidad") &&
        input == "C"
      ) {
      }
    },
    onKeyPressTecladoNuevoProducto(button) {
      this.$emit("onKeyPress", button);
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    onChange(input) {
      if (input.charAt(input.length - 1) == "C") {
        this.inputTecladoNumerico = "0";
      } else {
        this.inputTecladoNumerico = input;
      }
      if (isFloat(parseFloat(this.inputTecladoNumerico)))
        this.inputTecladoNumerico = "0";
    },
    onKeyPressTecladoNuevoProductoNumerico(button) {
      if (button == "C") {
        this.inputTecladoNumerico = "0";
      }
    },
    onChangeTecladoNuevoProductoNumerico(input) {
      if (
        this.nuevoProductoCampoCambiando == "precio" ||
        this.nuevoProductoCampoCambiando == "precioBase"
      )
        input = mostrarMoneda(parseInt(input) / 100);
      this.nuevoProducto[this.nuevoProductoCampoCambiando] = input;
      if (isFloat(parseFloat(this.inputTecladoNumerico)))
        this.inputTecladoNumerico = "0";
      // if (input.toString().charAt(input.length - 1) == "C") {
      //   this.inputTecladoNumerico = "0";
      // } else {
      //   this.inputTecladoNumerico = input;
      // }
      // if (isFloat(parseFloat(this.inputKeyboard))) this.inputKeyboard = "0";
    },
    onKeyPress(button) {
      if (button == "C") {
        this.$refs.keyboardRefNumerico.reset();
      }
    },
    cambiarPagina(i) {
      this.$store.dispatch("cambiarPagina", i);
    },
    addNuevoProducto() {
      let impFound = this.$store.state.impuestos.find(
        (i) => i.id == this.nuevoProducto.impuesto
      );
      this.nuevoProducto.peso = this.pesoEnVivo;
      if (impFound) {
        let precio = this.nuevoProducto.porPeso
          ? this.nuevoProducto.precioBase
          : this.nuevoProducto.precio;
        let cantidad = this.nuevoProducto.porPeso
          ? this.nuevoProducto.peso
          : this.nuevoProducto.cantidad;
        let productoInsertar = {
          id: moment().unix(),
          nombre: this.nuevoProducto.nombre,
          categoria: "personalizado",
          precio: parseFloat(precio),
          impuesto: this.nuevoProducto.impuesto,
          impPorcentaje: parseFloat(impFound.impuesto),
          tieneOpciones: false,
          opciones: [],
          productoPersonalizado: true,
        };

        if (this.nuevoProducto.porPeso) {
          productoInsertar.precioPesoTotal = mostrarMoneda(
            this.nuevoProducto.precio * this.nuevoProducto.peso
          );
          productoInsertar.precioPesoBase = this.nuevoProducto.precioBase;
          productoInsertar.porPeso = true;
        }

        this.$store.dispatch("pedidos/addProducto", {
          producto: productoInsertar,
          cantidad: cantidad,
        });
        this.nuevoProductoDialogo = false;
      }
    },
    elegirCategoria(c) {
      this.$store.commit("productos/setCategoriaPidiendo", c);
      this.pasoPidiendo = 1;
    },
    pasoAtras() {
      this.pasoPidiendoOpciones = this.pasoPidiendoOpcionesAnterior.pop();
      this.addOpcionDisponible = false;
      //console.log(this.productoPidiendoOpciones);
      //this.productoPidiendoOpciones.pop();
      //hay que deseleccionar la opción del panel
    },
    pasoAdelanteBool() {
      var algunaOpcionEncontrada = false;
      for (
        let index = this.pasoPidiendoOpciones;
        index < this.opciones.length;
        index++
      ) {
        const element = this.opciones[index];
        let opcionesValidas = this.opcionesCorregidas(element.opciones);
        if (opcionesValidas.length > 0) {
          algunaOpcionEncontrada = true;
          break;
        }
      }
      return algunaOpcionEncontrada;
    },
    pasoAdelante() {
      //comprobar qu elos modaalees siquieente tiene opciones
      var algunaOpcionEncontrada = false;
      for (
        let index = this.pasoPidiendoOpciones;
        index < this.opciones.length;
        index++
      ) {
        const element = this.opciones[index];
        let opcionesValidas = this.opcionesCorregidas(element.opciones);
        if (opcionesValidas.length > 0) {
          algunaOpcionEncontrada = true;
          this.pasoPidiendoOpcionesAnterior.push(this.pasoPidiendoOpciones);
          this.pasoPidiendoOpciones = index + 1;
          break;
        } else {
          this.addOpcionDisponible = true;
        }
      }

      /*if (this.pasoPidiendoOpciones < this.opciones.length) {
        this.pasoPidiendoOpciones++;
      }*/
    },
    elegirOpcion(op, panel) {
      if (panel.tipo == "simple") {
        panel.opciones.map((op) => {
          this.$store.commit("productos/removeProductoPidiendoOpcion", op.id);
        });
        this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
          incompatibilidades: op.incompatibilidades,
          nombreMostrar: op.nombreMostrar,
          nombreTpv: op.nombreTpv,
          id: op.id,
          precioCobrar: op.precioCobrar,
          cantidad: 1,
          panel: panel.id,
        });
        this.pasoAdelante();
      } else if (panel.tipo == "multi") {
        this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
          incompatibilidades: op.incompatibilidades,
          nombreMostrar: op.nombreMostrar,
          nombreTpv: op.nombreTpv,
          id: op.id,
          precioCobrar: op.precioCobrar,
          cantidad: 1,
        });
      }
    },
    conectarLector() {
      //this.$store.dispatch("configuracion/conectarLectorCodigo");
      // this.$store.dispatch("configuracion/conectarLectorCodigo");
    },
    elegirProducto(p) {
      let obligatorioOpciones =
        !!p.opciones &&
        p.opciones.length > 0 && //no tiene opciones
        p.opciones.filter((panel) => {
          return (
            (panel.tipo == "simple" &&
              panel.opciones.filter((op) => op.defecto).length == 0) ||
            parseInt(panel.min) > 0
          );
        }).length > 0; //tiene opciones pero ninguna es obligatoria, se añade del tirón

      var hayQueElegir = obligatorioOpciones;

      //agregamos opciones por defecto, que siempre hay que agregarlas
      if (p.opciones) {
        let agregar = p.opciones
          .map((panel) => panel.opciones)
          .flat()
          .filter((o) => o.defecto);

        agregar.forEach((op) => {
          this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
            incompatibilidades: op.incompatibilidades,
            nombreMostrar: mostrarIdioma(op.nombre),
            nombreTpv: !!op.nombreTpv ? op.nombreTpv : mostrarIdioma(op.nombre),
            id: op.id,
            precioCobrar: op.precioCobrar,
            cantidad: 1,
          });
        });
      }
      // if (obligatorioOpciones) {
      //   hayQueElegir = false;
      //   this.$store.commit("productos/setProductoPidiendo", p);
      //   this.pasoPidiendoOpciones = 1;

      //   let seleccionAutomatica = [];
      //   for (let panel of p.opciones) {
      //     let agregar = panel.opciones.filter((o) => o.defecto);
      //     hayQueElegir =
      //       hayQueElegir ||
      //       (panel.tipo == "simple" && agregar.length == 0) ||
      //       (panel.tipo != "simple" &&
      //         (!panel.min || parseInt(panel.min) > agregar.length));

      //     // agregar.forEach((op) => {
      //     //   console.log(op);
      //     //   this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
      //     //     incompatibilidades: op.incompatibilidades,
      //     //     nombreMostrar: mostrarIdioma(op.nombre),
      //     //     nombreTpv: !!op.nombreTpv
      //     //       ? op.nombreTpv
      //     //       : mostrarIdioma(op.nombre),
      //     //     id: op.id,
      //     //     precioCobrar: op.precioCobrar,
      //     //     cantidad: 1,
      //     //   });
      //     // });
      //     // console.log(agregar);
      //   }
      //   //hay que elewgir opciones, vemos si hay por defectos para agregar automáticamente
      // }

      if (hayQueElegir) {
        this.$store.commit("productos/setProductoPidiendo", p);
        this.pasoPidiendoOpciones = 1;
        this.opcionesProductoDialogo = true;
        //this.pasoPidiendo = 2;
      } else if (!!p.porPeso) {
        this.$store.commit("productos/setProductoPidiendo", p);
        this.$store.dispatch("configuracion/conectarBalanza");
        this.productoPesoDialogo = true;
      } else if (!!p.menu) {
        this.$store.commit("productos/setProductoPidiendoMenu", {
          ...p,
          idconcreto: key(),
        });
        this.pasoPidiendo = 2;
        //this.$store.commit("productos/setProductoPidiendoMenuDialog", true);
      } else {
        this.$store.dispatch("pedidos/addProducto", {
          producto: p,
          cantidad: 1,
          opciones: this.productoPidiendoOpciones,

          ...(!!this.menuPidiendo
            ? {
                menuId: this.menuPidiendo.id,
                menuIdConcreto: this.menuPidiendo.idconcreto,
                menuCopy: {
                  nombre: this.menuPidiendo.nombre,
                  descripcion: this.menuPidiendo.descripcion,
                  precio: this.menuPidiendo.precioCobrar,
                },
              }
            : {}),
        });
        if (!!this.menuPidiendo) {
          //agergamos también menú en la propiedad menu de editarProducto
          this.$store.dispatch("pedidos/addMenu", {
            menu: this.menuPidiendo,
            cantidad: 1,
          });
        }
        if (!!this.editarProductoObj) {
          // si estamos editando el producto restamos uno
          this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
            producto: this.editarProductoObj,
            cantidad: this.editarProductoObj.cantidad - 1,
            precioProductoTotalUnitario: parseFloat(
              this.editarProductoObj.precioProductoTotalUnitario
            ),
          });
          this.editarProductoObj.cantidad--;
          if (this.editarProductoObj.cantidad === 0) {
            this.editarProductoObj = null;
          }
        }
      }
    },
    addProductoPeso() {
      this.$store.dispatch("pedidos/addProducto", {
        producto: {
          ...this.productoPidiendo,
          precioPesoTotal: this.precioPesoTotal,
          precioPesoBase: this.precioPesoBase,
          porPeso: true,
        },
        cantidad: this.pesoEnVivo,
        // cantidad: 0.23,
      });
      this.productoPesoDialogo = false;
    },
    addProductoBtn(volver = true) {
      if (!!this.editarProductoObj) {
        this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
          producto: this.editarProductoObj,
          cantidad: this.editarProductoObj.cantidad - 1,
          precioProductoTotalUnitario: parseFloat(
            this.editarProductoObj.precioProductoTotalUnitario
          ),
        });
        this.editarProductoObj.cantidad--;
        if (this.editarProductoObj.cantidad === 0) {
          this.editarProductoObj = null;
        }
      }

      if (!!this.menuPidiendo) volver = false;
      this.$store
        .dispatch("pedidos/addProducto", {
          producto: this.productoPidiendo,
          opciones: this.productoPidiendoOpciones,
          cantidad: 1,
          ...(!!this.menuPidiendo
            ? {
                menuId: this.menuPidiendo.id,
                menuIdConcreto: this.menuPidiendo.idconcreto,
                menuCopy: {
                  nombre: this.menuPidiendo.nombre,
                  descripcion: this.menuPidiendo.descripcion,
                  precio: this.menuPidiendo.precioCobrar,
                },
              }
            : {}),
        })
        .then(() => {
          if (volver) this.pasoPidiendo = 0;
          this.opcionesProductoDialogo = false;
        });
    },
    mounted() {
      if (this.pasoPidiendo == 0) {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper--sticky-header {
  overflow: visible;

  > .v-stepper__header {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}
</style>

<style lang="scss">
.tecladoNuevoProducto {
  background: transparent;
  border-radius: 8px;
}
.custom-badge {
  &.producto-badge {
    left: -8px;
    top: -8px;
  }
}
.custom-badge {
  &.producto-price {
    bottom: -8px;
    right: -8px;
  }
}
</style>