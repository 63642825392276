var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"overlay-opacity":"0.90","content-class":"mt-n4 dialogo--custom-fs","scrollable":"","max-width":"912"},model:{value:(_vm.salidaDineroDialog),callback:function ($$v) {_vm.salidaDineroDialog=$$v},expression:"salidaDineroDialog"}},[_c('v-card',{staticClass:"transparent"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-title',{staticClass:"py-6 transparent"}):_vm._e(),_c('v-card-title',{staticClass:"text--primary rounded-t-lg blue-grey lighten-5",class:_vm.$vuetify.breakpoint.smAndDown
          ? 'caption'
          : _vm.$vuetify.breakpoint.mdAndDown
          ? 'title'
          : 'display-1'},[_vm._v("SALIDA DE DINERO ")]),_c('v-card-subtitle',{staticClass:"blue-grey lighten-5 body-1 font-weight-light py-2"},[_vm._v(" Hora: "),_c('strong',[_vm._v(_vm._s(_vm.inicioMostrar))])]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4 blue-grey lighten-5 border"},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"12","md":"8"}},[_c('v-list',{staticClass:"rounded-lg",staticStyle:{"min-height":"100%"}},[_c('v-list-item',{staticClass:"my-4"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.cuenta.nombre)+" ")])],1),_c('v-col',{staticClass:"text-center rounded-lg",class:_vm.campoCambiando == 'efectivo_' + _vm.cuenta.id
                      ? 'green lighten-3'
                      : 'grey lighten-3',attrs:{"cols":"3"},on:{"click":function($event){return _vm.cambiarSeleccion(_vm.cuenta.id)}}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("EFECTIVO")]),_c('div',{staticClass:"font-weight-bold text-h5 success--text"},[_vm._v(" "+_vm._s(_vm.cantidadMostrar)+" ")])])],1)],1),_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"COMENTARIO"},model:{value:(_vm.salidaDinero.comentario),callback:function ($$v) {_vm.$set(_vm.salidaDinero, "comentario", $$v)},expression:"salidaDinero.comentario"}})],1)],1)],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('KeyboardNumerico',{ref:"keyboardRefNumerico",attrs:{"keyboardClass":"keyboard-salida-dinero","noDividir":""},on:{"onChange":_vm.onChange,"onKeyPress":_vm.onKeyPress}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3"},on:{"click":function($event){return _vm.$router.push('/comanderos')}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",style:(hover ? 'cursor: pointer' : ''),attrs:{"elevation":"4","outlined":"","color":hover ? 'grey lighten-4' : 'white'}},[_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":"1.618"}},[_c('v-list-item-content',[_c('div',{staticClass:"text-center pb-6"},[_c('v-icon',{staticClass:"my-4",attrs:{"color":"teal darken-4","size":_vm.$vuetify.breakpoint.mdAndDown ? '35' : '45'}},[_vm._v("mdi-camera")])],1)]),_c('v-card-actions',{staticClass:"grey lighten-4 acciones-footer acciones--border-top"},[_c('span',{staticClass:"\n                      text-uppercase\n                      mx-auto\n                      font-weight-bold\n                      teal--text\n                      text--darken-4\n                      subtitle-2\n                    "},[_vm._v("Foto")])])],1)],1)]}}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"rounded-b-lg blue-grey lighten-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"black--text rounded-lg",attrs:{"large":"","loading":_vm.salidaDineroLoading,"color":"primary"},on:{"click":_vm.addSalida}},[_vm._v("AÑADIR SALIDA")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }