<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.90"
    :content-class="
      $vuetify.breakpoint.mdAndUp ? 'elevation-0' : 'dialogo--custom-fs'
    "
    scrollable
  >
    <v-card
      class="transparent"
      :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
    >
      <v-card-title
        class="white py-3 pr-3" style="height: 78px;"
      >
        <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
        <h2
          :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
          class="font-weight-black"
        >
          Elige usuario
        </h2>
        <v-spacer />
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
          color="primary"
          @click="dialog = false"
          top
          right
        >
          <v-icon
            :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
            color="black"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="rounded-b-lg pt-4 blue-grey lighten-5">
        <v-row>
          <v-col cols="12" md="7" order="2" order-md="1">
            <v-row align="center" justify="center" class="white--text">
              <v-col
                cols="6"
                v-for="(usuario, i) in usuarios"
                :key="usuario.id"
                :value="usuario.id"
              >
                <v-card
                  class="white--text text-center"
                  elevation="4"
                  @click="elegirUsuario(usuario)"
                  :color="i == 0 ? 'black' : 'green'"
                  rounded="lg"
                >
                  <v-responsive
                    :aspect-ratio="1.618"
                    class="align-center justify-center text-button"
                  >
                    <v-icon
                      color="white"
                      :size="$vuetify.breakpoint.smAndDown ? '36' : '32'"
                      >mdi-account</v-icon
                    >
                    <div>{{ usuario.nombre }}</div>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col
                cols="6"
                v-if="$store.state.auth.user.role == 'tpv-comandero'"
              >
                <v-card
                  class="white--text text-center"
                  elevation="4"
                  @click="logout"
                  color="red"
                  rounded="lg"
                >
                  <v-responsive
                    :aspect-ratio="1.618"
                    class="align-center justify-center text-button"
                  >
                    <v-icon
                      color="white"
                      :size="$vuetify.breakpoint.smAndDown ? '36' : '32'"
                      >mdi-power</v-icon
                    >
                  
                    <div>Salir</div>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical class="order-md-2"></v-divider> -->
          <v-col cols="12" md="5" order="1" order-md="3">
            <v-text-field
              solo
              type="password"
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="4"
              v-model="passwordUsuario"
              class="display-1 rounded-lg text-center input--p0"
              height="60"
              :error-messages="passwordErrors"
              @input="$v.passwordUsuario.$touch()"
              @blur="$v.passwordUsuario.$touch()"
              @keypress.native="inputOnKey"
              v-if="dialog == true"
              autofocus
              ref="passUsuarioInput"
            ></v-text-field>
            <v-alert
              v-if="mostrarError"
              type="error"
              class="mt-n1 mb-5"
              colored-border
              rounded="lg"
              border="left"
              dense
            > Código incorrecto</v-alert>
            <v-alert
              v-else
              type="info"
              class="mt-n1 mb-5"
              colored-border
              rounded="lg"
              border="left"
              dense
            >Introduzca su password</v-alert>
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <KeyboardNumMini
                ref="keyboardRefMini"
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                :input="passwordUsuario"
                :maxLength=4
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import KeyboardNumMini from "./KeyboardNumMini";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    passwordUsuario: { required, maxLength: maxLength(4) },
  },
  components: {
    KeyboardNumMini,
  },
  data() {
    return {
      mostrarError: false,
      passwordUsuario: "",
    };
  },
  watch: {
    dialog(v) {
      if (!v) this.passwordUsuario = "";
    },
  },
  computed: {
    usuarios() {
      return this.$store.getters["usuarios/getUsuariosListado"];
    },
    dialog: {
      get() {
        return this.$store.state.usuarios.eleccionUsuarioDialog;
      },
      set(v) {
        this.$store.commit("usuarios/setEleccionUsuarioDialog", v);
      },
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.passwordUsuario.$dirty) return errors;
      !this.$v.passwordUsuario.required && errors.push("Contraseña requerida.");
      !this.$v.passwordUsuario.maxLength &&
        errors.push("El password debe tener 4 caracteres como máximo.");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/firebaseLogout",
    }),
    elegirUsuario(usuario) {
      if (usuario.codigo == this.passwordUsuario || usuario.id === null) {
        this.$store.commit("usuarios/setUsuarioActual", usuario);
        this.dialog = false;
      } else {
        this.mostrarError = true;
        this.passwordUsuario = "";
        this.$refs.passUsuarioInput.focus();
      }
    },
    inputOnKey() {
      if (this.mostrarError) this.mostrarError = false;
      this.$refs.passUsuarioInput.focus();
    },
    onChange(input) {
      if (this.mostrarError) this.mostrarError = false;
      if (input.charAt(input.length - 1) == "C") {
        this.passwordUsuario = "";
      } else {
        this.passwordUsuario = input;
        setTimeout(()=>{
          this.$refs.passUsuarioInput.focus();
        }, 100)
      }
    },
    onKeyPress(button) {
      if (button == "C") {
        this.passwordUsuario = "";
        setTimeout(()=>{
          this.$refs.passUsuarioInput.focus();
        }, 100)
      }
    },
  },
};
</script>

<style lang="scss">
.btn--flex-column {
  .v-btn__content {
    flex-direction: column;
  }
}
</style>