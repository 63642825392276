import {
    mostrarIdioma,
    mostrarMoneda,

} from "@/utils/helper.utils.js";
export default {
    // PRODUCTOS_RESTAURANTE(state, getters, rootState) { //depreciar
    //     let asoc = rootState.restauranteObj.productosAsociados;
    //     return state.productosArray.map(p => {
    //         let found = asoc.find(pAsoc => pAsoc.id == p.id)
    //         return (found) ?
    //             {
    //                 ...p,
    //                 ...found,
    //                 asociado: true
    //             } :
    //             {
    //                 ...p,
    //                 asociado: false
    //             }

    //     })
    // },
    PRODUCTOS_RESTAURANTE(state, getters, rootState) {
        let asoc = state.productosCatalogos.map(cat => {
            var foundTarifa = state.productosTarifa.find(pT => pT.tarifa == cat.tarifa)
            if (foundTarifa) {
                return {
                    ...cat,
                    productosFinales: foundTarifa.productos.map(prdGeneral => {
                        var foundPrdCat = !!cat.productos ? cat.productos.find(pCat => pCat.id == prdGeneral.id) : false;
                        var foundPrdBase = state.productosArray.find(pBase => pBase.id == prdGeneral.id);
                        if (foundPrdCat) {
                            prdGeneral.activo = foundPrdCat.activo;
                            prdGeneral.precioCobrar = foundPrdCat.precio;
                            prdGeneral.particularRest = true;
                        }

                        return {
                            ...prdGeneral, ...(!!foundPrdBase ? foundPrdBase : {}), precioCobrar: prdGeneral.precio
                        };

                    })
                }
            } else {
                return {
                    ...cat,
                    productosFinales: []
                }
            }
        })

        return asoc;
    },
    PRODUCTOS_RESTAURANTE_LISTADO(state, getters, rootState) {
        var res = []
        getters['PRODUCTOS_RESTAURANTE'].forEach(prdCat => {
            prdCat.productosFinales.forEach(prd => {
                let fIndex = res.findIndex(p => p.id == prd.id);
                if (fIndex > -1) {
                    res[fIndex].catalogo.push({
                        catalogo: prdCat.catalogo,
                        precio: prd.precio,
                        activo: prd.activo
                    })
                } else {
                    res.push({
                        ...prd,
                        opciones: prd.opciones && prd.opciones.length > 0 ?
                            prd.opciones.map(op => {
                                return op;
                            }) : [],
                        catalogo: [{
                            catalogo: prdCat.catalogo,
                            precio: prd.precio,
                            activo: prd.activo
                        }]
                    })
                }
            })
        })
        return res;
    },
    PRODUCTOS_CATALOGO(state, getters, rootState, rootGetters) {
        if (rootState.pedidos.pedidoEditandoObj && rootState.pedidos.pedidoEditandoObj.modo) {
            let modo = rootState.pedidos.pedidoEditandoObj.modo;
            let canal = rootState.pedidos.pedidoEditandoObj.canalVenta;
            var valorFiltrar = '';
            if ((canal == 'funeats') || (canal == 'propio') || (canal == 'directorio')) {
                valorFiltrar = 'online-' + rootState.pedidos.pedidoEditandoObj.modo;
                let f = getters['PRODUCTOS_RESTAURANTE'].find(pc => pc.catalogo == valorFiltrar)
                return f ? f.productosFinales.filter(p => !!p.activo) : []
            } else if (canal == 'tpv') {

                if (rootState.pedidos.pedidoEditandoObj.modoCopy && rootState.pedidos.pedidoEditandoObj.modoCopy.tarifa) {
                    let f = getters['PRODUCTOS_RESTAURANTE'].find(pc => pc.tarifa == rootState.pedidos.pedidoEditandoObj.modoCopy.tarifa)
                    return f ? f.productosFinales.filter(p => !!p.activo) : []
                } else {
                    valorFiltrar = 'tpv-';
                    let mesa = rootGetters['pedidos/mesaObjPedido']

                    valorFiltrar = (mesa && mesa.catalogo) ? mesa.catalogo : 'tpv-' + modo
                    let f = getters['PRODUCTOS_RESTAURANTE'].find(pc => pc.catalogo == valorFiltrar)

                    return f ? f.productosFinales.filter(p => !!p.activo) : []
                }


            }
        }
        return []
    },
    PRODUCTOS_CATALOGO_DEFECTO(state, getters, rootState, rootGetters) {
        // if (rootState.pedidos.pedidoEditandoObj && rootState.pedidos.pedidoEditandoObj.modo) {
        // let modo = rootState.pedidos.pedidoEditandoObj.modo;
        // let canal = rootState.pedidos.pedidoEditandoObj.canalVenta;
        // var valorFiltrar = '';
        // if ((canal == 'funeats') || (canal == 'propio') || (canal == 'directorio')) {
        //     valorFiltrar = 'online-' + rootState.pedidos.pedidoEditandoObj.modo;
        // } else if (canal == 'tpv') {
        //     valorFiltrar = 'tpv-';
        //     let mesa = rootGetters['pedidos/mesaObjPedido']
        //     valorFiltrar = (mesa && mesa.catalogo) ? mesa.catalogo : 'tpv-' + modo
        // }
        // valorFiltrar += rootState.pedidos.pedidoEditandoObj.modo;
        let valorFiltrar = 'tpv-mostrador';
        let f = getters['PRODUCTOS_RESTAURANTE'].find(pc => pc.catalogo == valorFiltrar)

        return f ? f.productosFinales.filter(p => !!p.activo) : []
        // }
        return []
    },
    TARIFAS_EFECTIVAS(state) {
        return [...new Set(state.productosCatalogos.map(pC => pC.tarifa))]
    },
    MENU_PIDIENDO(state, getters) {
        return !!state.productoPidiendoMenu
            ? {
                ...state.productoPidiendoMenu,
                grupos: state.productoPidiendoMenu
                    ? state.productoPidiendoMenu.grupos.map((g) => {
                        return {
                            ...g,
                            nombreMostrar: mostrarIdioma(g.nombre),
                            descripcionMostrar: mostrarIdioma(g.descripcion),
                            productosGrupo: g.productosGrupo
                                .map((p) => {
                                    let foundP = getters[
                                        "PRODUCTOS_CATALOGO"
                                    ].find((pG) => pG.id == p.producto);

                                    if (!!foundP) {
                                        return {
                                            ...foundP,
                                            nombre: mostrarIdioma(foundP.nombre),
                                            nombreTpv: foundP.nombreTpv
                                                ? foundP.nombreTpv
                                                : mostrarIdioma(foundP.nombre),
                                            precioMostrar:
                                                foundP.precio && foundP.precio > 0
                                                    ? mostrarMoneda(foundP.precio)
                                                    : "0.00",
                                        };
                                    }
                                })
                                .filter((p) => !!p),
                        };
                    })
                    : [],
            }
            : null;
    }
}