<template>
  <v-row class="mt-0" style="max-height: 100%">
    <v-col
      cols="12"
      md="8"
      lg="9"
      class="scroll-y--helper"
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-1' : 'px-0'"
      style="max-height: 100%"
    >
      <v-overlay opacity="0.8" :value="editandoProductos"> </v-overlay>

      <div :class="$vuetify.breakpoint.mdAndDown ? 'pb-16 mb-4' : ''">
        <producto-pedir />
      </div>
    </v-col>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-col
        cols="7"
        md="4"
        lg="3"
        class="pa-0"
        style="position: relative; z-index: 999"
        ><v-card height="100%" width="100%" class="mx-auto border">
          <v-navigation-drawer
            absolute
            width="100%"
            permanent
            class="grey lighten-5 elevation-0"
          >
            <productos-carrito :productos="productosMostrar" />
            <template v-slot:append v-if="!editandoProductos">
              <div class="pa-0">
                <v-btn
                  class="text-h6 black--text"
                  x-large
                  color="primary"
                  @click="$store.commit('pedidos/setCobrarDialog', true)"
                  block
                  :disabled="
                    !pedidoEditandoObj.productos ||
                    pedidoEditandoObj.productos.length < 1 ||
                    !pedidoEditandoObj.id
                  "
                >
                  Cobrar
                  <v-spacer></v-spacer>
                  <span class="font-weight-black">{{ precioTotal }} €</span>
                </v-btn>
              </div>
              <div class="pa-0">
                <cobrar />
              </div>
            </template>
          </v-navigation-drawer>
        </v-card>
      </v-col>
    </template>
    <template v-else>
      <v-navigation-drawer
        fixed
        right
        top
        temporary
        class="grey lighten-5 elevation-0"
        width="100%"
        v-model="drawerComandero"
      >
        <v-btn
          fab
          :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
          :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
          color="primary"
          @click="
            !!$store.state.pedidos.editarProductoObj
              ? ($store.state.pedidos.editarProductoObj = false)
              : (drawerComandero = false)
          "
          absolute
          right
          class="mr-n2 mt-1"
          ><v-icon :size="$vuetify.breakpoint.smAndDown ? 36 : 54" color="black"
            >mdi-close</v-icon
          ></v-btn
        >
        <v-list-item v-if="$vuetify.breakpoint.smAndDown">
          <v-list-item-content>
            <v-list-item-title
              class="
                text-sm-h4 text-h5
                font-weight-black
                cyan--text
                text--lighten-2
              "
            >
              PEDIDO
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <productos-carrito :productos="productosMostrar" />
        <template v-slot:append v-if="!$store.state.pedidos.editarProductoObj">
          <div class="pa-0">
            <v-btn
              class="text-h6 black--text"
              x-large
              color="primary"
              @click="$store.commit('pedidos/setCobrarDialog', true)"
              block
              :disabled="
                !pedidoEditandoObj.productos ||
                pedidoEditandoObj.productos.length < 1
              "
            >
              Cobrar
              <v-spacer></v-spacer>
              <span class="font-weight-black">{{ precioTotal }} €</span>
            </v-btn>
          </div>
          <div class="pa-0">
            <cobrar />
          </div>
        </template>
      </v-navigation-drawer>
      <v-sheet
        class="pedido--bottom-sheet px-4 py-4"
        width="100%"
        color="grey lighten-5"
      >
        <v-btn
          block
          large
          color="primary black--text"
          @click.stop="drawerComandero = true"
        >
          Ver pedido
        </v-btn>
      </v-sheet>
    </template>
  </v-row>
</template>

<script>
import moment from "moment-timezone";
import { restSinNegocio } from "@/utils/restaurantes.utils.js";
import ProductoPedir from "../components/ProductoPedir.vue";
import ProductosCarrito from "../components/ProductosCarrito.vue";
import { mostrarMoneda } from "@/utils/helper.utils.js";
import Cobrar from "./Cobrar.vue";
import ClienteDatos from "./ClienteDatos.vue";
import DireccionDatos from "./DireccionDatos.vue";
import firebase from "firebase";
import { mapGetters } from "vuex";

export default {
  components: {
    ProductoPedir,
    ProductosCarrito,
    Cobrar,
    ClienteDatos,
    DireccionDatos,
  },
  data() {
    return {
      creando: false,
      pedidoCopy: null,
      timeoutGrabar: [],
      drawerComandero: false,
      // abriendoNoGrabar: false, //centinela
    };
  },
  watch: {
    pedidoEditando(v) {},

    pedidoEditandoObj: {
      handler(nVal, oVal) {
        console.log("abrir");
        console.log(nVal);
        this.pedidoCopy = { ...nVal }; // para manejarlo antes de destruir,
        if (!!nVal.cobrado && !this.$store.state.pedidos.cobrarDialog) {
          this.$store.commit("pedidos/setPedidoDialog", false);
          // this.$store.commit("pedidos/setCobrarDialog", true);
        } else if (this.pedidoEditandoObj && this.pedidoEditandoObj.id) {
          if (this.timeoutGrabar.length > 0) {
            this.timeoutGrabar.map((t) => {
              clearTimeout(t);
            });

            this.timeoutGrabar = [];
          }

          this.timeoutGrabar.push(
            setTimeout(() => {
              if (!!this.pedidoEditandoObj && !!this.pedidoEditandoObj.id) {
                //console.log("vamos a grabar");
                //console.log(this.pedidoEditandoObj);
                if (this.abriendoNoGrabar) {
                  this.abriendoNoGrabar = false;
                } else {
                  this.grabarPedido();
                }
              }
            }, 500)
          );
        } else if (
          !this.pedidoEditandoObj.id &&
          this.pedidoEditandoObj.productos &&
          this.pedidoEditandoObj.productos.length > 0 &&
          !this.creando &&
          this.pedidoEditandoObj.modo
        ) {
          // pedido nuevo
          this.creando = true;
          //console.log("crear");
          this.$store
            .dispatch("pedidos/addNuevoPedido", this.pedidoEditandoObj)
            .then((r) => {
              this.creando = false;
              //console.log("crado");
              //console.log(r);
              this.$store.commit("pedidos/setPedidoEditando", r[0].id);
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "id",
                value: r[0].id,
              });
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "numPedidoRest",
                value: r[0].numPedidoRest,
              });
              this.$store.commit("pedidos/setPedidoEditandoObjValor", {
                key: "numPedidoRestDia",
                value: r[0].numPedidoRestDia,
              });
              this.grabarPedido();
            });
          //   console.log("nuevoPedido");
          //   this.pedido.id = r[0].id;
          //   this.pedido.numPedidoRest = r[0].numPedidoRest;
          //   this.pedido.numPedidoRestDia = r[0].numPedidoRestDia;
        }
      },
      deep: true,
    },
  },
  computed: {
    editandoProductos() {
      return (
        !!this.$store.state.pedidos.editarProductoObj ||
        !!this.$store.state.pedidos.editarProductoMenuObj
      );
    },
    abriendoNoGrabar: {
      get() {
        return this.$store.state.pedidos.abriendoNoGrabar;
      },
      set(v) {
        this.$store.commit("pedidos/setAbriendoNoGrabar", v);
      },
    },
    productosMostrar() {
      let productosCobrados = (
        this.pedidoEditandoObj.cobradoDesglose
          ? this.pedidoEditandoObj.cobradoDesglose
              .map((c) => {
                return !!c.programado && !!c.programado.productos
                  ? c.programado.productos
                  : false;
              })
              .filter((p) => !!p)
              .flat()
          : []
      ).map((p) => {
        return {
          ...p,
          array2Sorted: p.opciones ? p.opciones.slice().sort() : [],
        };
      });

      return this.pedidoEditandoObj && this.pedidoEditandoObj.productos
        ? this.pedidoEditandoObj.productos
            .filter((p) => !p.cobradoPorProducto)
            .map((p) => {
              let coincideImpresion = this.pedidoEditandoObj.impresionCocina
                ? this.pedidoEditandoObj.impresionCocina.filter((e) => {
                    return true;
                    // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
                    var array2Sorted = p.opciones.slice().sort();
                    return (
                      e.id === p.id &&
                      e.opciones.length === p.opciones.length &&
                      e.opciones
                        .slice()
                        .sort()
                        .every(function (value, index) {
                          return (
                            array2Sorted &&
                            array2Sorted[index] &&
                            value === array2Sorted[index]
                          );
                        })
                    );
                  })
                : []; //filter coincide impresion
              let coincide = productosCobrados.filter((e) => {
                // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
                var array2Sorted = p.opciones.slice().sort();
                return (
                  e.id === p.id &&
                  e.opciones.length === p.opciones.length &&
                  e.opciones
                    .slice()
                    .sort()
                    .every(function (value, index) {
                      return (
                        array2Sorted &&
                        array2Sorted[index] &&
                        value === array2Sorted[index]
                      );
                    })
                );
              }); //filter

              var cantidadRestar = 0;

              coincide.forEach((pCoincide) => {
                if (
                  p.precioProductoTotalUnitario ===
                  pCoincide.precioProductoTotalUnitario
                )
                  cantidadRestar += pCoincide.cantidad;
              });

              if (coincide.length > 0) {
                return {
                  ...p,
                  cantidadCobrada: cantidadRestar,
                  //cantidadImpresa: cantidadImpresa,
                  precioProductoTotalCobrado:
                    cantidadRestar * p.precioProductoTotalUnitario,
                };
              } else {
                return p;
              }
            })
        : [];
    },
    ...mapGetters({
      cuenta: "cuenta",
    }),
    mostrarAnadirDialog() {
      return this.$store.state.pedidos.pedidoDialog;
    },
    modos() {
      return this.$store.state.pedidos.modos;
    },
    precioTotal() {
      return mostrarMoneda(this.pedidoEditandoObj.precioTotal, true);
    },
    subtotal() {
      return mostrarMoneda(
        this.pedidoEditandoObj.precioTotal -
          this.pedidoEditandoObj.impuestosTotal
      );
    },
    impuestos() {
      return mostrarMoneda(this.pedidoEditandoObj.impuestosTotal);
    },
    pedidoEditando() {
      return this.$store.state.pedidos.pedidoEditando;
    },
    pedidoEditandoObj: {
      get() {
        return this.$store.state.pedidos.pedidoEditandoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoEditandoObj", v);
      },
    },
    // pedidoDialog(){
    //     return this.$store.state.pedidos.pedidoDialog;
    // }
  },
  methods: {
    cambiarCliente() {
      this.$store.commit("pedidos/setModoPantalla", "seleccionCliente");
    },
    grabarPedido() {
      console.log("ANTES");
      this.$store
        .dispatch("pedidos/savePedido", this.pedidoEditandoObj)
        .finally(() => console.log("DESPUES"));
    },
    goSeleccionarCliente() {
      this.cliente.dialog = true;
    },
    nuevoPedido() {
      this.$store.commit("pedidos/setPedidoEditandoObj", {
        ...this.pedidoEditandoObj,
        canalVenta: "tpv",
      });
      this.pedidoEditandoObj = {
        ...this.pedidoEditandoObj,
        modo:
          this.pedidoEditandoObj && this.pedidoEditandoObj.modo
            ? this.pedidoEditandoObj.modo
            : null,
        id: null,
        cuentaTpv: this.$store.state.auth.user.email,
        fechaInicio: new Date(),
        fechaServidor: firebase.firestore.Timestamp.now(),
        zcierre: this.$store.state.zcierre,
        usuarioTpv: this.$store.state.usuarios.usuarioActual,
        comentarioPedidoTpv: "",
        nombrePersonalizadoTpv: "",
        cobradoDesglose: [],
        cobradoProgramado: [],
        copyUsuarioTpv: {
          nombre: this.$store.state.usuarios.usuarioActualObj.nombre,
        },
        historial: [
          {
            fecha: moment().unix(),
            mensaje: "comienzo pedido",
            usuario: this.$store.state.usuarios.usuarioActual,
          },
        ],
        estado: "tpv-realizando",
        canalVenta: "tpv",
        precioTotal: 0,
        impuestosTotal: 0,
        numPedidoRest: null,
        numPedidoRestDia: null,
        negocio: this.$store.state.restauranteObj.negocio,
        restaurante: restSinNegocio(this.$store.state.restauranteObj),
        copyRest: {
          nombre: this.$store.state.restauranteObj.nombre,
          cif: this.$store.state.restauranteObj.cif,
          telefono: this.$store.state.restauranteObj.telefono,
          telefono: this.$store.state.restauranteObj.telefono,
          direccion: this.$store.state.restauranteObj.direccion,
          tiempoDomicilio:
            this.$store.state.restauranteObj &&
            this.$store.state.restauranteObj.tiempoDomicilio
              ? this.$store.state.restauranteObj.tiempoDomicilio
              : null,
          tiempoRecogida:
            this.$store.state.restauranteObj &&
            this.$store.state.restauranteObj.tiempoRecogida
              ? this.$store.state.restauranteObj.tiempoRecogida
              : null,
          confGeneral: {
            zonaHoraria:
              this.$store.state.restauranteObj.confGeneral &&
              this.$store.state.restauranteObj.confGeneral.zonaHoraria
                ? this.$store.state.restauranteObj.confGeneral.zonaHoraria
                : null,
          },
        },
        copyNegocio: {
          nombre: this.$store.state.negocioObj.nombre,
        },
        productos: [],
        menus: [],
      };
      if (!!this.pedidoEditandoObj.direccion) {
        this.pedidoEditandoObj.direccion = this.pedidoEditandoObj.direccion;
      }
      this.$store.commit(
        "pedidos/setPedidoEditandoObj",
        this.pedidoEditandoObj
      );
      this.$store.commit(
        "pedidos/setPedidoEditando",
        this.pedidoEditandoObj.id
      );
      // this.$store.dispatch("pedidos/addNuevoPedido", this.pedido).then((r) => {
      //   console.log("nuevoPedido");
      //   this.pedido.id = r[0].id;
      //   this.pedido.numPedidoRest = r[0].numPedidoRest;
      //   this.pedido.numPedidoRestDia = r[0].numPedidoRestDia;
      //   this.$store.commit("pedidos/setPedidoEditandoObj", this.pedido);
      //   this.$store.commit("pedidos/setPedidoEditando", this.pedido.id);
      // });
    },
  },
  beforeDestroy() {
    if (
      this.pedidoCopy &&
      this.pedidoCopy.id &&
      this.cuenta &&
      this.cuenta.imprimirCocinaAuto
    ) {
      this.$store.commit("impresion/setPedidoOcupado", this.pedidoCopy.id);
      this.$store
        .dispatch("pedidos/imprimirPedidoCocina", {
          pedido: this.pedidoCopy,
          filtrarImpresos: true,
        })
        .finally(() => {
          console.log("final");
          this.$store.commit("impresion/setPedidoOcupado", null);
        });
    }
    this.$store.commit("setMostrarFabAdd", true);
    //if (!!this.timeoutGrabar) clearTimeout(this.timeoutGrabar);
    //return this.pedido && this.pedido.id ? this.grabarPedido() : true;
  },

  mounted() {
    this.$store.commit("setMostrarFabAdd", false);
    // let idpedido = this.$route.params.idpedido;
    let idpedido = this.$store.state.pedidos.pedidoEditando;
    // this.$store
    // 	.dispatch("firestoreConexion") //desactivamos conexión bbdd firesetoree, grabamos offline
    // 	.then((r) => {
    // 		console.log("sin conexión");
    // 	});
    if (idpedido) {
      this.$store.dispatch("pedidos/setPedidoObj", idpedido).then(() => {
        this.abriendoNoGrabar = true;
        // this.pedidoEditandoObj = this.$store.state.pedidos.pedidoEditandoObj;
      });
      // this.pedido = this.$store.getters["pedidos/pedidoEditandoObj"];
    } else {
      //nuevo
      this.nuevoPedido();
    }
  },
};
</script>

<style lang="scss">
.overlay {
  pointer-events: none;
  z-index: 50;
  /* for "disabled" effect */
  opacity: 1;
  background: #000;
}
.scroll-y--helper {
  max-height: 100%;
  overflow-y: auto;
}
.border {
  border: 1px solid #000;
}
.pedido--bottom-sheet {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>