import { db, functions } from '@/firebase'
import firebase from 'firebase';
import { mostrarIdioma, redondeo } from "@/utils/helper.utils.js";
import moment from 'moment-timezone'


export default {
    //crear todas las colecciones y documentos para contable, si no lo está
    crearSistemaContable({ rootState }) {
        const rest = rootState.auth.user.restauranteTpv;
        console.log(rest);
        console.log(rest.split('-').map(s => s.charAt(0).toUpperCase()).join(''))
        let codigoRestaurante = rest.split('-').map(s => s.charAt(0).toUpperCase()).join('');
        return db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('contable').doc('contadores').set({
            //codigoRestaurante: codigoRestaurante,
            numeroContableTpv: 1,
            numeroContableOnline: 1,
            numeroContableAbono: 1,
        })
    },
    getNumerosContables({ rootState, commit, dispatch }) {
        return db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('contable').doc('contadores')
            .onSnapshot((r) => {
                if (r.exists) {
                    console.log(r.data())
                    commit('SET_NUMEROS_CONTABLES', r.data())
                } else {
                    console.log("crear sistema contable");
                    dispatch('crearSistemaContable');
                }
            })
    },
    incrementarContadores({ rootState }, serie) {
        let grabar = {};
        var ref = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv).collection('contable').doc('contadores')
        switch (serie) {
            case 'tpv':
                grabar.numeroContableTpv = firebase.firestore.FieldValue.increment(1);
                break;
            case 'online':
                grabar.numeroContableOnline = firebase.firestore.FieldValue.increment(1);
                break;
            case 'abono':
                grabar.numeroContableAbono = firebase.firestore.FieldValue.increment(1);
                break;
        }
        console.log(grabar)
        return ref.set(grabar, { merge: true })
    },
    crearApunteContable({ rootState, state, dispatch }, datos) {
        console.log(datos);
        let codigoRestaurante = rootState.restauranteObj && rootState.restauranteObj.codigoRest
            ? rootState.restauranteObj.codigoRest
            : '001'
        console.log(codigoRestaurante);
        var numeroContable;
        let serieCodigo;
        switch (datos.serie) {
            case 'tpv':
                serieCodigo = 'T';
                numeroContable = state.numerosContables.numeroContableTpv
                break;
            case 'online':
                serieCodigo = 'N';

                numeroContable = state.numerosContables.numeroContableOnline
                break;
            case 'abono':
                serieCodigo = 'A';
                numeroContable = state.numerosContables.numeroContableAbono
                break;
        }
        let codigoFactura = 'F' + serieCodigo + codigoRestaurante + numeroContable.toString().padStart(10, '0');;
        console.log(codigoFactura)
        dispatch('incrementarContadores', datos.serie)

        // Los impuestos y base imponible vienen desde 
        // datos.productos = datos.productos.map(p => {
        //     let baseImponible = redondeo(p.precioProductoTotalUnitario * p.cantidad / (p.impuesto + 1)); //dividir entre 1.1, 1,21, 1,04 ...
        //     return {
        //         ...p,
        //         impuestoCantidad: redondeo((p.precioProductoTotalUnitario * p.cantidad) - baseImponible),
        //         baseImponible: baseImponible

        //     }
        // })
        // datos.baseImponible = redondeo(datos.productos.reduce((accumulator, a) => accumulator + a.baseImponible, 0))
        // datos.impuestosTotal = redondeo(datos.pagado - datos.baseImponible)

        datos.codigoFactura = codigoFactura
        datos.restaurante = rootState.restauranteObj.id
        datos.negocio = rootState.negocioObj.id
        console.log(datos);
        //return;
        return db.collection('restaurantes')
            .doc(rootState.auth.user.restauranteTpv).collection('contable')
            .doc('apuntes').collection('listado')
            .doc(codigoFactura + '-' + moment().unix()).set(datos)
            .then(() => codigoFactura)
    }
}