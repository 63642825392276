export default {
  setProductosArray(state, p) {
    state.productosArray = p;
  },
  setCategorias(state, c) {
    state.categorias = c;
  },
  setCategoriaPidiendo(state, c) {
    state.categoriaPidiendo = c;
  },
  setProductoEditandoCarrito(state, c) {
    state.productoEditandoCarrito = c;
  },
  setPidiendoDialog(state, b) {
    state.pidiendoDialog = b;
  },
  setProductosPidiendo(state, p) { //array
    state.productosPidiendo = p;
  },
  setProductoPidiendo(state, p) {
    if (!state.productoPidiendo || state.productoPidiendo.id !== p.id) {
      state.productoPidiendoOpciones = {
        ids: [],
        nombres: [],
        nombresTpv: [],
        precios: [],
        incompatibilidades: []
      }
    }
    state.productoPidiendo = p;
  },
  setProductoPidiendoMenu(state, p) {
    state.productoPidiendoMenu = p;
  },
  setProductoPidiendoMenuDialog(state, p) {
    state.productoPidiendoMenuDialog = p;
  },
  setProductoPidiendoOpciones(state, ops) {
    state.productoPidiendoOpciones = ops;
  },
  setPasoPidiendoProducto(state, i) {
    state.pasoPidiendoProducto = i;
  },
  setPasoPidiendoProductoOpciones(state, i) {
    state.pasoPidiendoProductoOpciones = i;
  },
  setOpcionesProductoDialogo(state, i) {
    state.opcionesProductoDialogo = i;
  },
  reiniciarProductosPidiendo(state) {
    state.productosPidiendo = null;
    state.productoPidiendoOpciones = {
      ids: [],
      nombres: [],
      nombresTpv: [],
      precios: [],
      incompatibilidades: []
    }
  },
  addRemoveProductoPidiendoOpcionUltra(state, opAdd) {

    let fI = state.productoPidiendoOpciones.ids.findIndex(op => op == opAdd.id)
    if (fI > -1 && opAdd.cantidad == -1) {
      state.productoPidiendoOpciones.ids.splice(fI, 1)
      state.productoPidiendoOpciones.nombres.splice(fI, 1)
      state.productoPidiendoOpciones.nombresTpv.splice(fI, 1)
      state.productoPidiendoOpciones.precios.splice(fI, 1)
      state.productoPidiendoOpciones.incompatibilidades.splice(fI, 1)
    } else {
      state.productoPidiendoOpciones.ids.push(opAdd.id)
      state.productoPidiendoOpciones.incompatibilidades.push(!!opAdd.incompatibilidades ? opAdd.incompatibilidades : [])
      state.productoPidiendoOpciones.nombres.push(opAdd.nombreMostrar)
      state.productoPidiendoOpciones.nombresTpv.push(opAdd.nombreTpv)
      state.productoPidiendoOpciones.precios.push(parseFloat(opAdd.precioCobrar))
    }
  },
  addRemoveProductoPidiendoOpcion(state, opAdd) {

    let fI = state.productoPidiendoOpciones.ids.findIndex(op => op == opAdd.id)
    if (fI > -1) {
      state.productoPidiendoOpciones.ids.splice(fI, 1)
      state.productoPidiendoOpciones.nombres.splice(fI, 1)
      state.productoPidiendoOpciones.nombresTpv.splice(fI, 1)
      state.productoPidiendoOpciones.precios.splice(fI, 1)
      state.productoPidiendoOpciones.incompatibilidades.splice(fI, 1)
    } else {
      state.productoPidiendoOpciones.ids.push(opAdd.id)
      state.productoPidiendoOpciones.incompatibilidades.push(!!opAdd.incompatibilidades ? opAdd.incompatibilidades : [])
      state.productoPidiendoOpciones.nombres.push(opAdd.nombreMostrar)
      state.productoPidiendoOpciones.nombresTpv.push(opAdd.nombreTpv)
      state.productoPidiendoOpciones.precios.push(parseFloat(opAdd.precioCobrar))
    }
  },
  removeProductoPidiendoOpcion(state, opRemove) { //elimina la opción si existe
    let fI = state.productoPidiendoOpciones.ids.findIndex(op => op == opRemove)
    if (fI > -1) {
      state.productoPidiendoOpciones.ids.splice(fI, 1)
      state.productoPidiendoOpciones.nombres.splice(fI, 1)
      state.productoPidiendoOpciones.nombresTpv.splice(fI, 1)
      state.productoPidiendoOpciones.precios.splice(fI, 1)
      state.productoPidiendoOpciones.incompatibilidades.splice(fI, 1)

    }
  },
  setProductosCatalogo(state, payload) {
    let findIndex = state.productosCatalogos.findIndex(pc => pc.catalogo == payload.catalogo);
    if (findIndex > -1) {
      state.productosCatalogos[findIndex].productos = payload.productos;
      state.productosCatalogos[findIndex].tarifa = payload.tarifa;
    } else {
      state.productosCatalogos.push({
        catalogo: payload.catalogo,
        productos: payload.productos,
        tarifa: payload.tarifa,
      })
    }
  },
  setProductosTarifa(state, payload) {
    let findIndex = state.productosTarifa.findIndex(pc => pc.tarifa == payload.tarifa);
    if (findIndex > -1) {
      state.productosTarifa[findIndex].productos = payload.productos;
      state.productosTarifa[findIndex].tarifa = payload.tarifa;
    } else {
      state.productosTarifa.push({
        productos: payload.productos,
        tarifa: payload.tarifa,
      })
    }
  },
  setProductosTarifaTodos(state, tarifas) {
    state.productosTarifa = tarifas;
  },
  setGenerarCatalogoSnackbar(state, b) {
    state.generarCatalogoSnackbar = b
  },
  setGenerarCatalogoSegundos(state, i) {
    if (i >= 0)
      state.generarCatalogoSegundos = i
  },
  setGenerarCatalogoTimeout(state, f) {
    state.generarCatalogoTimeout = f
  },
  setGenerarCatalogoTimeoutContador(state, f) {
    state.generarCatalogoTimeoutContador = f
  },
  setResultadosBusqueda(state, a) {
    state.resultadosBusqueda = a;
  }


}