import { db } from '@/firebase'
import firebase from 'firebase'
import _ from "lodash";
export default {
  getPedidos({ rootState, commit }) {
    let devolver = [];
    const negocio = rootState.negocioObj.id;
    const restaurante = rootState.restauranteObj.id;
    return db.collection('negocios').doc(negocio)
      .collection('internos').doc('pedidos')
      .collection('listado')
      .where('restaurante', '==', restaurante)
      .get()
      .then((r) => {
        r.forEach(c => {
          devolver.push({
            ...c.data(),
            id: c.id,
          })
        });
        commit('setPedidos', devolver);
      })
  },
  getCategorias({ rootState, commit }) {
    let devolver = [];
    const negocio = rootState.negocioObj.id;
    return db.collection('negocios').doc(negocio)
      .collection('internos').doc('categorias')
      .collection('listado').get()
      .then((r) => {
        r.forEach(c => {
          devolver.push({
            id: c.id,
            ...c.data()
          })
        });
        commit('setCategorias', devolver);
      })
  },
  getProductos({ rootState, commit }) {
    let devolver = [];
    const negocio = rootState.negocioObj.id;

    return db.collection('negocios').doc(negocio)
      .collection('internos').doc('productos')
      .collection('listado').get()
      .then((r) => {
        r.forEach(c => {
          devolver.push({
            id: c.id,
            ...c.data()
          })
        });

        commit('setProductos', devolver);
      })
  },
  savePedido({ state, rootState, commit }) {
    //firebase.firestore.FieldValue.serverTimestamp
    if (!state.pedidoEnCurso.fechaInicio) {
      commit('setFechaInicio', firebase.firestore.Timestamp.fromDate(new Date()))
    }
    const negocio = rootState.negocioObj.id;
    const rest = rootState.restauranteObj.id;
    const restNombre = rootState.restauranteObj.nombre;
    const pedido = state.pedidoEnCurso
    const id = pedido.id;
    var numPedido = 0;
    //delete pedido.id;
    if (id) {
      var p = db.collection('negocios').doc(negocio)
        .collection('internos').doc('pedidos')
        .collection('listado').doc(pedido.id).set({
          ...pedido
        }, { merge: true })
    } else {
      var paux = db.collection('negocios').doc(negocio)
        .collection('internos').doc('pedidos');
      var p = paux.get()
        .then((r) => {
          if (r.exists) {
            const data = r.data();
            numPedido = (data.contador) ? data.contador : 1;
            return numPedido;
          }
        })
        .then((numPedido) => {
          return paux.set({
            contador: numPedido + 1
          }, { merge: true })
        })
        .then(() => {

          return db.collection('negocios').doc(negocio)
            .collection('internos').doc('pedidos')
            .collection('listado').add({
              ...pedido,
              restaurante: rest,
              restauranteNombre: restNombre,
              numPedidoInternos: numPedido,
              fechaInicioServidor: firebase.firestore.FieldValue.serverTimestamp(),
              estado: 'realizando'
            })
            .then((docRef) => {
              commit('setId', docRef.id);
            })
        })
    }
    return p.catch((e) => { console.log(e); return Promise.reject(e) })
  },
  enviarPedido({ state, rootState, commit }) {
    //firebase.firestore.FieldValue.serverTimestamp
    const negocio = rootState.negocioObj.id;
    const pedido = state.pedidoEnCurso
    if (pedido.id) {
      return db.collection('negocios').doc(negocio)
        .collection('internos').doc('pedidos')
        .collection('listado').doc(pedido.id).set({
          estado: 'espera',
          fechaEspera: firebase.firestore.Timestamp.fromDate(new Date())
        }, { merge: true })
        .then(() => commit('resetPedidoEnCurso'))
        .catch((e) => { return Promise.reject(e) })
    } else {
      return Promise.reject("Pedido no guardado")
    }
  },
  comprobarPedido({ rootState, state, commit }) {
    const negocio = rootState.negocioObj.id;
    const rest = rootState.restauranteObj.id;
    return db.collection('negocios').doc(negocio)
      .collection('internos').doc('pedidos')
      .collection('listado')
      .where('estado', '==', 'realizando')
      .where('restaurante', '==', rest)
      .get()
      .then((snap) => {
        if (snap.size > 0) {
          let data = snap.docs[0];
          commit('setPedidoEnCursoComprobar', {
            ...data.data(),
            id: data.id
          });
        }
      })
  },
  getPedidoInterno({ rootState, commit }, id) {
    const negocio = rootState.negocioObj.id;
    return db.collection('negocios').doc(negocio)
      .collection('internos').doc('pedidos')
      .collection('listado')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return {
            ...doc.data(),
            id: doc.id
          }
        }
      })
  },
  eliminarPedidosRealizando({ rootState, commit }) {
    const negocio = rootState.negocioObj.id;
    const rest = rootState.restauranteObj.id;
    return db.collection('negocios').doc(negocio)
      .collection('internos').doc('pedidos')
      .collection('listado')
      .where('estado', '==', 'realizando')
      .where('restaurante', '==', rest)
      .get()
      .then((snap) => {
        var promesas = [];
        if (snap.size > 0) {
          snap.forEach((d) => {
            promesas.push(d.ref.delete())
          })
        }
        return Promise.all(promesas).then(() => {
          commit('resetPedidoComprobar')
        })
      })
  },
  deleteElementPedido({ state, rootState, commit }, id) {
    let _vue = this;
    let carritoInterno = state.pedidoEnCurso.carritoInterno;
    var found = carritoInterno.findIndex(prod => {
      return prod.id == id;
    });

    if (found > -1) {
      // _.pullAt(carritoInterno, found)
      carritoInterno.splice(found, 1)
      // commit("setCarritoInterno", carritoInterno)

    } else {

    }



  }
}












